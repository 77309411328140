import React from 'react';
import '../../../stylesheets/lis-member-management/member-management.css';
import { bindActionCreators } from 'redux';
import { Link, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActionCreators } from '../../../stores/lis-user-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { sessionActionCreators } from "../../../stores/lis-session-store";
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import ReactTable from "react-table";
import ContactInformation from './lis-contact-form';
import UserForm from './lis-user-creation';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { cancelRequest } from '../../../services/request.service';

const customStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
        paddingBottom: '4px !important'
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    menuList: (base) => ({
        ...base,
        paddingBottom: '4px !important'
    }),
    singleValue: (base, state) => {
        return { ...base, };
    }
}

class MemberManagementForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isSaving: false,
            userFieldsetIsActive: true,
            personalFieldsetIsActive: true,
            memberFieldsetisActive: true,
            memberData: '',
            personData: '',
            userData: [],
            chamberOptions: [
                { label: 'House', value: 'H' },
                { label: 'Senate', value: 'S' }
            ],
            districtOptions: [],
            contactOptions: [],
            userList: [],
            personList: [],
            showUserCreation: false,
            domainOptions: [],
            statusOptions: [],
            showPersonCreation: true,
            memberNumberError: false,
            isDirty: false
        };

        this.buildListDisplayName = this.buildListDisplayName.bind(this);
        this.toggleFieldsetCollapse = this.toggleFieldsetCollapse.bind(this);
        this.handleMemberChange = this.handleMemberChange.bind(this);
        this.handlePersonChange = this.handlePersonChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.selectPerson = this.selectPerson.bind(this);
        this.createPerson = this.createPerson.bind(this);
        this.toggleUserCreation = this.toggleUserCreation.bind(this);
        this.togglePersonCreation = this.togglePersonCreation.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.getMemberData = this.getMemberData.bind(this);
    }

    buildListDisplayName(personData) {
        let listDisplayName = '';
        listDisplayName = personData.LastName && personData.LastName != "" ? listDisplayName + " " + personData.LastName + "," : listDisplayName;
        listDisplayName = personData.FirstName && personData.FirstName != "" ? listDisplayName + " " + personData.FirstName : listDisplayName;
        listDisplayName = personData.MiddleName && personData.MiddleName != "" ? listDisplayName + " " + personData.MiddleName : listDisplayName;
        listDisplayName = personData.AlternateName && personData.AlternateName != "" ? listDisplayName + " " + personData.AlternateName : listDisplayName;
        listDisplayName = personData.Suffix && personData.Suffix != "" ? listDisplayName + " " + personData.Suffix : listDisplayName;

        return listDisplayName;
    }

    buildMemberDisplayName(personData) {
        let memberDisplayName = '';
        memberDisplayName = personData.FirstName && personData.FirstName != "" ? memberDisplayName + " " + personData.FirstName : memberDisplayName;
        memberDisplayName = personData.MiddleName && personData.MiddleName != "" ? memberDisplayName + " " + personData.MiddleName : memberDisplayName;
        memberDisplayName = personData.AlternateName && personData.AlternateName != "" ? memberDisplayName + " " + personData.AlternateName : memberDisplayName;
        memberDisplayName = personData.LastName && personData.LastName != "" ? memberDisplayName + " " + personData.LastName : memberDisplayName;
        memberDisplayName = personData.Suffix && personData.Suffix != "" ? memberDisplayName + " " + personData.Suffix : memberDisplayName;

        return memberDisplayName;
    }

    buildPatronDisplayName(personObj, memberDataObject) {
        let patronDisplayName = '';

        //Set the PatronDisplayName to last name, unless the last name is not unique
        if (this.props.members.memberList.find(x => x.ListDisplayName && x.ListDisplayName.split(",")[0] === personObj.LastName && (memberDataObject ? x.MemberID !== memberDataObject.MemberID : true))
            || this.props.members.memberList.find(x => x.PatronDisplayName && x.PatronDisplayName.split(",")[0] === personObj.LastName && (memberDataObject ? x.MemberID !== memberDataObject.MemberID : true))) {
            patronDisplayName = personObj.LastName + ", " + personObj.FirstName[0] + ". " + (personObj.MiddleName ? personObj.MiddleName[0] + "." : "")
        } else {
            patronDisplayName = personObj.LastName
        }

        return patronDisplayName;
    }

    toggleFieldsetCollapse(fieldset) {
        this.setState(state => ({
            [fieldset]: !state[fieldset]
        }));
    };

    handleMemberChange(key, value) {
        let memberData = { ...this.state.memberData };
        if (key === 'MemberNumber') {
            value = value ? value.toUpperCase() : value;
            if (value && ['H', 'S'].includes(value[0])) {
                memberData['ChamberCode'] = value[0];
            } else {
                memberData['ChamberCode'] = null;
                memberData['DistrictID'] = null;
            }
        }

        if (["ListDisplayName", "PatronDisplayName", "MemberDisplayName"].includes(key)) {
            memberData["override"] = true;
            memberData["markOverride"] = false;
        }

        memberData[key] = value;
        this.setState({
            memberData: memberData,
            isDirty: true,
        });
    }

    handlePersonChange(key, value) {
        let personData = { ...this.state.personData };
        let memberData = { ...this.state.memberData };
        personData[key] = value;

        if (["FirstName", "MiddleName", "AlternateName", "LastName", "Suffix"].includes(key)) {
            if (memberData["override"] === true) {
                memberData["markOverride"] = true;
                memberData["namesChanged"] = true;
            } else {
                memberData["namesChanged"] = true;
            }
        }

        memberData.ListDisplayName = this.buildListDisplayName(personData).trim();
        memberData.MemberDisplayName = this.buildMemberDisplayName(personData).trim();

        this.setState({
            personData: personData,
            memberData: memberData,
            isDirty: true
        });
    }

    handleContactChange(stateVar, value) {
        this.setState({
            [stateVar]: value,
            isDirty: true
        });
    }

    selectPerson(person) {
        this.setState({
            personData: person
        }, () => {
            let newMember = {};
            if (person.FirstName && person.LastName) {
                newMember.MemberDisplayName = this.buildMemberDisplayName(person);
                newMember.ListDisplayName = this.buildListDisplayName(person);
            } else {
                newMember.MemberDisplayName = '';
                newMember.ListDisplayName = '';
            }
            this.setState({
                memberData: newMember
            });
        });
    }

    createPerson() {
        // Check to make sure the required fields are filled out
        if (!this.state.memberData.MemberNumber || !this.state.memberData.ChamberCode || !this.state.memberData.DistrictID || !this.state.memberData.PartyCode || (!this.state.personData.IdentityID && (!this.state.memberData.MemberDisplayName || !this.state.memberData.ListDisplayName))) {
            return;
        }
        this.setState({
            isSaving: true
        }, () => {
            const personObj = {
                ...this.state.personData,
            }
            // If the person object has an IdentityID then the user has selected a person from the person list.
            // So there is no need to create a new person
            let createPerson = ''
            if (personObj.IdentityID) {
                createPerson = () => Promise.resolve();
            } else {
                createPerson = this.props.actions.createPerson(personObj)
                    .then(() => {
                        // The person has been succesfully created. Time to create the member
                        // The response from the person creation will return an ID
                        return Promise.resolve();
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.props.actions.makeToast([{ message: "Person Creation Failure", type: "failure" }]);
                        return Promise.reject(err);
                    });
            }
            Promise.all([createPerson])
                .then(() => {
                    const newMemberObj = {
                        ...this.state.memberData,
                        SessionCode: this.props.sessionCode,
                        IdentityID: personObj.IdentityID || this.props.users.personCreate,
                    };
                    newMemberObj.PatronDisplayName = this.buildPatronDisplayName(personObj);

                    //Make sure member number is unique
                    if (this.props.members.memberNumbers.find(x => x.MemberNumber === this.state.memberData.MemberNumber)) {
                        this.setState({
                            memberNumberError: true,
                            isSaving: false
                        })
                        return;
                    }

                    this.props.actions.createMember(newMemberObj)
                        .then(() => {
                            const member = this.props.members.memberCreate;
                            this.setState({ isDirty: false }, () => {
                                // If everything was succesful then send the user to the edit page for the newly created member
                                this.props.history.push(`/member-management/${member.SessionCode}/edit-member/${member.MemberID}`)
                            })
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            this.props.actions.makeToast([{ message: "Member Creation Failure", type: "failure" }]);
                            this.setState({
                                isSaving: false,
                                memberNumberError: false
                            });
                        })
                });
        });
    }

    toggleUserCreation() {
        this.setState(state => ({
            showUserCreation: !state.showUserCreation
        }));
    }

    togglePersonCreation() {
        this.setState(state => ({
            showPersonCreation: !state.showPersonCreation,
            personData: ''
        }));
    }

    saveUser(userObj) {
        this.setState({
            isSaving: true
        }, () => {
            this.props.actions.createUser(userObj)
                .then(() => {
                    this.props.actions.makeToast([{ message: "User Creation Success", type: "success" }]);
                    // Add the new user to the top of the user table
                    // The password is returned which will be used in the table
                    let fullName = (userObj.FirstName || '') + ' ' + (userObj.LastName || '');
                    userObj = {
                        ...userObj,
                        ...this.props.users.userCreate,
                        FullName: fullName
                    }
                    let userData = [...this.state.userData];
                    userData.unshift(userObj);
                    this.setState({
                        isSaving: false,
                        showUserCreation: false,
                        userData: userData
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "User Creation Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false
                    });
                })
        });
    }

    selectUser(rowIndex) {
        const userData = [...this.state.userData];
        userData[rowIndex].IsActive = !userData[rowIndex].IsActive;
        this.setState({
            userData: userData
        });
    }

    saveForm() {
        // Check to make sure the required fields are filled out
        if (!this.state.memberData.MemberNumber || !this.state.memberData.ChamberCode || !this.state.memberData.DistrictID || !this.state.memberData.PartyCode || !this.state.personData.FullName) {
            return;
        }
        //Make sure member number is unique        
        if (this.props.members.memberNumbers.filter(x => x.MemberID !== this.state.memberData.MemberID).find(x => x.MemberNumber === this.state.memberData.MemberNumber)) {
            this.setState({
                memberNumberError: true
            })
            return;
        }
        this.setState({
            isSaving: true,
            memberNumberError: false
        }, () => {
            const { memberData, personData } = this.state;
            let memberDataObject = { ...memberData };

            //Set the PatronDisplayName to last name, unless the last name is not unique
            //Ignore if names have been overridden by the user and the user hasn't gone back and overwritten the override
            //Ignore if there have been no changes to the name fields
            if ((memberData["override"] && !memberData["markOverride"]) || !memberData["namesChanged"]) {
                //Do nothing
            } else {
                memberDataObject.PatronDisplayName = this.buildPatronDisplayName(personData, memberDataObject);
            }

            memberDataObject.ChamberCode = memberDataObject.MemberNumber[0];

            //Pressing the save button will save the member info, the person info, and the contact info for user, member, and person
            const saveMember = this.props.actions.saveMember(memberDataObject)
                .then(() => {
                    return Promise.resolve();
                }).catch(err => Promise.reject(err));
            //Check to see if the person already exists before deciding to save or create the person
            const savePerson = personData.IdentityID
                ? this.props.actions.savePerson(personData)
                    .then(() => {
                        return Promise.resolve();
                    }).catch(err => Promise.reject(err))
                : this.props.actions.createPerson(personData)
                    .then(() => {
                        return Promise.resolve();
                    }).catch(err => Promise.reject(err))

            let contactArray = [];
            let saveContact;
            if (memberData.ContactInformation.length > 0) {
                contactArray = [
                    ...contactArray,
                    ...memberData.ContactInformation
                ];
            }
            if (personData && personData.ContactInformation.length > 0) {
                contactArray = [
                    ...contactArray,
                    ...personData.ContactInformation
                ];
            }
            if (contactArray.length === 0) {
                saveContact = () => Promise.resolve();
            }
            const contactObj = { ContactInformation: contactArray }
            saveContact = this.props.actions.saveContact(contactObj)
                .then(() => {
                    if (this.props.users.contactSaveError !== null) {
                        Promise.reject(this.props.users.contactSaveError)
                    } else {
                        return Promise.resolve();
                    }
                }).catch(err => Promise.reject(err));

            Promise.all([
                saveMember,
                savePerson,
                saveContact
            ])
                .then(() => {
                    this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
                    this.getMemberData(this.props.memberId);
                    this.setState({
                        isSaving: false,
                        isDirty: false
                    });
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: err.includes("Historical Member records can not be updated") ? "Updating historical members is prohibited." : "Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false,
                    });
                });
        });
    }

    async getMemberData(memberId) {
        await this.props.actions.getMember(memberId + '?sessionCode=' + this.props.sessionCode, true)
            .then(() => {
                let memberData = { ...this.props.members.member };
                let personData = memberData.Person.length > 0 ? { ...memberData.Person[0] } : '';
                let userData = [];
                if (personData) {
                    userData = personData.UserProfile;

                    if (!memberData.ListDisplayName) {
                        memberData.ListDisplayName = this.buildListDisplayName(personData).trim();
                    }
                    if (!memberData.MemberDisplayName) {
                        memberData.MemberDisplayName = this.buildMemberDisplayName(personData).trim();
                    }
                    if (!memberData.PatronDisplayName) {
                        memberData.PatronDisplayName = this.buildPatronDisplayName(personData, memberData);
                    }
                }

                this.setState({
                    memberData: memberData,
                    personData: personData,
                    userData: userData
                });
                return Promise.resolve();
            }).catch(err => Promise.reject(err));
    }

    componentDidMount() {
        this.props.actions.getMemberNumbers();
        const memberId = this.props.memberId;
        let getMemberData = '';
        let getPersonData = ''
        //It will undefined if the user is creating a new member instead of editing an existing one.
        if (memberId === undefined) {
            getMemberData = () => Promise.resolve();
            // If a new member is being created then a list of persons needs to be fetched to allow the user to associate
            // the new member to an existing person
            getPersonData = this.props.actions.getPersonList('')
                .then(() => {
                    this.setState({
                        memberData: '',
                        personData: '',
                        userData: [],
                        personList: this.props.users.personList,
                        showPersonCreation: false
                    });
                    return Promise.resolve();
                })
        } else {
            getPersonData = () => Promise.resolve();
            getMemberData = this.getMemberData(memberId)
        }
        // Get list of districts for the district dropdown in member information
        const getDistricts = this.props.actions.getDistricts()
            .then(() => {
                let districtOptions = [...this.props.members.districtList];
                districtOptions.forEach(district => {
                    district.label = `(${district.ChamberCode}) ${district.Title}`;
                    district.value = district.DistrictID;
                });
                this.setState({
                    districtOptions: districtOptions
                });
                return Promise.resolve();
            });
        const getParties = this.props.actions.getPartyCodeList()
            .then(() => {
                let partyOptions = [...this.props.members.partyCodeList];
                partyOptions.forEach(party => {
                    party.label = party.PartyCode;
                    party.value = party.PartyCode;
                });
                this.setState({
                    partyOptions: partyOptions
                });
                return Promise.resolve();
            });
        // Get the list of contact types used in the contact sections
        const getContactTypes = this.props.actions.getContactReferences()
            .then(() => {
                let contactOptions = [...this.props.users.contactReferences];
                contactOptions.forEach(type => {
                    type.label = type.ContactType;
                    type.value = type.ContactTypeID;
                });
                this.setState({
                    contactOptions: contactOptions
                });
                return Promise.resolve();
            })
        // Get list of possible member status for the dropdown in member information
        const getStatuses = this.props.actions.getMemberStatuses()
            .then(() => {
                let statusOptions = [...this.props.members.memberStatuses];
                statusOptions.forEach(status => {
                    status.label = status.Name;
                    status.value = status.MemberStatusID;
                });
                this.setState({
                    statusOptions: statusOptions
                });
                return Promise.resolve();
            });


        // Once all those requests are finished then show the page
        Promise.all([
            getMemberData,
            getPersonData,
            getDistricts,
            getParties,
            getContactTypes,
            getStatuses
        ])
            .then(() => {
                this.setState({
                    isLoaded: true
                });
            })
            .catch(err => {
                console.log(err)
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isLoaded: true,
                    message: 'Error returned when retrieving the data: ' + err
                });
            });

        //Get a list of AD directories. Used for creating a user. This does not appear on the main form so the page can be loaded before finishing this request
        this.props.actions.getDirReferences()
            .then(() => {
                let dirList = [...this.props.users.dirList];
                dirList.forEach(dir => {
                    dir.label = dir.DomainName;
                    dir.value = dir.DomainID;
                });
                this.setState({
                    domainOptions: dirList
                });
                return Promise.resolve();
            });
    }

    componentWillUnmount() {
        cancelRequest();
    }


    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="center-spinner spinner">Loading results...</div>
            );
        }
        if (this.state.message) {
            return (
                <div className="dlas-forms">
                    <span>{this.state.message}</span>
                </div>
            );

        }
        return (
            <div className="dlas-forms user-forms">
                <div>
                    <Prompt
                        when={this.state.isDirty}
                        message={`You have unsaved changes. Are you sure you would like to leave?`}
                    />
                </div>
                {this.state.showUserCreation &&
                    <div className="overlay">
                        <div className="full">
                            <div className="overlay-content animated-fade-in-up">
                                <UserForm
                                    toggleUserCreation={this.toggleUserCreation}
                                    isSaving={this.state.isSaving}
                                    domainOptions={this.state.domainOptions}
                                    saveUser={this.saveUser}
                                    userError={this.props.users.userCreateError}
                                    personData={this.state.personData}
                                />
                            </div>
                        </div>
                    </div>
                }
                <form>
                    <fieldset className={this.state.personalFieldsetIsActive ? 'fieldset-collapse' : 'fieldset-collapse fieldset-closed'}>
                        <legend onClick={() => this.toggleFieldsetCollapse('personalFieldsetIsActive')}>Personal Information</legend>
                        {this.state.showPersonCreation ?
                            <PersonInformation
                                person={this.state.personData}
                                contactOptions={this.state.contactOptions}
                                handlePersonChange={this.handlePersonChange}
                                handleContactChange={this.handleContactChange}
                                memberId={this.props.memberId}
                                togglePersonCreation={this.togglePersonCreation}
                            />
                            :
                            <PersonTable
                                personList={this.state.personList}
                                togglePersonCreation={this.togglePersonCreation}
                                selectPerson={this.selectPerson}
                            />
                        }
                    </fieldset>
                    <fieldset className={this.state.memberFieldsetisActive ? 'fieldset-collapse' : 'fieldset-collapse fieldset-closed'}>
                        <legend onClick={() => this.toggleFieldsetCollapse('memberFieldsetisActive')}>Member Information</legend>
                        <MemberInformation
                            member={this.state.memberData}
                            chamberOptions={this.state.chamberOptions}
                            districtOptions={this.state.districtOptions}
                            statusOptions={this.state.statusOptions}
                            partyOptions={this.state.partyOptions}
                            contactOptions={this.state.contactOptions}
                            handleMemberChange={this.handleMemberChange}
                            handleContactChange={this.handleContactChange}
                            memberId={this.props.memberId}
                            memberNumberError={this.state.memberNumberError}
                        />
                    </fieldset>
                    <div className="button-bar">
                        <div>
                            <Link to="/member-management" className="button secondary">Close</Link>
                        </div>
                        <div className="align-right">
                            {!this.props.memberId ?
                                <button disabled={this.state.isSaving} type="button" onClick={this.createPerson} className="button">Create</button>
                                :
                                <button disabled={this.state.isSaving} className="button" onClick={this.saveForm} type="button">Save</button>
                            }

                        </div>
                    </div>
                </form>
            </div>
        );
    }
};


class UserInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: []
        };
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
    }

    toggleShowPassword(e, userIndex) {
        if (e) {
            e.stopPropagation();
        }
        let showPassword = this.state.showPassword;
        showPassword[userIndex] = !showPassword[userIndex];
        this.setState({
            showPassword: showPassword
        });
    }

    render() {
        const { user, person } = this.props;

        return (<div>
            <div className="align-right">
                <button type="button" onClick={this.props.toggleUserCreation} className="button add-wide">Add User</button>
            </div>
            <ReactTable
                data={user}
                getTrProps={(state, rowInfo) => {
                    let background = '';
                    if (rowInfo) {
                        background = rowInfo.original.IsActive ? '#89ff89' : '';
                    }
                    return {
                        style: {
                            background: background,
                            cursor: rowInfo ? 'pointer' : 'default'
                        },
                        onClick: () => this.props.selectUser(rowInfo.index)
                    }
                }}
                noDataText="No Users Found"
                columns={[
                    {
                        id: "checkbox",
                        accessor: "",
                        className: "checkbox-column",
                        Cell: ({ original }) => {
                            return <span
                                className={original.IsActive ? "icon checkmark" : ""}>
                            </span>;
                        },
                        sortable: false,
                        filterable: false,
                        width: 45
                    },
                    {
                        Header: "Login",
                        Cell: ({ original, index }) => {
                            return (
                                <div className="flex-row">
                                    <div>{original.LoginID}</div>
                                    {original.Password &&
                                        <div>
                                            {this.state.showPassword[index] ?
                                                <span style={{ fontWeight: 700 }}>{original.Password} <button type="button" onClick={(e) => this.toggleShowPassword(e, index)} className="button small-button">Hide</button></span>
                                                :
                                                <button type="button" onClick={(e) => this.toggleShowPassword(e, index)} className="button small-button">Show Password</button>
                                            }
                                        </div>
                                    }
                                </div>
                            );
                        }
                    },
                    {
                        Header: "Organization",
                        accessor: "OrganizationName",
                        width: 300
                    },
                ]}
                defaultPageSize={5}
                className="-striped -highlight"
            />
        </div>)
    }
}

class PersonTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPersonIndex: null,
            search: '',
            personList: []
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.selectPerson = this.selectPerson.bind(this);
    }

    handleSearchChange(e) {
        const val = e.target.value;
        let personList = [...this.props.personList];
        const formattedSearch = val.toLowerCase().replace(/ /g, '');
        let filteredPersonList = null;
        if (formattedSearch !== '') {
            filteredPersonList = personList.filter(person => {
                if (person.FullName) {
                    return person.FullName.toLowerCase().includes(formattedSearch);
                }
            });
        }
        this.setState({
            search: val,
            personList: filteredPersonList || personList,
            selectedPersonIndex: null
        });
    }

    selectPerson(personIndex) {
        this.setState({
            selectedPersonIndex: personIndex
        });
        this.props.selectPerson(this.state.personList[personIndex]);
    }

    componentDidMount() {
        this.setState({
            personList: this.props.personList
        });
    }

    render() {
        return (
            <div>
                <div className="flex-row">
                    <input
                        type="text"
                        onChange={this.handleSearchChange}
                        value={this.state.search}
                        placeholder="Search"
                    />
                    <button onClick={this.props.togglePersonCreation} type="button" className="button add-wide">Add Person</button>
                </div>
                {!this.state.selectedPersonIndex && this.state.selectedPersonIndex != 0 && <div className="input-feedback align-right">Person is required.</div>}
                <br />
                <ReactTable
                    data={this.state.personList}
                    getTrProps={(state, rowInfo) => {
                        return {
                            style: {
                                background: rowInfo && rowInfo.index === this.state.selectedPersonIndex ? '#89ff89' : '',
                                cursor: rowInfo ? 'pointer' : 'default'
                            },
                            onClick: () => this.selectPerson(rowInfo.index)
                        }
                    }}
                    noDataText="No Persons Found"
                    columns={[
                        {
                            id: "checkbox",
                            accessor: "",
                            className: "checkbox-column",
                            Cell: ({ original, index }) => {
                                return <span className={index === this.state.selectedPersonIndex ? "icon checkmark" : ""}></span>;
                            },
                            sortable: false,
                            filterable: false,
                            width: 45
                        },
                        {
                            Header: "Name",
                            accessor: "FullName"
                        },
                    ]}
                    defaultPageSize={5}
                    className="-striped -highlight"
                />
            </div>
        );
    }
}

class PersonInformation extends React.Component {

    constructor(props) {
        super(props);
        this.changeContact = this.changeContact.bind(this);
        this.addContact = this.addContact.bind(this);
        this.removeContact = this.removeContact.bind(this);
    }

    changeContact(contactIndex, key, value) {
        let person = { ...this.props.person };
        person.ContactInformation[contactIndex][key] = value;
        this.props.handleContactChange("personData", person);
    }

    addContact() {
        let person = { ...this.props.person };
        person.ContactInformation.push({
            OwnerID: person.IdentityID,
            IsActive: true,
            IsPublic: true
        });
        this.props.handleContactChange("personData", person);
    }

    removeContact(contactIndex) {
        let person = { ...this.props.person };
        // If it doesn't have an ID then that means it hasn't been saved yet. So remove it from the array
        if (!person.ContactInformation[contactIndex].ContactInformationID) {
            person.ContactInformation.splice(contactIndex, 1)
        } else {
            person.ContactInformation[contactIndex].IsActive = false;
        }
        this.props.handleContactChange("personData", person);
    }

    render() {
        const { person, contactOptions } = this.props;
        return (
            <div>
                {/* if the memberid is there then a member already exists and already associated to a person */}
                {!this.props.memberId &&
                    <div className="align-right">
                        <button type="button" className="button" onClick={this.props.togglePersonCreation}>Use Existing Person</button>
                    </div>
                }
                <div className="multi-row sixth-even no-margin">
                    <div>
                        <label>Prefix</label>
                        <input type="text"
                            placeholder="Prefix"
                            value={person.Prefix}
                            onChange={(e) => this.props.handlePersonChange('Prefix', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>First Name</label>
                        <input type="text"
                            placeholder="First Name"
                            value={person.FirstName}
                            onChange={(e) => this.props.handlePersonChange('FirstName', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Middle Name</label>
                        <input type="text"
                            placeholder="Middle Name"
                            value={person.MiddleName}
                            onChange={(e) => this.props.handlePersonChange('MiddleName', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Nickname</label>
                        <input type="text"
                            placeholder="Nickname"
                            value={person.AlternateName}
                            onChange={(e) => this.props.handlePersonChange('AlternateName', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input type="text"
                            placeholder="Last Name"
                            value={person.LastName}
                            onChange={(e) => this.props.handlePersonChange('LastName', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Suffix</label>
                        <input type="text"
                            placeholder="Suffix"
                            value={person.Suffix}
                            onChange={(e) => this.props.handlePersonChange('Suffix', e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    {/* Do not allow the user to create a contact for a person that hasn't been created yet */}
                    {this.props.memberId &&
                        <ContactInformation
                            changeContact={this.changeContact}
                            contactOptions={contactOptions.filter(option => option.OwnerTypeID === 1)}
                            removeContact={this.removeContact}
                            addContact={this.addContact}
                            contactList={person.ContactInformation || []}
                        />
                    }
                </div>
            </div>);
    }
}

class MemberInformation extends React.Component {

    constructor(props) {
        super(props);
        this.changeContact = this.changeContact.bind(this);
        this.addContact = this.addContact.bind(this);
        this.removeContact = this.removeContact.bind(this);
    }

    changeContact(contactIndex, key, value) {
        let member = { ...this.props.member };
        member.ContactInformation[contactIndex][key] = value;
        this.props.handleContactChange("memberData", member);
    }

    addContact() {
        let member = { ...this.props.member };
        member.ContactInformation.push({
            OwnerID: member.MemberID,
            IsActive: true,
            IsPublic: true
        });
        this.props.handleContactChange("memberData", member);
    }

    removeContact(contactIndex) {
        let member = { ...this.props.member };
        // If it doesn't have an ID then that means it hasn't been saved yet. So remove it from the array
        if (!member.ContactInformation[contactIndex].ContactInformationID) {
            member.ContactInformation.splice(contactIndex, 1)
        } else {
            member.ContactInformation[contactIndex].IsActive = false;
        }
        this.props.handleContactChange("memberData", member);
    }

    render() {
        const { member, chamberOptions, districtOptions, statusOptions, partyOptions, contactOptions, invalidGabEmail, invalidGabPhone, memberNumberError } = this.props;
        return (
            <div>
                <div className="multi-row sixth-even no-margin">
                    <div>
                        <label>Member Display Name</label>
                        <i data-tip={`Member display name is how the member's name appears on the primary member listing page and the patronage section of a bill's detail and bill limits page.\nThe member display name is updated automatically when the name fields above are modified, but can be overridden here.`} className="icon notification member-form" />
                        <ReactTooltip key={new Date()} />
                        <input type="text"
                            placeholder="Member Display Name"
                            value={member.MemberDisplayName}
                            onChange={(e) => this.props.handleMemberChange('MemberDisplayName', e.target.value)}
                            style={member.markOverride ? { color: "red" } : {}} />
                    </div>
                    <div>
                        <label>Member Patron Name</label>
                        <i data-tip={`Patron display name is how the member's last name is shown on the bill versions, calendar, communication, docket, etc.\nThe patron display name is set when the member is saved unless the value has been overridden here.`} className="icon notification member-form" />
                        <ReactTooltip key={new Date()} />
                        <input type="text"
                            placeholder="Member Patron Name"
                            value={member.PatronDisplayName}
                            onChange={(e) => this.props.handleMemberChange('PatronDisplayName', e.target.value)}
                            style={member.markOverride ? { color: "red" } : {}}
                        />
                    </div>
                    <div>
                        <label>List Display Name</label>
                        <i data-tip={`List display name is how the member's name appears on BDS/eFile, member and watchlist notification searching, and how my legislator voted.\nThe list display name is updated automatically when the name fields are modified, but can be overridden here.`} className="icon notification member-form" />
                        <ReactTooltip key={new Date()} />
                        <input type="text"
                            placeholder="List Display Name"
                            value={member.ListDisplayName}
                            onChange={(e) => this.props.handleMemberChange('ListDisplayName', e.target.value)}
                            style={member.markOverride ? { color: "red" } : {}}
                        />
                    </div>
                    <div>
                        <label>Member Number</label>
                        <input type="text"
                            placeholder="Member Number"
                            value={member.MemberNumber}
                            onChange={(e) => this.props.handleMemberChange('MemberNumber', e.target.value)}
                        />
                        {!member.MemberNumber && <div className="input-feedback">Member Number is required.</div>}
                        {memberNumberError && <div className="input-feedback">Member Number is already in use.</div>}
                    </div>
                    <div />
                </div>
                <div className="multi-row sixth-even no-margin">
                    <div>
                        <label>Party Code</label>
                        <Select
                            styles={customStyles}
                            value={partyOptions.find(option => option.value === member.PartyCode)}
                            key={member.PartyCode}
                            options={partyOptions}
                            onChange={(val) => this.props.handleMemberChange('PartyCode', val.value)}
                        />
                        {!member.PartyCode && <div className="input-feedback">Party Code is required.</div>}
                    </div>
                    <div>
                        <label>District</label>
                        <Select
                            styles={customStyles}
                            value={districtOptions.find(option => option.value === member.DistrictID && option.ChamberCode === member.ChamberCode)}
                            key={member.DistrictID}
                            isDisabled={!member.ChamberCode}
                            options={districtOptions.filter(option => option.ChamberCode === member.ChamberCode)}
                            onChange={(val) => this.props.handleMemberChange('DistrictID', val.value)}
                        />
                        {!member.DistrictID && <div className="input-feedback">District is required.</div>}
                    </div>
                    <div>
                        <label>Voting Sequence</label>
                        <input type="number"
                            placeholder="Voting Sequence"
                            value={member.VotingSequence}
                            onChange={(e) => this.props.handleMemberChange('VotingSequence', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Seat Number</label>
                        <input type="number"
                            placeholder="Seat Number"
                            value={member.SeatNumber}
                            onChange={(e) => this.props.handleMemberChange('SeatNumber', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Status</label>
                        <Select
                            styles={customStyles}
                            options={statusOptions}
                            key={member.MemberStatusID}
                            value={statusOptions.find(option => option.value === member.MemberStatusID)}
                            onChange={(val) => this.props.handleMemberChange('MemberStatusID', val.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="is-public-button" className="checkbox-label no-background" style={{ paddingLeft: '0px' }}>Is Public?</label>
                        <div className="toggle-switch" onClick={() => this.props.handleMemberChange('IsPublic', !member.IsPublic)}>
                            <input id="is-public-button" checked={member.IsPublic} type="checkbox" style={{ width: 'inherit' }} />
                            <span className="slider"></span>
                        </div>
                    </div>
                </div>
                <div className="multi-row sixth-even no-margin">
                    <div>
                        <label>Service Start Date</label>
                        <DatePicker
                            selected={member.ServiceBeginDate ? moment(member.ServiceBeginDate) : null}
                            key={member.ServiceBeginDate}
                            onChange={(val) => this.props.handleMemberChange('ServiceBeginDate', val)}
                            isClearable
                        />
                    </div>
                    <div>
                        <label>Service End Date</label>
                        <DatePicker
                            selected={member.ServiceEndDate ? moment(member.ServiceEndDate) : null}
                            key={member.ServiceEndDate}
                            onChange={(val) => this.props.handleMemberChange('ServiceEndDate', val)}
                            isClearable
                        />
                    </div>
                    <div>
                        <label>Service End Reason</label>
                        <input
                            type="text"
                            placeholder="Ex. Retired"
                            value={member.ServiceEndReason}
                            onChange={(e) => this.props.handleMemberChange('ServiceEndReason', e.target.value)}
                            disabled={!member.ServiceEndDate}
                        />
                    </div>
                    <div>
                        <label>GAB Email Address</label>
                        <input type="text"
                            placeholder="GAB Email Address"
                            value={member.GABEmailAddress}
                            onChange={(e) => this.props.handleMemberChange('GABEmailAddress', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>GAB Phone Number</label>
                        <input type="text"
                            placeholder="GAB Phone Number"
                            value={member.GABPhoneNumber}
                            onChange={(e) => this.props.handleMemberChange('GABPhoneNumber', e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Room Number</label>
                        <input type="text"
                            placeholder="Room Number"
                            value={member.RoomNumber}
                            onChange={(e) => this.props.handleMemberChange('RoomNumber', e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    {/* Do not allow the user to create a contact for a member that hasn't been created yet */}
                    {this.props.memberId &&
                        <ContactInformation
                            changeContact={this.changeContact}
                            contactOptions={contactOptions.filter(option => option.OwnerTypeID === 3)}
                            removeContact={this.removeContact}
                            addContact={this.addContact}
                            contactList={member.ContactInformation || []}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { nav, users, members, session } = state;
        return {
            nav,
            users,
            members,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, userActionCreators, memberActionCreators, sessionActionCreators), dispatch)
        }
    }
)(MemberManagementForm)








