import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { memberActionCreators } from '../../stores/lis-members-store';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { patronActionCreators } from '../../stores/lis-patron-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { cancelRequest } from '../../services/request.service';


class MemberInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.toggleShowData = this.toggleShowData.bind(this);
    }

    toggleShowData(memberID) {
        this.setState(prevState => ({
            [memberID]: !prevState[memberID]
        }));
    }

    render() {
        const { member, sessionData, patronRoles } = this.props;
        const prefileEvent = sessionData ? sessionData.SessionEvents.find(event => event.DisplayName === 'Prefile Date') : '';
        const closingEvent = sessionData ? sessionData.SessionEvents.find(event => event.DisplayName === 'Closing') : '';
        const startDate = prefileEvent ? moment(prefileEvent.ActualDate) : '';
        const endDate = closingEvent ? moment(closingEvent.ActualDate) : moment();
        const memberDetails = (member, startDate, endDate) => {
            let detailString = '';
            if (member.ServiceEndDate && moment(member.ServiceEndDate).isAfter(startDate) && moment(member.ServiceEndDate).isBefore(endDate) && member.ServiceEndReason) {
                detailString = detailString ? detailString + ', ' : '(';
                detailString += member.ServiceEndReason;
            }
            if (detailString) detailString += ')';
            if (member.MemberStatus === "Elect") detailString += "(Elect)";
            return <React.Fragment>{detailString}</React.Fragment>;
        }
        const info = member.ContactInformation.map(contact => {
            return (
                <p>{contact.Address1}, {member.RoomNumber}<br />
                    {contact.Address2}, {contact.City}, {contact.StateCode} {contact.ZipCode}<br />
                    {contact.PhoneNumber}<br />
                    {contact.EmailAddress &&
                        <span>email: {contact.EmailAddress}</span>
                    }
                </p>
            );
        });
        return (
            <div className={this.state[member.MemberNumber] ? 'parent-li slidedown-open' : 'parent-li slidedown-closed'}>
                <div className="toggle-row">
                    <div>
                        <Link to={`/session-details/${sessionData.SessionCode}/member-information/${member.MemberNumber}/member-details`}>{member.MemberDisplayName}</Link>
                        <span className="txt-greyed">{memberDetails(member, startDate, endDate)}</span>
                    </div>
                    <div className="button-container">
                        <button aria-expanded={!!this.state[member.MemberNumber]} className="arrow-down" onClick={this.toggleShowData.bind(this, member.MemberNumber)}>More info</button>
                    </div>
                </div>
                {this.state[member.MemberNumber] &&
                    <div className="list-desc">
                        <p>{member.PartyName} / {member.Chamber} - {member.DistrictName} District</p>
                        {info}
                    </div>
                }
                <div className="list-links">
                    {/* Search for member's legislation by all patron roles except Sponsor */}
                    <Link to={'/bill-search/?q=' + window.btoa(JSON.stringify({ selectedPatron: member.MemberID, selectedSession: sessionData.SessionID, selectedPatronType: patronRoles && patronRoles.length ? patronRoles.filter(role => role.Name !== "Sponsor").map(roles => roles.PatronTypeID) : [] }))} style={{ pointerEvents: patronRoles && patronRoles.length ? null : 'none' }}>Legislation</Link>
                    <Link to={`/vote-search/${sessionData.SessionCode}/${member.MemberNumber}`}>Votes</Link>
                </div>
            </div>
        )
    }
}


class MemberInformationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionData: [],
            houseMembers: [],
            senateMembers: [],
            value: "",
            showHouseChamber: true,
            showSenateChamber: true
        };
        this.getMembersLists = this.getMembersLists.bind(this);
        this.toggleChamber = this.toggleChamber.bind(this);
        this.setupPage = this.setupPage.bind(this);
    }

    getMembersLists(sessionData, chamberCode, listName) {
        var params = 'sessionID=' + sessionData.SessionID + '&chamberCode=' + chamberCode + '&contactTypeID=8'

        this.props.actions.getMemberContact(params).then(() =>
            this.setState({
                [listName]: this.props.members.memberContactData,
                [listName + 'IsLoaded']: true
            })
        )
    }

    toggleChamber(chamber) {
        this.setState(state => ({
            [chamber]: !state[chamber]
        }));
    }

    componentDidMount() {
        //Update the session selector component, which will run componentDidUpdate and call setupPage on its own
        const sessionCode = this.props.match.params.sessioncode
        if (sessionCode) {
            if (this.props.nav.session !== sessionCode) {
                this.props.actions.changeSession(sessionCode);
            } else {
                this.setupPage(sessionCode);
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push('/session-details/' + this.props.nav.session + '/member-information')
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            if (this.props.match.params.sessioncode) {
                this.props.actions.changeSession(this.props.match.params.sessioncode);
            }
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    setupPage(sessionCode) {
        this.setState({
            houseMembersIsLoaded: false,
            senateMembersIsLoaded: false
        })
        this.props.actions.getSessionByCode(sessionCode).then(() => {
            this.setState({
                sessionData: this.props.session.selectedSession
            });
            this.getMembersLists(this.props.session.selectedSession, 'H', 'houseMembers')
            this.getMembersLists(this.props.session.selectedSession, 'S', 'senateMembers')
        });

        this.props.actions.getPatronRoles()
            .then(() => {
                const patronRoles = [...this.props.patrons.patronRoles];
                this.setState({
                    patronRoles: patronRoles
                });
            });
    }

    render() {
        const { sessionData, houseMembers, senateMembers, patronRoles } = this.state;
        const listHouseMembers = houseMembers.map((member, i) =>
            <MemberInfoComponent key={i} member={member} sessionData={sessionData} patronRoles={patronRoles} />
        );
        const listSenateMembers = senateMembers.map((member, i) =>
            <MemberInfoComponent key={i} member={member} sessionData={sessionData} patronRoles={patronRoles} />
        );

        return (
            <div className="public-chamber-data">
                <div>
                    <h2>{sessionData.SessionYear} {sessionData.DisplayName}</h2>
                </div>
                <div id="chamber-vote-leg-links">
                    {/*TODO: Temporarily removing these links for historical data per User Story 13242*/}
                    {sessionData.IsActive &&
                        <>
                            <Link to={`/session-details/${sessionData.SessionCode}/member-legislation/S`}>Senate Member Legislation</Link>
                            <br />
                            <Link to={`/session-details/${sessionData.SessionCode}/member-legislation/H`}>House Member Legislation</Link>
                            <br />
                        </>
                    }
                    <Link to={`/vote-search/${sessionData.SessionCode}`}>Member Vote Search</Link>
                    <br />
                    <a rel="noreferrer" target="_blank" href="https://whosmy.virginiageneralassembly.gov">Who's My Legislator?</a>
                </div>
                <div className="public-list inner-grid two">
                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showHouseChamber")} aria-expanded={this.state.showHouseChamber} className={`chamber-name ${this.state.showHouseChamber ? "opened" : ""}`}>House Members</h3>
                        {this.state.houseMembersIsLoaded ?
                            <ul className="slidedown-list">
                                {this.state.showHouseChamber &&
                                    <>
                                        {listHouseMembers}
                                    </>
                                }
                            </ul>
                            :
                            <div className="spinner">Fetching results...</div>
                        }
                    </div>
                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showSenateChamber")} aria-expanded={this.state.showSenateChamber} className={`chamber-name ${this.state.showSenateChamber ? "opened" : ""}`}>Senate Members</h3>
                        {this.state.senateMembersIsLoaded ?
                            <ul className="slidedown-list">
                                {this.state.showSenateChamber &&
                                    <>
                                        {listSenateMembers}
                                    </>
                                }
                            </ul>
                            :
                            <div className="spinner">Fetching results...</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const PublicMemberInformation = connect(
    (state) => {
        const { members, session, patrons, nav } = state;
        return {
            members,
            session,
            patrons,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, memberActionCreators, sessionActionCreators, patronActionCreators, navActionCreators), dispatch)
        }
    }
)(MemberInformationComponent)

export default PublicMemberInformation;
