import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { communicationsActionCreators } from '../../stores/lis-communications-store';
import moment from 'moment-timezone';

import { cancelRequest } from '../../services/request.service';
import { Link } from 'react-router-dom';

class PublicCommunicationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            session: '',
            senateIsLoaded: false,
            houseIsLoaded: false,
            senateCommList: [],
            houseCommList: [],
            showHouseChamber: true,
            showSenateChamber: true
        };
        this.toggleChamber = this.toggleChamber.bind(this);
    }

    toggleChamber(chamber) {
        this.setState(state => ({
            [chamber]: !state[chamber]
        }));
    }

    //When component has mounted, make the call to get session by code
    componentDidMount() {
        const sessionCode = this.props.match.params.sessioncode
        if (parseInt(sessionCode) > 0) {
            //Update the session selector component, which will run componentDidUpdate and call setupPage on its own
            if (this.props.nav.session !== sessionCode) {
                this.props.actions.changeSession(sessionCode)
            } else {
                this.setupPage(sessionCode);
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push("/session-details/" + this.props.nav.session + "/communications");
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            const sessionCode = this.props.match.params.sessioncode;
            if (!isNaN(sessionCode)) {
                this.props.actions.changeSession(sessionCode);
            }
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    setupPage(sessionCode) {
        this.setState({
            senateIsLoaded: false,
            houseIsLoaded: false
        });
        const createCommList = commList => {
            commList.forEach(comm => {
                const jsonFile = comm.CommunicationFiles.find(file => file.FileURL.includes('JSON'))
                if (jsonFile && jsonFile.IsGenerated) {
                    comm.jsonFile = jsonFile
                }
            });
            return commList;
        }
        //Get the session information
        this.props.actions.getSessionByCode(sessionCode)
            .then(() => {
                this.setState({
                    session: this.props.session.selectedSession,
                });
            });
        //Get the list for the Senate
        this.props.actions.getCommList('?sessionCode=' + sessionCode + '&chamberCode=S&communicationType=Floor')
            .then(() => {
                this.setState({
                    senateCommList: createCommList([...this.props.communications.commList]),
                    senateIsLoaded: true
                });
            });
        //Get the list for the House
        this.props.actions.getCommList('?sessionCode=' + sessionCode + '&chamberCode=H&communicationType=Floor')
            .then(() => {
                this.setState({
                    houseCommList: createCommList([...this.props.communications.commList]),
                    houseIsLoaded: true
                });
            });
    }

    render() {
        const { senateCommList, houseCommList, senateIsLoaded, houseIsLoaded, session } = this.state;
        const sessionHeader = session ? session.SessionYear + " " + session.DisplayName : '';
        return (
            <div className="public-chamber-data">
                <div>
                    <h2>{sessionHeader}</h2>
                </div>
                <div className="public-list inner-grid two">
                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showHouseChamber")} aria-expanded={this.state.showHouseChamber} className={`chamber-name ${this.state.showHouseChamber ? "opened" : ""}`}>House Communications</h3>
                        {houseIsLoaded ?
                            <table className="line-table">
                                {this.state.showHouseChamber &&
                                    <tbody className="toggle-table">
                                        <div className="list-desc">
                                            {houseCommList.map(comm =>
                                                <tr key={comm.CommunicationID} className="inner-grid two alternating-color-list">
                                                    <td>
                                                        <Link to={`/session-details/${this.state.session.SessionCode}/communications/${comm.ReferenceNumber}`}>
                                                            <span className="button paper">Communication</span>
                                                            {moment(comm.CommunicationDate).format("MMMM D, YYYY")} {comm.CommunicationNumber > 1 && `(${comm.CommunicationNumber})`}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </div>
                                    </tbody>
                                }
                            </table>
                            :
                            <div className="center-spinner spinner"></div>
                        }
                    </div>

                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showSenateChamber")} aria-expanded={this.state.showSenateChamber} className={`chamber-name ${this.state.showSenateChamber ? "opened" : ""}`}>Senate Communications</h3>
                        {senateIsLoaded ?
                            <table className="line-table">
                                {this.state.showSenateChamber &&
                                    <tbody className="toggle-table">
                                        <div className="list-desc">
                                            {senateCommList.map(comm =>
                                                <tr key={comm.CommunicationID} className="inner-grid two alternating-color-list">
                                                    <td>
                                                        <Link to={`/session-details/${this.state.session.SessionCode}/communications/${comm.ReferenceNumber}`}>
                                                            <span className="button paper">Communication</span>
                                                            {moment(comm.CommunicationDate).format("MMMM D, YYYY")} {comm.CommunicationNumber > 1 && `(${comm.CommunicationNumber})`}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </div>
                                    </tbody>
                                }
                            </table>
                            :
                            <div className="center-spinner spinner"></div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { session, nav, communications } = state;
        return {
            session,
            nav,
            communications
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, navActionCreators, communicationsActionCreators), dispatch)
        }
    }
)(PublicCommunicationsList)
