import moment from 'moment-timezone';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { patronActionCreators } from '../../../stores/lis-patron-store';

class ShorthandUrlTranslator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: [],
            error: false
        };

        this.findPatronType = this.findPatronType.bind(this);
    }

    findPatronType(value) {
        switch (value) {
            case "C":
                return this.props.patrons.patronRoles.find(x => x.Name === "Chief Patron").PatronTypeID
            case "F":
                return this.props.patrons.patronRoles.find(x => x.Name === "Chief Co-Patron").PatronTypeID
            case "S":
                return this.props.patrons.patronRoles.find(x => x.Name === "Co-Patron").PatronTypeID
            case "I":
                return this.props.patrons.patronRoles.find(x => x.Name === "Incorporated Chief Co-Patron").PatronTypeID
            default:
                return '';
        }
    }

    componentDidMount() {
        this.setState({
            params: this.props.location.search.slice(1).split("&")
        }, () => {
            if(!this.state.params || this.state.params.length === 0) {
                this.setState({
                    error: true
                }, () => {
                    return;
                })                
            }

            this.props.actions.getSessionList()
                .then(() => {
                    //Get the default session if the user didn't pass in a session param
                    let sessionParam = "SessionID=" + (this.state.params.findIndex(x => x.toLowerCase().includes("ses")) >= 0
                        ? this.props.session.sessionList.find(x => x.SessionCode === this.state.params[this.state.params.findIndex(x => x.toLowerCase().includes("ses"))].split("=")[1]).SessionID
                        : this.props.session.sessionList.find(x => x.IsDefault).SessionID);

                    this.props.actions.getCommitteeList(sessionParam + "&includeSubCommittees=true")
                        .then(this.props.actions.getPatronRoles(sessionParam)
                            .then(this.props.actions.getMemberList(sessionParam)
                                .then(() => {                                    
                                    let obj = {};
                                    try {
                                        for (let i = 0; i < this.state.params.length; i++) {
                                            switch (this.state.params[i].split("=")[0].toLowerCase()) {
                                                case "com":
                                                    obj.selectedCommittee = this.props.committee.committeeList.find(x => x.CommitteeNumber === this.state.params[i].split("=")[1]).CommitteeID
                                                    break;
                                                case "subcom":
                                                    obj.selectedSubcommittee = this.props.committee.committeeList.find(x => x.CommitteeNumber === this.state.params[i].split("=")[1]).CommitteeID
                                                    break;
                                                case "mem":
                                                    obj.selectedPatron = this.props.members.memberList.find(x => x.MemberNumber === this.state.params[i].split("=")[1]).MemberID
                                                    break;
                                                case "mempat":
                                                    if (!obj.selectedPatronType)
                                                        obj.selectedPatronType = [];
                                                    obj.selectedPatronType.push(this.findPatronType(this.state.params[i].split("=")[1]))
                                                    break;
                                                case "ses":
                                                    obj.selectedSession = this.props.session.sessionList.find(x => x.SessionCode === this.state.params[i].split("=")[1]).SessionID
                                                    break;
                                                default:
                                                    //Invalid param, do nothing
                                                    break;
                                            }
                                        }

                                        //If the user provided patron types with no patron throw an error
                                        if (obj.selectedPatronType && !obj.selectedPatron) {
                                            this.setState({
                                                error: true
                                            }, () => {
                                                return;
                                            })
                                        }

                                        //If the user provided a member without patron types then set the default patron types based on chamber
                                        if (obj.selectedPatron && !obj.selectedPatronType && this.props.members.memberList.find(x => x.MemberID === obj.selectedPatron).ChamberCode === "H") {
                                            obj.selectedPatronType = [1, 2, 4];
                                        } else if (obj.selectedPatron && !obj.selectedPatronType) {
                                            obj.selectedPatronType = [1, 2, 4, 5];
                                        }

                                        //If the user provided a subcommittee without a parent committee then set the parent committee for them
                                        if (obj.selectedSubcommittee && !obj.selectedCommittee) {
                                            obj.selectedCommittee = this.props.committee.committeeList.find(x => x.CommitteeID === (this.props.committee.committeeList.find(x => x.CommitteeID === obj.selectedSubcommittee).ParentCommitteeID)).CommitteeID
                                        }

                                        if (Object.keys(obj).length > 0) {
                                            const b64Params = window.btoa(JSON.stringify(obj));
                                            const url = '/bill-search/?q=' + b64Params;
                                            this.props.history.push(url);
                                        } else {
                                            this.setState({
                                                error: true
                                            }, () => {
                                                return;
                                            })
                                        }
                                    } catch (e) {
                                        console.log(e)
                                        this.setState({
                                            error: true
                                        })
                                    }
                                })));

                })
        })
    }

    render() {
        return (
            <div>
                {!this.state.error
                    ? <div className="center-spinner spinner">Loading...</div>
                    : <p className='message-error'>There was an error converting your URL parameters into a bill search. Please check your parameters and try again.
                        <br />
                        <br />
                        The following parameters can be used to write a search query:
                        <br />
                        <br />
                        To search for a:
                        <br />
                        Member: mem=Member Number
                        <br />
                        Patron Type: mempat=Letter (C/F/S/I)
                        <br />
                        Committee: com=Committee Number
                        <br />
                        Subcommittee: subcom=Committee Number
                        <br />
                        Session: ses=Session Code
                        <br />
                        <br />
                        The URLs are constructed as /q/?param=value&param2=value2&param3=value3
                        <br />
                        <br />
                        For example:  lis.virginia.gov/q?ses=20221&mem=H0289&mempat=S&mempat=F will get legislation for Dawn Adams as a Co-Patron or Chief Co-Patron in the 2022 Regular Session.
                        <br />
                        <br />
                        You may use multiple patron types. If you use multiple sessions, members, committees, or subcommittees the search will use the last one in the list. If you supply a patron type you must supply a patron.
                    </p>
                }
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { nav, committee, members, patrons, session } = state;
        return {
            nav,
            committee,
            members,
            patrons,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, memberActionCreators, navActionCreators, patronActionCreators, sessionActionCreators), dispatch)
        }
    }
)(ShorthandUrlTranslator);