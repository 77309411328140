import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navActionCreators } from '../../stores/lis-nav-store';
import { statActionCreators } from '../../stores/lis-statistics-store';
import { sessionActionCreators } from '../../stores/lis-session-store';


class CommitteeStatDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            stats: [],
            sessionData: '',
            totals: ''
        };

        this.setupPage = this.setupPage.bind(this);
    }

    setupPage(sessionCode) {
        this.setState({
            isLoading: true
        });
        this.props.actions.getSessionByCode(sessionCode).then(() => {
            this.setState({
                sessionData: this.props.session.selectedSession
            }, () => {
                this.props.actions.getCommitteeStats('?sessionId=' + this.state.sessionData.SessionID + '&chambercode=' + this.props.match.params.chambercode)
                    .then(() => {
                        let totals = {
                            ContinuedFromTotal: 0,
                            HReferredTotal: 0,
                            SReferredTotal: 0,
                            HInCommitteeTotal: 0,
                            SInCommitteeTotal: 0,
                            HInSubCommitteeTotal: 0,
                            SInSubCommitteeTotal: 0,
                            HReportedTotal: 0,
                            SReportedTotal: 0,
                            HIncorporatedTotal: 0,
                            SIncorporatedTotal: 0,
                            HFailedTotal: 0,
                            SFailedTotal: 0,
                            ContinuedToTotal: 0
                        }
                        this.props.stats.committeeStats.sort((a, b) => a.CommitteeName.localeCompare(b.CommitteeName))
                        this.props.stats.committeeStats.forEach(stat => {
                            totals.ContinuedFromTotal += stat.HContinuedFrom += stat.SContinuedFrom || 0;
                            totals.HReferredTotal += stat.HReferred || 0;
                            totals.SReferredTotal += stat.SReferred || 0;
                            totals.HInCommitteeTotal += stat.HInCommittee || 0;
                            totals.SInCommitteeTotal += stat.SInCommittee || 0;
                            totals.HInSubCommitteeTotal += stat.HInSubCommittee || 0;
                            totals.SInSubCommitteeTotal += stat.SInSubCommittee || 0;
                            totals.HReportedTotal += stat.HReported || 0;
                            totals.SReportedTotal += stat.SReported || 0;
                            totals.HIncorporatedTotal += stat.HIncorporated || 0;
                            totals.SIncorporatedTotal += stat.SIncorporated || 0;
                            totals.HFailedTotal += stat.HFailed || 0;
                            totals.SFailedTotal += stat.SFailed || 0;
                            totals.ContinuedToTotal += stat.HContinued += stat.SContinued || 0;
                        });
                        this.setState({
                            stats: this.props.stats.committeeStats,
                            totals: totals,
                            isLoading: false
                        });
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.setState({
                            isLoading: false,
                            message: this.props.stats.committeeStatsError
                        })
                    });
            });
        });
    }

    componentDidMount() {
        if (this.props.match.params.sessioncode) {
            //Update the session selector component, which will run componentDidUpdate and call setupPage on its own
            if (this.props.nav.session !== this.props.match.params.sessioncode) {
                this.props.actions.changeSession(this.props.match.params.sessioncode)
            } else {
                this.setupPage(this.props.match.params.sessioncode);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.chambercode !== prevProps.match.params.chambercode) {
            this.setupPage(this.props.match.params.sessioncode);
        }
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push('/session-details/' + this.props.nav.session + '/statistics/committee/' + this.props.match.params.chambercode)
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            if (this.props.match.params.sessioncode) {
                this.props.actions.changeSession(this.props.match.params.sessioncode);
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return (<div aria-label="Loading" className="center-spinner spinner"></div>)
        }
        if (this.state.message) {
            return (<div className="center">{this.state.message}</div>)
        }
        return (
            <div>
                <br />
                <div className="full">
                    <table className="statistics-table">
                        <caption>
                            <h1>
                                {this.state.sessionData && <span className="statistics-header">{this.state.sessionData.SessionYear} {this.state.sessionData.DisplayName}</span>}
                                <span className="statistics-header">{this.props.match.params.chambercode.toLowerCase() === "s" ? " Senate" : " House"} Committee Statistics</span>
                            </h1>
                            {this.props.match.params.chambercode.toLowerCase() === "s" &&
                                <Link to={`/session-details/${this.props.match.params.sessioncode}/statistics/committee/h`}>House Committee Statistics</Link>
                            }
                            {this.props.match.params.chambercode.toLowerCase() === "h" &&
                                <Link to={`/session-details/${this.props.match.params.sessioncode}/statistics/committee/s`}>Senate Committee Statistics</Link>
                            }
                            <span> | </span>
                            <Link to={`/session-details/${this.props.match.params.sessioncode}/statistics/status`}>Session Statistics</Link>
                        </caption>
                        <tbody>
                            <tr>
                                <th colSpan="1" rowSpan="2"></th>
                                {this.state.totals.ContinuedFromTotal > 0 && <th rowSpan="2">Continued From {this.state.sessionData.SessionYear - 1}</th>}
                                <th colSpan="2">Referred</th>
                                <th colSpan="2">In Committee</th>
                                <th colSpan="2">In Subcommittee</th>
                                <th colSpan="2">Reported</th>
                                <th colSpan="2">Incorporated</th>
                                <th colSpan="2">Failed</th>
                                {this.state.totals.ContinuedToTotal > 0 && <th rowSpan="2">Continued To {this.state.sessionData.SessionYear + 1}</th>}
                            </tr>
                            <tr>
                                <th>H</th>
                                <th>S</th>
                                <th>H</th>
                                <th>S</th>
                                <th>H</th>
                                <th>S</th>
                                <th>H</th>
                                <th>S</th>
                                <th>H</th>
                                <th>S</th>
                                <th>H</th>
                                <th>S</th>
                            </tr>
                            {this.state.stats.map((stat, statIndex) =>
                                <tr key={statIndex}>
                                    <td className="homepage-links"><Link to={`/session-details/${this.props.match.params.sessioncode}/committee-information/${stat.CommitteeNumber}/committee-details`}>{stat.CommitteeName}</Link></td>
                                    {this.state.totals.ContinuedFromTotal > 0 && <td>{stat.HContinuedFrom + stat.SContinuedFrom}</td>}
                                    <td>{stat.HReferred}</td>
                                    <td>{stat.SReferred}</td>
                                    <td>{stat.HInCommittee}</td>
                                    <td>{stat.SInCommittee}</td>
                                    <td>{stat.HInSubCommittee}</td>
                                    <td>{stat.SInSubCommittee}</td>
                                    <td>{stat.HReported}</td>
                                    <td>{stat.SReported}</td>
                                    <td>{stat.HIncorporated}</td>
                                    <td>{stat.SIncorporated}</td>
                                    <td>{stat.HFailed}</td>
                                    <td>{stat.SFailed}</td>
                                    {this.state.totals.ContinuedToTotal > 0 && <td>{stat.HContinued + stat.SContinued}</td>}
                                </tr>
                            )}
                            <tr>
                                <td>Total for all {this.props.match.params.chambercode.toLowerCase() === "s" && "Senate"}{this.props.match.params.chambercode.toLowerCase() === "h" && "House"} Committees</td>
                                {this.state.totals.ContinuedFromTotal > 0 && <td>{this.state.totals.ContinuedFromTotal}</td>}
                                <td>{this.state.totals.HReferredTotal}</td>
                                <td>{this.state.totals.SReferredTotal}</td>
                                <td>{this.state.totals.HInCommitteeTotal}</td>
                                <td>{this.state.totals.SInCommitteeTotal}</td>
                                <td>{this.state.totals.HInSubCommitteeTotal}</td>
                                <td>{this.state.totals.SInSubCommitteeTotal}</td>
                                <td>{this.state.totals.HReportedTotal}</td>
                                <td>{this.state.totals.SReportedTotal}</td>
                                <td>{this.state.totals.HIncorporatedTotal}</td>
                                <td>{this.state.totals.SIncorporatedTotal}</td>
                                <td>{this.state.totals.HFailedTotal}</td>
                                <td>{this.state.totals.SFailedTotal}</td>
                                {this.state.totals.ContinuedToTotal > 0 && <td>{this.state.totals.ContinuedToTotal}</td>}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { stats, nav, session } = state;
        return {
            stats,
            nav,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, statActionCreators, navActionCreators, sessionActionCreators), dispatch)
        }
    }
)(CommitteeStatDetails)
