import React from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// STYLE TO SHOW WHEN ITEM IS BEING DRAGGED
const getItemStyle = (isDragging, draggableStyle, pendingDeletion) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? '#34495e' : 'white',
    color: isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%',
});


class MembersDraggableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownDataRoles: [{ value: 0, label: 'Role' }],
            membersDropdown: [],
            selectedMember: 'Select...',
            selectedRole: { value: 0, label: 'Role' }
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onInsert = this.onInsert.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleRolesDropDownChange = this.handleRolesDropDownChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSeniorityChange = this.handleSeniorityChange.bind(this);
    }

    // HANDLE DATE ASSIGNED IN ADD NEW MEMBER ROW
    handleDateChange(date) {
        this.setState(state => ({
            selectedMember: { ...state.selectedMember, AssignDate: moment(date) }
        }));
    }

    // HANDLE SENIORITY CHANGE IN ADD NEW MEMBER ROW
    handleSeniorityChange(e) {
        let value = e.target.value;
        this.setState(state => ({
            selectedMember: { ...state.selectedMember, Seniority: value }
        }));
    }

    // HANDLE MEMBER DROPDOWN CHANGE IN ADD NEW MEMBER ROW
    handleDropDownChange = member => {
        if (member) {
            //The AssignDate is deleted because the subcommittee dropdown has it defined and
            //the date is not formatted correctly for the DatePicker. Since a new date is being assigned anyway I am just going to delete this value
            delete member.AssignDate;
            this.setState(state => ({
                selectedMember: {
                    AssignDate: state.selectedMember.AssignDate,
                    ...member,
                    DisplaySequence: this.props.items.length,
                }
            }));
        } else {
            this.setState({
                selectedMember: 'Select...'
            });
        }
    }

    // ADD THE SELECTED MEMBER TO THE GRID
    onInsert() {

        let selectedMember = this.state.selectedMember
        //Make sure that a member is selected before trying to add 
        if (selectedMember.MemberID && this.state.selectedRole.value) {
            delete selectedMember.RemoveDate;
            //Add isPublic=false so duplicate entries are not created
            selectedMember.IsPublic = false;
            //If the user didn't set the date then assign today's date
            if (!selectedMember.AssignDate) selectedMember.AssignDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SS[Z]");
            selectedMember.DisplaySequence = this.props.items.length + 1;
            selectedMember.CommitteeID = this.props.affiliation;
            selectedMember.CommitteeRoleID = this.state.selectedRole.value;
            this.props.onMemberChange([...this.props.items, selectedMember])
            this.handleDropDownChange();
        }

    }

    // REMOVE A MEMBER FROM THE GRID
    onRemove(rowID) {

        var tempArray = this.props.items

        tempArray.forEach(function (element) {
            if (element.MemberID === rowID) {
                //the element has already been marked to be deleted. un-mark it
                if (element.RemoveDate) {
                    delete element.RemoveDate;
                } else {
                    element.RemoveDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SS[Z]");
                }
            }
        })
        this.props.onMemberChange(tempArray);
    }

    // FILTER DATEPICKER TO SHOW TODAY & PAST DAYS ONLY - IN ADD NEW MEMBER ROW
    isValidTerminationDate = (date) => {
        const day = date;
        return day <= new Date()
    }

    // HANDLE ROLE DROPDOWN CHANGE
    handleRolesDropDownChange = value => {
        var role = value;
        var selected = this.state.selectedMember;
        this.setState({
            selectedRole: role,
            selectedMember: { ...selected, CommitteeRoleID: role.value }
        })
    }

    // WHEN DRAGGED ITEM HAS BEEN PLACED
    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        let newItems = [...this.props.items];
        let [removed] = newItems.splice(result.source.index, 1);

        newItems.splice(result.destination.index, 0, removed);
        // Reorder the DisplaySequence value too!
        newItems.forEach((element, index) => {
            element.DisplaySequence = index + 1
        });
        this.props.onMemberChange(newItems);
    }

    // HANDLE EDITING OF ROLES INPUT (IN TABLE ROWS) - WILL NOT SET PROPS
    handleEditRoles(memberRole, valObj) {
        this.setState({ [memberRole]: valObj })
    }

    // HANDLE SAVING OF ROLES INPUT
    handleSaveRoles(index, memberRole, member) {
        const valObj = this.state[memberRole];
        const items = this.props.items.map((item, i) => {
            if (i === index) {
                return { ...item, CommitteeRoleID: valObj.value, isPendingAdd: true };
            } else {
                return item;
            }
        });

        this.props.onMemberChange(items);

        this.toggleInput(member)
    }

    // HANDLE EDITING OF SENIORITY INPUT - WILL NOT SET PROPS
    handleEditSeniority(memberSeniority, e) {
        this.setState({ [memberSeniority]: e.target.value })
    }

    // HANDLE SAVING OF SENIORITY INPUT
    handleSaveSeniority(index, memberSeniority, member) {
        const value = this.state[memberSeniority];
        const items = this.props.items.map((item, i) => {
            if (i === index) {
                return { ...item, Seniority: value, isPendingAdd: true };
            } else {
                return item;
            }
        });

        this.props.onMemberChange(items);

        this.toggleInput(member)
    }

    // HANDLE EDITING OF SENIORITY INPUT - WILL NOT SET PROPS
    handleEditVotingSequence(memberVotingSequence, e) {
        this.setState({ [memberVotingSequence]: e.target.value })
    }

    // HANDLE SAVING OF VOTE SEQUENCE INPUT
    handleSaveVotingSequence(index, memberVotingSequence, member) {
        const value = this.state[memberVotingSequence];
        const items = this.props.items.map((item, i) => {
            if (i === index) {
                return { ...item, VotingSequence: value, isPendingAdd: true };
            } else {
                return item;
            }
        });

        this.props.onMemberChange(items);

        this.toggleInput(member)
    }

    // HANDLE EDITING OF ASSIGN DATE INPUT - WILL NOT SET PROPS
    handleEditAssignDate(memberAssignDate, value) {
        this.setState({ [memberAssignDate]: value })
    }

    // HANDLE SAVING OF ASSIGN DATE INPUT
    handleSaveAssignDate(index, memberAssignDate, member) {
        let memberId;

        const value = this.state[memberAssignDate];
        const items = this.props.items.map((item, i) => {
            if (i === index) {
                memberId = item.MemberID
                return { ...item, AssignDate: value, isPendingAdd: true };
            } else {
                return item;
            }
        });

        this.props.onMemberChange(items);

        this.toggleInput(member, memberId + "tempAssignDateValue")
    }

    // HANDLE EDITING OF ASSIGN DATE INPUT - WILL NOT SET PROPS
    handleEditRemoveDate(memberRemoveDate, value) {
        this.setState({ [memberRemoveDate]: value })
    }

    // HANDLE SAVING OF ASSIGN DATE INPUT
    handleSaveRemoveDate(index, memberRemoveDate, member) {
        let memberId;

        const value = memberRemoveDate === "Now" ? moment() : this.state[memberRemoveDate];
        const items = this.props.items.map((item, i) => {
            if (i === index) {
                memberId = item.MemberID
                return { ...item, RemoveDate: value, isPendingAdd: true };
            } else {
                return item;
            }
        });

        this.props.onMemberChange(items);

        this.toggleInput(member, memberId + "tempRemoveDateValue")
    }

    // SHOW INPUT OR NOT
    toggleInput(value, tempValue, e) {
        var stateName = value;

        this.setState(prevState => ({
            [stateName]: !prevState[stateName],            
        }), () => {
            //Delete the temp value from state
            if(typeof tempValue === 'string') {                
                this.setState({
                    [tempValue]: undefined
                })
            }
        });
    }

    // FILTER TO DISPLAY ROLE NAMES BASED ON ROLE IDS
    getCommitteeRoleName(roleID) {
        var result = 'Member';
        this.props.dropdownDataRoles.forEach(function (element) {
            if (roleID === element.value) {
                result = element.label
            }
        })
        return result;
    }

    render() {
        const memberRoleEditStyle = {
            gridTemplateColumns: '1fr 15px 15px',
            gridGap: '5px',
            backgroundColor: '#f3f5f5',
        };
        const { dropdownDataRoles, name, dropdownDataMembers, items } = this.props;
        const { selectedMember } = this.state;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="memberDroppable">
                    {(provided, snapshot) => (
                        <table
                            className="dnd-grid-table members-grid"
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            <thead className="dnd-grid-header" style={getListStyle(snapshot.isDraggingOver)}>
                                <tr>
                                    <th scope="col" id={name + '-grabber-column'}></th>
                                    <th scope="col" id={name + '-display-column'}>Display #</th>
                                    <th scope="col" id={name + '-membername-column'}>Member Name</th>
                                    <th scope="col" id={name + '-role-column'}>{this.props.isSubcommittee ? "Subcommittee Role" : "Committee Role"}</th>
                                    <th scope="col" id={name + '-party-column'}>Party</th>
                                    <th scope="col" id={name + '-seniority-column'}>Seniority</th>
                                    <th scope="col" id={name + '-voting-sequence-column'} className="voting-sequence-header" onClick={this.props.sortByVotingSequence}>Voting Sequence</th>
                                    <th scope="col" id={name + '-date-column'}>Date Assigned</th>
                                    <th scope="col" id={name + '-date-column'}>Date Removed</th>
                                    <th scope="col" id={name + '-actions-column'}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <SelectMenu
                                    items={items}
                                    name={name}
                                    dropdownDataMembers={dropdownDataMembers}
                                    dropdownDataRoles={dropdownDataRoles}
                                    handleDropDownChange={this.handleDropDownChange}
                                    handleRolesDropDownChange={this.handleRolesDropDownChange}
                                    selectedMember={selectedMember}
                                    handleSeniorityChange={this.handleSeniorityChange}
                                    handleDateChange={this.handleDateChange}
                                    isValidTerminationDate={this.isValidTerminationDate}
                                    onInsert={this.onInsert}
                                />
                                {items.map((item, index) => (
                                    <Draggable key={`${item.MemberID}-${item.DisplaySequence}`} draggableId={`${item.MemberID}-${item.DisplaySequence}`} index={index}>
                                        {(provided, snapshot) => (
                                            <tr
                                                className={item.RemoveDate ? 'pending-delete' : 'nothing'}
                                                id={name + '-' + item.MemberID}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <td><button className="button draggable">Drag and drop</button></td>
                                                <td>{index + 1}</td>
                                                <td>{item.MemberDisplayName}</td>
                                                <td className={this.state[item.MemberID + 'showRoles'] ? 'td-split' : null} style={this.state[item.MemberID + 'showRoles'] ? memberRoleEditStyle : null}>
                                                    {this.state[item.MemberID + 'showRoles'] ?
                                                        <React.Fragment>
                                                            <Fields.Dropdown
                                                                options={dropdownDataRoles}
                                                                id={name + '-dropdownRoles'}
                                                                defaultValue={this.props.dropdownDataRoles.find(role => role.value === item.CommitteeRoleID)}
                                                                onChange={this.handleEditRoles.bind(this, item.MemberID + 'tempRoleValue')} />
                                                            <a
                                                                disabled={!this.state[item.MemberID + 'tempRoleValue']}
                                                                className="icon save"
                                                                onClick={this.handleSaveRoles.bind(this, index, item.MemberID + 'tempRoleValue', item.MemberID + 'showRoles')} ></a>
                                                            <a
                                                                onClick={this.toggleInput.bind(this, item.MemberID + 'showRoles')}
                                                                className="icon grey-delete"></a>
                                                        </React.Fragment>
                                                        :
                                                        <div
                                                            className={!this.state[item.MemberID + 'showRoles'] ? 'hide-input' : null}
                                                            id={item.MemberID + 'showRoles'}
                                                            onClick={this.toggleInput.bind(this, item.MemberID + 'showRoles')}>
                                                            {this.getCommitteeRoleName(item.CommitteeRoleID)}
                                                        </div>
                                                    }
                                                </td>
                                                <td>{item.PartyCode}</td>
                                                <td className={this.state[item.MemberID + 'showSeniority'] ? 'td-split' : null} style={this.state[item.MemberID + 'showSeniority'] ? memberRoleEditStyle : null}>
                                                    {this.state[item.MemberID + 'showSeniority'] ?
                                                        <React.Fragment>
                                                            <input
                                                                className="input-small"
                                                                id={item.MemberID + 'editSeniorityInput'}
                                                                type="text"
                                                                defaultValue={item.Seniority}
                                                                placeholder={item.Seniority}
                                                                onChange={this.handleEditSeniority.bind(this, item.MemberID + 'tempSeniorityValue')} />
                                                            <a
                                                                disabled={!this.state[item.MemberID + 'tempSeniorityValue']}
                                                                className="icon save"
                                                                onClick={this.handleSaveSeniority.bind(this, index, item.MemberID + 'tempSeniorityValue', item.MemberID + 'showSeniority')} ></a>
                                                            <a
                                                                onClick={this.toggleInput.bind(this, item.MemberID + 'showSeniority')}
                                                                className="icon grey-delete"></a>
                                                        </React.Fragment>
                                                        :
                                                        <span
                                                            className={!this.state[item.MemberID + 'showSeniority'] ? 'hide-input' : null}
                                                            id={item.MemberID + 'showSeniority'}
                                                            onClick={this.toggleInput.bind(this, item.MemberID + 'showSeniority')}>
                                                            {!this.state[item.MemberID + 'showSeniority'] && item.Seniority > 0 ? item.Seniority : ''}
                                                        </span>
                                                    }
                                                </td>
                                                <td className={this.state[item.MemberID + 'showVotingSequence'] ? 'td-split' : null} style={this.state[item.MemberID + 'showVotingSequence'] ? memberRoleEditStyle : null}>
                                                    {this.state[item.MemberID + 'showVotingSequence'] ?
                                                        <React.Fragment>
                                                            <input
                                                                className="input-small"
                                                                id={item.MemberID + 'editVotingSequenceInput'}
                                                                type="text"
                                                                defaultValue={item.VotingSequence}
                                                                placeholder={item.VotingSequence}
                                                                onChange={this.handleEditVotingSequence.bind(this, item.MemberID + 'tempVotingSequenceValue')} />
                                                            <a
                                                                disabled={!this.state[item.MemberID + 'tempVotingSequenceValue']}
                                                                className="icon save"
                                                                onClick={this.handleSaveVotingSequence.bind(this, index, item.MemberID + 'tempVotingSequenceValue', item.MemberID + 'showVotingSequence')} ></a>
                                                            <a
                                                                onClick={this.toggleInput.bind(this, item.MemberID + 'showVotingSequence')}
                                                                className="icon grey-delete"></a>
                                                        </React.Fragment>
                                                        :
                                                        <span
                                                            className={!this.state[item.MemberID + 'showVotingSequence'] ? 'hide-input' : null}
                                                            id={item.MemberID + 'showVotingSequence'}
                                                            onClick={this.toggleInput.bind(this, item.MemberID + 'showVotingSequence')}>
                                                            {!this.state[item.MemberID + 'showVotingSequence'] ? (item.VotingSequence ? item.VotingSequence : 0) : ''}
                                                        </span>
                                                    }
                                                </td>
                                                <td className={this.state[item.MemberID + 'showAssignDate'] ? 'td-split' : null} style={this.state[item.MemberID + 'showAssignDate'] ? memberRoleEditStyle : null}>
                                                    {this.state[item.MemberID + 'showAssignDate'] ?
                                                        <React.Fragment>
                                                            <DatePicker
                                                                id={item.MemberID + 'editAssignDateInput'}
                                                                className="datepicker-filter"
                                                                value={this.state[item.MemberID + 'tempAssignDateValue'] ? moment(this.state[item.MemberID + 'tempAssignDateValue']).format("L") : moment(item.AssignDate).format("L")}
                                                                onChange={this.handleEditAssignDate.bind(this, item.MemberID + 'tempAssignDateValue')} />
                                                            <a
                                                                disabled={!this.state[item.MemberID + 'tempAssignDateValue']}
                                                                className="icon save"
                                                                onClick={!this.state[item.MemberID + 'tempAssignDateValue'] ? this.toggleInput.bind(this, item.MemberID + 'showAssignDate', item.MemberID + 'tempAssignDateValue') : this.handleSaveAssignDate.bind(this, index, item.MemberID + 'tempAssignDateValue', item.MemberID + 'showAssignDate')}
                                                            >
                                                            </a>
                                                            <a
                                                                onClick={this.toggleInput.bind(this, item.MemberID + 'showAssignDate', item.MemberID + 'tempAssignDateValue')}
                                                                className="icon grey-delete"></a>
                                                        </React.Fragment>
                                                        :                                                        
                                                        <span
                                                            className={!this.state[item.MemberID + 'showAssignDate'] ? 'hide-input' : null}
                                                            id={item.MemberID + 'showAssignDate'}
                                                            onClick={this.toggleInput.bind(this, item.MemberID + 'showAssignDate')}>
                                                            {!this.state[item.MemberID + 'showAssignDate'] ? (item.AssignDate ? moment(item.AssignDate).format('L') : "") : ''}
                                                        </span>
                                                    }
                                                </td>
                                                <td className={this.state[item.MemberID + 'showRemoveDate'] ? 'td-split' : null} style={this.state[item.MemberID + 'showRemoveDate'] ? memberRoleEditStyle : null}>
                                                    {this.state[item.MemberID + 'showRemoveDate'] ?
                                                        <React.Fragment>
                                                            <DatePicker
                                                                id={item.MemberID + 'editRemoveDateInput'}
                                                                className="datepicker-filter"
                                                                value={this.state[item.MemberID + 'tempRemoveDateValue'] ? moment(this.state[item.MemberID + 'tempRemoveDateValue']).format("L") : moment(item.RemoveDate).format("L")}
                                                                onChange={this.handleEditRemoveDate.bind(this, item.MemberID + 'tempRemoveDateValue')} 
                                                                maxDate={moment()}
                                                                />
                                                            <a
                                                                disabled={!this.state[item.MemberID + 'tempRemoveDateValue']}
                                                                className="icon save"
                                                                onClick={!this.state[item.MemberID + 'tempRemoveDateValue'] ? this.handleSaveRemoveDate.bind(this, index, "Now", item.MemberID + 'showRemoveDate') : this.handleSaveRemoveDate.bind(this, index, item.MemberID + 'tempRemoveDateValue', item.MemberID + 'showRemoveDate')}
                                                            >
                                                            </a>
                                                            <a
                                                                onClick={this.toggleInput.bind(this, item.MemberID + 'showRemoveDate', item.MemberID + 'tempRemoveDateValue')}
                                                                className="icon grey-delete"></a>
                                                        </React.Fragment>
                                                        :                                                        
                                                        <span
                                                            className={!this.state[item.MemberID + 'showRemoveDate'] ? 'hide-input' : null}
                                                            id={item.MemberID + 'showRemoveDate'}
                                                            onClick={this.toggleInput.bind(this, item.MemberID + 'showRemoveDate')}>
                                                            {!this.state[item.MemberID + 'tempRemoveDateValue'] ? (item.RemoveDate ? moment(item.RemoveDate).format('L') : "") : ''}
                                                        </span>
                                                    }
                                                </td>
                                                <td>{item.RemoveDate ? <button type="button" onClick={this.onRemove.bind(this, item.MemberID)} className="button add">Delete</button> : ""}</td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}


function SelectMenu(props) {

    var dropdown = [];

    props.dropdownDataMembers.forEach(function (element) {
        let isDuplicate = false;
        props.items.forEach(function (item) {
            if (item.MemberID === element.MemberID) {
                isDuplicate = true;
            }
        });
        if (!isDuplicate) {
            dropdown.push(element);
        }
    })

    return (
        <tr id={props.name + '-insert-row'} className="dnd-insert-row">
            <td></td>
            <td></td>
            <td><Fields.Dropdown className="grid-selector-dropdown" classNamePrefix="grid-selector-dropdown" value={props.selectedMember} options={dropdown} id={props.name + '-dropdownMembers'} onChange={props.handleDropDownChange} /></td>
            <td><Fields.Dropdown className="grid-selector-dropdown" classNamePrefix="grid-selector-dropdown" options={props.dropdownDataRoles} id={props.name + '-dropdownRoles'} onChange={props.handleRolesDropDownChange} /></td>
            <td>{props.selectedMember.PartyCode}</td>
            <td><input id="seniorityInput" type="text" className="dnd-field" value={props.selectedMember.Seniority} onChange={props.handleSeniorityChange} /></td>
            <td><input id="votingSequenceInput" type="text" className="dnd-field" value={props.selectedMember.VotingSequence} onChange={props.handleEditVotingSequence} /></td>
            <td>
                <DatePicker
                    id="dateAssignedInput"
                    className="dnd-field"
                    selected={props.selectedMember.AssignDate}
                    onChange={props.handleDateChange}
                    popperPlacement="top-start"
                    autoComplete="off"
                    filterDate={props.isValidTerminationDate}
                    placeholderText="Date Assigned"

                />
            </td>
            <td></td>
            <td><button type="button" id={props.name + '-insert-button'} className="button" onClick={props.onInsert}>Insert</button></td>
        </tr>
    )
}
export default MembersDraggableComponent;