import React from "react";
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import '../../../stylesheets/shared/react-table.css';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { cancelRequest } from '../../../services/request.service';

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

class SessionGridComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultSession: '',
            error: null,
            isLoaded: false,
            data: [],            
            filtered: [{ id: 'all', value: 'true' }],
            currentYear: (new Date()).getFullYear(),
            isCurrentBiennium: true,
            filterAll: 'true',            
        };        
        this.filterAll = this.filterAll.bind(this);
        this.getSessionList = this.getSessionList.bind(this);
    }

    //When component has mounted, make the call to the list users API
    componentDidMount() {
        this.getSessionList();
    }

    onFilteredChange(filtered) {
        if (filtered.length > 1 && this.state.filterAll.length) {
            // NOTE: this removes any FILTER ALL filter
            const filterAll = '';
            this.setState({ filtered: filtered.filter((item) => item.id !== 'all'), filterAll })
        }
        else
            this.setState({ filtered });
    }

    getSessionList() {
        this.props.actions.getSessionList().then(() => {
            const defaultSession = this.props.session.sessionList.find(session => session.IsDefault === true)
            this.setState({
                data: this.props.session.sessionList,
                isLoaded: true,
                defaultSession: defaultSession ? defaultSession.SessionYear + ' ' + defaultSession.DisplayName : ""
            })
        })
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            return {
                style: {
                    background: rowInfo.original.IsDefault ? 'rgb(202, 225, 241)' : null,
                }
            }
        }
        return true;
    }


    filterAll(e) {
        const { value } = e.target;
        const filterAll = value;
        const filtered = [{ id: 'all', value: filterAll }];
        // NOTE: this completely clears any COLUMN filters
        this.setState({ filterAll, filtered });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { defaultSession } = this.state;
        return (
            <div>
                <div className="header-half">
                    <h1>Session Management</h1>
                    <p>Default Display Session: <span> {defaultSession}</span></p>
                </div>

                <div className="toolbar session-toolbar">
                    <div className="dlas-forms">
                        <select value={this.state.filterAll} onChange={this.filterAll}>
                            <option value="true">Current Biennium</option>
                            <option value="false">All Sessions</option>
                        </select> 
                    </div>
                    <div>
                        <Link to="/session-management/edit-session" className="button add-wide">New Session</Link>
                    </div>
                </div>
                <ReactTable
                    filtered={this.state.filtered}
                    resizable={false}
                    loading={!this.state.isLoaded}
                    defaultFilterMethod={(filter, row) => {
                        if (filter.value === "false") {
                            return true;
                        }
                        if (filter.value === "true") {
                            return row.SessionYear === this.state.currentYear || row.SessionYear === this.state.currentYear - 1;
                        }
                    }}
                    data={this.state.data}
                    ref={r => this.reactTable = r}
                    onFilteredChange={this.onFilteredChange.bind(this)}
                    SubComponent={row => {
                        return (
                            <div className={row.original.IsDefault ? 'selected-session' : null} style={{ padding: "10px" }}>
                                <ul>
                                    <li>{row.original.Name}</li>
                                    <li>{row.original.Year}</li>
                                    <li>{row.original.SessionType}</li>
                                </ul>
                            </div>
                        );
                    }}
                    columns={[
                        {
                            Header: "Year",
                            accessor: "SessionYear",
                            id: "yearfilter",
                            width: 80
                        },
                        {
                            Header: "Type",
                            accessor: "SessionType",
                            width: 85
                        },
                        {
                            Header: "Name",
                            accessor: "DisplayName",
                            width: 180
                        },
                        {
                            Header: "Prefile Start",
                            Cell: ({ original }) => {
                                if (original.SessionEvents !== undefined) {
                                    for (var i = 0; i < original.SessionEvents.length; i++) {
                                        //check for Session Start event type ID
                                        if (original.SessionEvents[i].DisplayName === "Prefile Date") {
                                            return moment(original.SessionEvents[i].ActualDate).format('L')
                                        }
                                    }
                                    return false;
                                } else {
                                    return ' '
                                }
                            }
                        },
                        {
                            Header: "Session Start",
                            Cell: ({ original }) => {
                                const sessionStartObj = original.SessionEvents && original.SessionEvents.length && original.SessionEvents.find(date => date.DisplayName === "Session Start");
                                const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate) : '';
                                return moment(startDate).format("MM/DD/YYYY");
                            }
                        },
                        {
                            Header: "Bill Cutoff",
                            Cell: ({ original }) => {
                                if (original.SessionEvents !== undefined) {
                                    for (var i = 0; i < original.SessionEvents.length; i++) {
                                        if (original.SessionEvents[i].DisplayName === "Bill Cutoff") {
                                            return moment(original.SessionEvents[i].ActualDate).format('L')
                                        }
                                    }
                                    return false;
                                } else {
                                    return ' '
                                }
                            }
                        },
                        {
                            Header: "Crossover",
                            Cell: ({ original }) => {
                                if (original.SessionEvents !== undefined) {
                                    for (var i = 0; i < original.SessionEvents.length; i++) {
                                        if (original.SessionEvents[i].DisplayName === "Crossover") {
                                            return moment(original.SessionEvents[i].ActualDate).format('L')
                                        }
                                    }
                                    return false;
                                } else {
                                    return ' '
                                }
                            }
                        },
                        {
                            Header: "Adjournment",
                            Cell: ({ original }) => {
                                if (original.SessionEvents !== undefined) {
                                    for (var i = 0; i < original.SessionEvents.length; i++) {
                                        if (original.SessionEvents[i].DisplayName === "Adjournment") {
                                            return moment(original.SessionEvents[i].ActualDate).format('L')
                                        }
                                    }
                                    return false
                                } else {
                                    return ' '
                                }
                            }
                        },
                        {
                            Header: "Reconvene",
                            Cell: ({ original }) => {
                                if (original.SessionEvents !== undefined) {
                                    for (var i = 0; i < original.SessionEvents.length; i++) {
                                        if (original.SessionEvents[i].DisplayName === "Reconvene") {
                                            return moment(original.SessionEvents[i].ActualDate).format('L')
                                        }
                                    }
                                    return false;
                                } else {
                                    return ' '
                                }
                            }
                        },
                        {
                            Header: "Action",
                            headerClassName: "header-action",
                            accessor: "action",
                            className: "action-column",
                            width: 50,
                            expander: true,
                            Expander: ({ isExpanded, ...rest }) => {
                                return (
                                    <button className="button view">View</button>
                                );
                            }
                        },
                        {
                            Header: "",
                            accessor: "edit",
                            headerClassName: "all-column",
                            width: 40,
                            Cell: row => {
                                return (
                                    <Link to={`/session-management/edit-session/${row.original.SessionID}`} className="button edit">Edit</Link>
                                )
                            }
                        },
                        {
                            Header: "All",
                            id: 'all',
                            headerClassName: "all-column",
                            width: 0,
                            resizable: false,
                            sortable: false,
                            Filter: () => { },
                            getProps: () => {
                                return {
                                    style: { padding: "0px"}
                                }
                            },
                            filterMethod: (filter, rows) => {
                                if (filter.value === "false") {
                                    return true;
                                }
                                if (filter.value === "true") {
                                    return rows.yearfilter === this.state.currentYear || rows.yearfilter === this.state.currentYear - 1;
                                }
                            }
                        }
                    ]}
                    defaultSorted={[
                        {
                            id: "yearfilter",
                            desc: true
                        }
                    ]}
                    defaultPageSize={10}
                    className="session-table -striped -highlight"
                    getTrProps={this.getTrProps}
                />
                <br />

            </div>
        );
    }
}

const SessionManagementGrid = connect(
    (state) => {
        const { session } = state;
        return {
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators), dispatch)
        }
    }
)(SessionGridComponent)

export default SessionManagementGrid;