import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { sessionActionCreators } from '../../../stores/lis-session-store';

class SearchInputComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({ search: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        //If nothing is in the search box then don't search
        if (this.state.search.replace(/\s/g, '') === '') {
            return;
        }

        let session, getSessionPromise;
        if (this.props.session.sessionList && this.props.session.sessionList.length) {
            session = this.props.session.sessionList.find(session => session.SessionCode === this.props.nav.session).SessionID;
        } else {
            getSessionPromise = this.props.actions.getSessionByCode(this.props.nav.session)
                .then(() => {
                    session = this.props.session.selectedSession.SessionID;
                })
        }

        Promise.all([getSessionPromise]).finally(() => {
            // Check to see if the search term contains a bill number
            // This regex means 'match any of the bill prefixes even if it is seperated by non alpha-numberic characters. Only match if it is followed by a number. Also, include the number in the match'
            // A greater than sign (>) or less than sign (<) is a symbol used to search multiple bills. It also is part of a valid bill number
            const regexResult = this.state.search.match(/[HS][^0-9A-Z]*[BJR][^0-9A-Z]*(?=[0-9])[0-9]+[<>-]?/gi);
            let legNumbers = '';
            let searchTerms = '';
            if (regexResult) {
                //bill number(s) found
                legNumbers = '';
                let foundRange = false;
                let searchWithRemovedBillNumbers = this.state.search;
                regexResult.forEach((result, resultIndex) => {
                    foundRange = result.match(/-/g);
                    //If it has a dash then it is a range of bills and should not get a comma after it
                    if (foundRange !== null) {
                        //Remove all the characters that are not a letter, number, or dash
                        legNumbers += result.replace(/[^A-Za-z0-9\-]+/g, '');
                    } else {
                        //Remove all the characters that are not a letter, number, greater than sign, or less than sign
                        legNumbers += result.replace(/[^A-Za-z0-9<>]+/g, '');
                        //Add a comma between the legislation numbers so the api can search for multiple legislation numbers
                        if (regexResult.length !== resultIndex + 1) {
                            legNumbers += ','
                        }
                    }
                    // Remove the bill numbers from the search string. If there is anything left then add them as search terms
                    searchWithRemovedBillNumbers = searchWithRemovedBillNumbers.replace(result, '')
                });
                searchTerms = searchWithRemovedBillNumbers;
                // Remove any commas since those were probably added by the user to seperate the bill numbers
                searchTerms = searchTerms.replace(',', '');

            } else {
                searchTerms = this.state.search;
            }

            if (/[A-Za-z0-9]+/g.test(searchTerms)) {
                searchTerms = searchTerms.trim();
            } else {
                // Don't add search terms if there are no letters or numbers in it
                searchTerms = '';
            }
            const param = {
                selectedBillNumbers: legNumbers,
                selectedKeywords: searchTerms,
                selectedSession: session
            }
            const link = window.btoa(JSON.stringify(param));
            let url = '/bill-search?q=' + link;
            if (this.props.location.pathname === '/bill-search') {
                //if they have selected a watchlist, keep the watchlist set in the url
                if (queryString.parse(this.props.location.search).collection) url += "&collection=" + queryString.parse(this.props.location.search).collection;
                //if they are re-executing the same search (i.e. same parameters) as they're currently viewing (essentially a refresh), just add a rnd so the url changes (needed for search to execute)
                const parsed = queryString.parse(this.props.location.search, { decode: false });
                if (parsed.q && parsed.q === link) {
                    url += '&rnd=' + new Date().getTime();
                }
            }
            this.props.history.push(url);
        });
    }

    render() {
        return (
            <div className="global-search-box">
                <form>
                    <label htmlFor="quick-search" className="screen-reader-only">Search for keyword or bill number</label>
                    <input type="text" id="quick-search" className="quick-search" onChange={this.handleInputChange} value={this.state.search} placeholder="Search for keyword or bill number" />
                    <input type="submit" aria-label="search" value="GO" onClick={this.handleSubmit} className="input-searchbtn" />
                </form>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { nav, session } = state;
        return {
            nav,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, sessionActionCreators), dispatch)
        }
    }
)(withRouter(SearchInputComponent))