import React from 'react';
import DatePicker from 'react-datepicker';
import { LargeListSelect } from '../../../lis-shared/lis-layout/components/lis-forms-component';
import InputType from '../../lis-minutes-management/components/input-types';
import moment from 'moment-timezone';

class EventControls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventDate: moment(),
            eventSequence: '',
            event: "",
            otherEvent: "",
            templateInputs: [],
            templateEdited: false,
            otherEventSelected: false
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSequenceChange = this.handleSequenceChange.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.handleOtherEventChange = this.handleOtherEventChange.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.removeEvent = this.removeEvent.bind(this);
        this.unSelectOtherEvent = this.unSelectOtherEvent.bind(this);
    }

    handleDateChange(val) {
        this.setState({
            eventDate: val
        });
    }

    handleSequenceChange(val) {
        if (val || val === '') {
            val = val.trim(); // ' ' (i.e. space) gets treated as a number in isNaN (below), so trim here since '' does not
            if (val && !isNaN(val)) {
                val = Number(val)
                this.setState({ eventSequence: val })
            } else if (val === '') {
                this.setState({ eventSequence: val })
            }
        }
    }

    handleEventChange(val) {
        let templateInputs = [];
        if (val.value != "Other") {
            if (val.ActionReferences && val.ActionReferences.length > 0) {
                templateInputs = JSON.parse(JSON.stringify(val.ActionReferences));
            }
            this.setState({
                event: val,
                templateInputs: templateInputs,
                templateEdited: false
            });
        } else {
            this.setState({
                event: val,
                otherEventSelected: true
            })
            if (this.props.otherBillEventOptions.length === 0) {
                this.props.getOtherBillEventOptions();
            }
        }
    }

    handleOtherEventChange(val) {
        let templateInputs = [];
        if (val.ActionReferences && val.ActionReferences.length > 0) {
            templateInputs = JSON.parse(JSON.stringify(val.ActionReferences));
        }
        this.setState({
            otherEvent: val,
            templateInputs: templateInputs,
            templateEdited: false
        });
    }

    handleTemplateChange(_a, _b, referenceIndex, reference) {
        let templateInputs = [...this.state.templateInputs];
        templateInputs[referenceIndex] = reference;
        this.setState({
            templateInputs: templateInputs,
            templateEdited: true
        });
    }

    addEvent() {
        let eventObj = this.state.event.value != "Other" ? { ...this.state.event } : { ...this.state.otherEvent };
        if (eventObj.ActionReferences && eventObj.ActionReferences.length > 0 && this.state.templateInputs.length > 0 && this.state.templateEdited) {
            eventObj.EventReferences = this.state.templateInputs;
            eventObj.Description = this.state.templateInputs.map(tInput => {
                if (!tInput.ReferenceText) {
                    return `${tInput.ActionReferenceType}`
                }
                return tInput.ReferenceText
            }).join('');
        } else {
            eventObj.Description = eventObj.label;
        }
        let actorType = '';
        switch (eventObj.EventCode ? eventObj.EventCode[0].toUpperCase() : eventObj.LegislationChamberCode) {
            case 'H':
                actorType = 'House';
                break;
            case 'S':
                actorType = 'Senate';
                break;
            case 'C':
                actorType = 'Conference';
                break;
            case 'G':
                actorType = 'Governor';
                break;
        }
        eventObj.ActorType = actorType;
        this.props.addEvent(this.state.eventDate, this.state.eventSequence, eventObj);
        //Reset the selectors after adding the event
        this.unSelectOtherEvent();
        this.setState({
            eventAdded: true,
            eventSequence: ''
        });
        setTimeout(() => {
            this.setState({
                eventAdded: false
            })
        }, 2000)
    }

    removeEvent() {
        this.props.removeEvent(this.state.eventDate, this.state.event, this.state.eventSequence || null)
        this.setState({
            eventRemoved: true
        });
        setTimeout(() => {
            this.setState({
                eventRemoved: false
            })
        }, 2000)
    }

    unSelectOtherEvent() {
        this.setState({
            otherEventSelected: false,
            event: "",
            templateInputs: [],
            templateEdited: false,
            otherEvent: ""
        })
    }

    render() {
        const { billEventOptions, otherBillEventOptions } = this.props;
        const fontSize = '0.8em';
        const selectStyle = {
            control: styles => ({
                ...styles,
                minHeight: '0px',
                padding: '0.12em 0.6em !important',
            }),
            valueContainer: styles => ({
                ...styles,
                padding: 0,
            }),
            input: styles => ({
                ...styles,
                fontSize: fontSize,
                lineHeight: 'normal',
            }),
            singleValue: styles => ({
                ...styles,
                fontSize: fontSize,
            }),
            placeholder: styles => ({
                ...styles,
                fontSize: fontSize
            }),
            option: styles => ({
                ...styles,
                fontSize: fontSize
            }),
            dropdownIndicator: styles => ({
                ...styles,
                padding: '4px'
            }),
        }
        const buttonDisabled = !this.state.event
            || !this.state.eventDate
            || this.props.checkedBills.every(c => !c)
            || this.state.eventAdded
            || this.state.eventRemoved;
        return (
            <div>
                <span style={{ fontSize: '12px' }}>* indicates required field</span>
                <div className="extra-spacing dlas-forms inner-grid" style={{ gridTemplateColumns: '1fr 0.5fr 3fr' }}>
                    <div>
                        <label>Event Date*</label>
                        <DatePicker
                            required
                            selected={this.state.eventDate}
                            onChange={this.handleDateChange}
                            className='event-date-picker'
                        />
                    </div>
                    <div>
                        <label>Sequence</label>
                        <input min={0} style={{ width: '80%' }} maxLength={3} value={this.state.eventSequence} onChange={(e) => this.handleSequenceChange(e.target.value)} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Event*</label>
                        <div className="inner-grid bill-history-event-controls">
                            <LargeListSelect
                                required
                                options={billEventOptions}
                                styles={selectStyle}
                                value={this.state.event}
                                getOptionLabel={opt => opt.EventCode ? "(" + opt.EventCode + ") " + opt.label : opt.label}
                                onChange={this.handleEventChange}
                                isDisabled={this.state.otherEventSelected}
                            />
                            {this.state.otherEventSelected &&
                                <button type="button" className='icon delete' onClick={() => this.unSelectOtherEvent()}></button>
                            }
                        </div>
                        {this.state.otherEventSelected &&
                            <div className="inner-grid bill-history-event-controls" style={{ marginTop: "10px" }}>
                                <LargeListSelect
                                    options={otherBillEventOptions}
                                    styles={selectStyle}
                                    value={this.state.otherEvent}
                                    getOptionLabel={opt => opt.EventCode ? "(" + opt.EventCode + ") " + opt.label : opt.label}
                                    onChange={this.handleOtherEventChange}
                                />
                            </div>
                        }
                    </div>
                </div>
                {this.state.templateInputs.length > 0 &&
                    <div >
                        <InputType
                            memberList={this.props.patronList}
                            comitteeList={this.props.committeeList}
                            textList={this.props.textList}
                            actionTypes={this.props.actionTypes}
                            handleChange={this.handleTemplateChange}
                            entryIndex={0}
                            activityIndex={0}
                            references={this.state.templateInputs}
                        />
                    </div>
                }
                <br />
                <div>
                    <div></div>
                    <div className="align-right inline-list">
                        <button disabled={buttonDisabled} type="button" className="button" onClick={this.addEvent}>{this.state.eventAdded ? "Event Added" : "Add Event"}</button>
                        <button disabled={buttonDisabled} type="button" className="button danger" onClick={this.removeEvent}>{this.state.eventRemoved ? "Event Removed" : "Remove Event"}</button>
                    </div>
                </div>
                <br />
                <hr className="faded-line" />
            </div>
        )
    }
}


export default EventControls;
