import React from "react";
import { Prompt } from "react-router";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import ReactToPrint from "react-to-print";
import VoteDetails from "../../../lis-shared/lis-votes/lis-vote-details";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { voteActionCreators } from '../../../stores/lis-votes-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import Select from 'react-select';
import Popout from '../../../lis-shared/lis-layout/components/lis-popout-component';
import { sessionActionCreators } from "../../../stores/lis-session-store";
import { calendarActionCreators } from "../../../stores/lis-calendar-store";
import { cancelRequest } from '../../../services/request.service';
import { billActionCreators } from "../../../stores/lis-legislation-store";

const PassFailOptions = [{ label: "Pass", value: "P" }, { label: "Fail", value: "F" }];

class VoteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voteData: '',
            message: '',
            isPreviewing: false,
            isLoaded: false,
            changeVotes: false,
            selectedVoteActionDescription: '',
            voteActionList: [],
            selectedCalendarCategoryType: '',
            calendarCategoryTypeList: [],
            pendingSave: false,
            isDirty: false,
            showWarning: false,
            settingIsBlock: false,
            billList: []
        };

        this.resetForm = this.resetForm.bind(this);
        this.resetVoteMembers = this.resetVoteMembers.bind(this);
        this.getVoteActionReferences = this.getVoteActionReferences.bind(this);
        this.getCalendarCategoryTypes = this.getCalendarCategoryTypes.bind(this);
        this.handleVoteActionDescriptionChange = this.handleVoteActionDescriptionChange.bind(this);
        this.handleCalendarCategoryTypeChange = this.handleCalendarCategoryTypeChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleVoteChange = this.handleVoteChange.bind(this);
        this.handleVoteLegislationChange = this.handleVoteLegislationChange.bind(this);
        this.updateStatement = this.updateStatement.bind(this);
        this.togglePreview = this.togglePreview.bind(this);
        this.togglePortal = this.togglePortal.bind(this);
        this.toggleTallySheetPDFPreview = this.toggleTallySheetPDFPreview.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.toggleChangeVotes = this.toggleChangeVotes.bind(this);
        this.setVoteTally = this.setVoteTally.bind(this);
        this.toggleWarning = this.toggleWarning.bind(this);
        this.getVoteListForFinalVote = this.getVoteListForFinalVote.bind(this);
        this.toggleEditVoteLegislation = this.toggleEditVoteLegislation.bind(this);

        this.pdfRef = React.createRef();
    }

    resetForm() {
        const originalVoteData = JSON.parse(JSON.stringify(this.props.votes.voteDetailsResponse[0]));
        this.setState({
            pendingSave: false,
            voteData: originalVoteData,
            selectedVoteActionDescription: this.state.voteActionList.find(x => x.EventCode === originalVoteData.EventCode),
            selectedCalendarCategoryType: this.state.calendarCategoryTypeList.find(x => x.CalendarCategoryTypeID === originalVoteData.CalendarCategoryTypeID) || null,
            isDirty: false
        });
    }

    resetVoteMembers() {
        let voteData = { ...this.state.voteData };
        let originalVoteData = JSON.parse(JSON.stringify(this.props.votes.voteDetailsResponse[0]));
        const originalVoteMembers = originalVoteData.VoteMember;
        voteData.VoteMember = originalVoteMembers;
        this.setState({
            voteData,
            isDirty: false
        }, () => {
            this.setVoteTally();
        })
    }

    getVoteActionReferences(setSelectedValue) {
        this.setState({ loadingVoteActionList: true }, () => {
            this.props.actions.getVoteActionList("?"
                + (this.state.voteData.CalendarCategoryTypeID ? "calendarCategoryTypeId=" + this.state.voteData.CalendarCategoryTypeID + "&" : "")
                + "committeeID=" + this.state.voteData.CommitteeID
                + "&voteTypeID=" + this.state.voteData.VoteTypeID
                + "&chamberCode=" + this.state.voteData.ChamberCode)
                .then(() => {
                    let voteActionList = [...this.props.votes.voteActionList];

                    // if this is a committee vote, separate the vote action options between actions of this vote's committee and then all other committee's vote actions
                    if (this.state.voteData.VoteType !== "Floor") {
                        voteActionList = [
                            {
                                label: this.state.voteData.CommitteeName ? this.state.voteData.CommitteeName + " Vote Actions" : 'Vote Actions From This Committee',
                                options: [...voteActionList.filter(v => v.EventCode.substr(1, 2) === this.state.voteData.EventCode.substr(1, 2))]
                            },
                            {
                                label: 'Vote Actions From Other Committees',
                                options: [...voteActionList.filter(v => v.EventCode.substr(1, 2) !== this.state.voteData.EventCode.substr(1, 2))]
                            }
                        ]
                    }

                    if (setSelectedValue) {
                        let voteAction = voteActionList.flatMap(x => x.options || x).find(x => x.EventCode === this.state.voteData.EventCode) || null;
                        let voteData = { ...this.state.voteData };
                        if (voteAction) {
                            voteData.VoteActionID = voteAction.VoteActionID;
                            voteData.EventCode = voteAction.EventCode;
                        } else if (voteData.EventCode && voteData.VoteActionDescription) {
                            const voteObj = { EventCode: voteData.EventCode, Description: voteData.VoteActionDescription, ChamberCode: voteData.ChamberCode, VoteActionID: voteData.VoteActionID }
                            if (this.state.voteData.VoteType !== "Floor") {
                                voteActionList[0].options.push(voteObj);
                            } else {
                                voteActionList.push(voteObj)
                            }
                            voteAction = voteActionList.flatMap(x => x.options || x).find(x => x.EventCode === this.state.voteData.EventCode) || null;
                        }
                        this.setState({ selectedVoteActionDescription: voteAction, voteData })
                    }
                    this.setState({ voteActionList, loadingVoteActionList: false })
                })
        })
    }

    getCalendarCategoryTypes() {
        this.props.actions.getCalendarCategoriesReferences('?Chambercode=' + this.state.voteData.ChamberCode)
            .then(() => {
                let calendarCategoryTypeList = this.props.calendar.calendarCategoriesReferences;
                let selectedCalendarCategoryType = calendarCategoryTypeList.find(x => x.CalendarCategoryTypeID === this.state.voteData.CalendarCategoryTypeID);
                if (!selectedCalendarCategoryType && this.state.voteData.CalendarCategoryTypeID && this.state.voteData.CategoryDescription) {
                    calendarCategoryTypeList.push({ Description: this.state.voteData.CategoryDescription, CalendarCategoryTypeID: this.state.voteData.CalendarCategoryTypeID })
                    selectedCalendarCategoryType = calendarCategoryTypeList.find(x => x.CalendarCategoryTypeID === this.state.voteData.CalendarCategoryTypeID);
                }
                this.setState({
                    calendarCategoryTypeList,
                    selectedCalendarCategoryType
                });
            });
    }

    handleVoteActionDescriptionChange(value) {
        let voteData = { ...this.state.voteData };
        voteData.VoteActionDescription = value.Description;
        voteData.ChamberCode = value.ChamberCode;
        voteData.EventCode = value.EventCode;
        voteData.VoteActionID = value.VoteActionID;
        voteData.Description = voteData.VoteActionDescription + " " + voteData.VoteTally;

        this.setState({
            pendingSave: true,
            voteData: voteData,
            selectedVoteActionDescription: this.state.voteActionList.find(x => x.EventCode === voteData.EventCode),
            isDirty: true
        });
    }

    handleCalendarCategoryTypeChange(value) {
        let voteData = { ...this.state.voteData };
        voteData.CalendarCategoryTypeID = value.CalendarCategoryTypeID;

        this.setState({
            pendingSave: true,
            voteData: voteData,
            selectedCalendarCategoryType: this.state.calendarCategoryTypeList.find(x => x.CalendarCategoryTypeID === value.CalendarCategoryTypeID),
            isDirty: true
        }, () => {
            this.getVoteActionReferences(true);
        });
    }

    handleInputChange(key, value) {
        let voteData = { ...this.state.voteData };
        voteData[key] = value;
        let promise;
        if (key === "VoteTally") {
            voteData.Description = voteData.VoteActionDescription + " " + voteData.VoteTally
        } else if (key === "IsBlock") {
            if (voteData.VoteLegislation && voteData.VoteLegislation.filter(v => !v.DeletionDate).length > 1 && !value) {
                return;
            } else if (!this.state.billList || !this.state.billList.length) {
                //Get bills in the session for the bill dropdown
                promise = new Promise(res => {
                    this.setState({ settingIsBlock: true }, () => {
                        this.props.actions.getSessionBills('?sessionID= ' + voteData.SessionID).then(() => {
                            let billList = [...this.props.bills.sessionBills];
                            billList.forEach(bill => {
                                bill.label = bill.LegislationNumber
                                bill.value = bill.LegislationID
                            });
                            this.setState({
                                billList: billList,
                                settingIsBlock: false
                            })
                            res();
                        })
                    });
                })
            }
        }
        Promise.resolve(promise).then(() => {
            this.setState({
                pendingSave: true,
                voteData: voteData,
                isDirty: true
            }, () => {
                //if they are setting it to be the final vote, and we have not already checked to make sure this is valid based on other votes on this date, do so now
                if (key === "FinalVote" && !this.state.committeeSameDayVoteList && this.state.voteData.FinalVote && this.state.voteData.VoteType === "Committee" && this.state.voteData.CommitteeID && !this.state.voteData.ParentCommitteeID) {
                    this.getVoteListForFinalVote(this.state.voteData);
                }
            });
        })
    }

    handleDateChange(val) {
        let voteData = { ...this.state.voteData };
        voteData.VoteDate = val;
        this.setState({
            pendingSave: true,
            voteData: voteData,
            isDirty: true
        });
    }

    handleVoteChange(index, val) {
        let voteData = { ...this.state.voteData };
        voteData.VoteMember[index].ResponseCode = val;
        this.setState({
            pendingSave: true,
            voteData: voteData,
            isDirty: true
        }, () => {
            this.setVoteTally();
        });
    }

    toggleEditVoteLegislation(undo) {
        this.setState({ editingVoteLegislation: !this.state.editingVoteLegislation, showEditingVoteLegislationMessage: false }, () => {
            let voteData = { ...this.state.voteData }
            if (this.state.editingVoteLegislation) {
                this.setState({ uneditedVoteLegislation: JSON.parse(JSON.stringify(voteData.VoteLegislation)) })
            } else if (undo === true) {
                voteData.VoteLegislation = this.state.uneditedVoteLegislation;
                this.setState({ voteData });
            }
        })
    }

    handleVoteLegislationChange(_, behavior) {
        let voteData = { ...this.state.voteData };
        let voteLegislation = [...voteData.VoteLegislation];
        if (behavior && behavior.action) {
            switch (behavior.action) {
                case 'select-option':
                    const deletedLegIndex = voteLegislation.findIndex(vl => vl.DeletionDate && vl.LegislationID === behavior.option.LegislationID);
                    if (deletedLegIndex >= 0) {
                        voteLegislation[deletedLegIndex].DeletionDate = null;
                    } else {
                        voteLegislation.push({ LegislationNumber: behavior.option.LegislationNumber, LegislationID: behavior.option.LegislationID });
                    }
                    break;
                case 'remove-value':
                    const legIndex = voteLegislation.findIndex(vl => !vl.DeletionDate && vl.LegislationID === behavior.removedValue.LegislationID);
                    voteLegislation[legIndex].DeletionDate = moment();
                    break;
                case 'clear':
                    voteLegislation.filter(vl => !vl.DeletionDate).forEach(vl => vl.DeletionDate = moment())
                    break;
                default:
                    break;
            }
        }

        voteData.VoteLegislation = voteLegislation;
        voteData.IsBlock = voteLegislation.filter(vl => !vl.DeletionDate).length > 1 ? true : voteData.IsBlock;
        this.setState({
            voteData
        });
    }

    updateStatement(voteMembers) {
        let voteData = { ...this.state.voteData };
        voteData.VoteMember = voteMembers;
        this.setState({
            pendingSave: true,
            voteData: voteData,
            isDirty: true
        });
    }

    setVoteTally() {
        const yeaVotes = this.state.voteData.VoteMember.filter(vm => vm.ResponseCode === "Y").length.toString();
        const nayVotes = this.state.voteData.VoteMember.filter(vm => vm.ResponseCode === "N").length.toString();
        const abstainVotes = this.state.voteData.VoteMember.filter(vm => vm.ResponseCode === "A").length.toString();
        this.handleInputChange('VoteTally', '(' + yeaVotes + '-Y ' + nayVotes + "-N" + (abstainVotes !== "0" ? " " + abstainVotes + "-A" : "") + ")")
    }

    togglePreview() {
        this.setState(state => ({
            isPreviewing: !state.isPreviewing
        }));
    }

    togglePortal() {
        this.setState({
            showPortal: !this.state.showPortal
        }, () => {
            if (this.state.showPortal) {
                this.toggleTallySheetPDFPreview();
            }
        });
    }

    toggleTallySheetPDFPreview() {
        this.setState({
            gettingPdf: true
        });
        // This endpoint returns a pdf document and not json so the partnerRequest function will not work
        // It doesn't need to be saved to the redux store because it will not be referenced after the user sees it. So it is called here within the component.
        const API_URL = window.env ? window.env.API_SERVER : '';
        const idToken = 'LIS_ID_TOKEN';
        let signal = new window.AbortController().signal;
        const config = {
            headers: {
                'content-type': "application/json; charset=utf-8",
                Authorization: "Bearer " + localStorage.getItem(idToken),
                'WebAPIKey': process.env.REACT_APP_API_KEY
            },
            method: "GET",
            signal: signal
        };
        let params = `?voteId=${this.state.voteData.VoteID}`
        fetch((API_URL || process.env.REACT_APP_VOTE_FILE_GEN_URL) + '/VoteFileGeneration/api/PreviewTallySheetFileAsync' + params, config)
            .then(response => {
                if (response.status === 204) {
                    throw 'No Content';
                } else if (response.status > 399) {
                    throw response;
                } else {
                    response.blob()
                        .then(res => {
                            if (this.pdfRef && this.pdfRef.current) {
                                let pdfRef = this.pdfRef.current;
                                pdfRef.setAttribute('src', URL.createObjectURL(res));
                                pdfRef.onload = () => {
                                    this.setState({
                                        gettingPdf: false
                                    });
                                };
                            }
                        })
                }
            }).catch(err => {
                if (err.name === 'AbortError') {
                    return;
                }
                this.setState({
                    gettingPdf: false
                });
                //This is a response and the text needs to be extracted
                if (err.text) {
                    err.text().then(err => console.log(err.toString()));
                } else {
                    console.log(err.toString())
                }
                this.props.actions.makeToast([{ message: "Failed To Get Data", type: "failure" }]);
            })
    }

    toggleWarning() {
        this.setState({
            showWarning: !this.state.showWarning
        })
    }

    handleSave(isPublishing) {
        this.setState({ isSaving: true, showWarning: false }, () => {
            let voteData = { ...this.state.voteData };
            if (isPublishing) {
                voteData.IsPublic = true;
            }
            this.props.actions.saveVote({ VoteList: [voteData] })
                .then(async () => {
                    if (this.props.votes.voteSaveError) {
                        throw this.props.votes.voteSaveError;
                    }
                    if (this.props.votes.voteSave.length === 0) {
                        throw 'No vote data returned'
                    }
                    this.setState({ pendingSave: false })
                    const voteData = JSON.parse(JSON.stringify(this.props.votes.voteSave[0]));
                    if (isPublishing) {
                        //Call Vote File Generation and move the user back to the grid
                        this.props.actions.voteFileGen('?voteID=' + voteData.VoteID)
                            .then(() => {
                                if (this.props.votes.voteFileError) {
                                    console.log(this.props.votes.voteFileError.toString());
                                }
                            })
                        if (voteData.FinalVote && voteData.VoteType === "Committee" && voteData.CommitteeID && !voteData.ParentCommitteeID && voteData.VoteDate) {
                            let committeeSameDayVoteList = [...this.state.committeeSameDayVoteList];
                            committeeSameDayVoteList = committeeSameDayVoteList.concat(voteData.VoteLegislation.filter(vl => !vl.DeletionDate).map(vl => { return { LegislationNumber: vl.LegislationNumber, EventCode: voteData.EventCode } }));
                            const success = await this.props.createChairmansReportSummarizedOnMinutes(voteData.SessionID, voteData.ChamberCode, voteData.CommitteeID, moment(voteData.VoteDate).utc().hour(0), committeeSameDayVoteList);
                            if (!success) {
                                this.props.actions.makeToast([{ message: "Vote Publish Successful, Chairman's Report Save Failed", type: "failure", long: true }]);
                            } else {
                                this.props.actions.makeToast([{ message: "Vote Published", type: "success" }]);
                            }
                        } else {
                            this.props.actions.makeToast([{ message: "Vote Published", type: "success" }]);
                        }
                        this.setState({ isDirty: false }, () => {
                            this.props.history.push('/vote-management');
                        });
                    } else {
                        this.props.actions.makeToast([{ message: "Vote Saved", type: "success" }]);
                        this.setState({
                            voteData: voteData,
                            isSaving: false,
                            isDirty: false,
                            changeVotes: false
                        });
                    }
                }).catch((err) => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err.toString());
                    this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false
                    });
                });
        });
    }

    toggleChangeVotes() {
        this.setState({ changeVotes: !this.state.changeVotes }, () => {
            if (!this.state.changeVotes) {
                this.resetVoteMembers();
            }
        })
    }

    componentDidMount() {
        const voteId = this.props.match.params.voteid;
        this.props.actions.getVoteDetails('?voteID=' + voteId, true)
            .then(() => {
                if (this.props.votes.voteDetailsError) {
                    throw this.props.votes.voteDetailsError;
                }
                if (this.props.votes.voteDetailsResponse.length === 0) {
                    throw "Cannot get vote data";
                }
                const voteData = JSON.parse(JSON.stringify(this.props.votes.voteDetailsResponse[0]));

                //Get bills in the session for the bill dropdown
                this.props.actions.getSessionBills('?sessionID= ' + voteData.SessionID).then(() => {
                    let billList = [...this.props.bills.sessionBills];
                    billList.forEach(bill => {
                        bill.label = bill.LegislationNumber
                        bill.value = bill.LegislationID
                    });
                    this.setState({
                        billList: billList,
                    })

                    // if this is a final vote, check other votes for this committee on this day to ensure whether or not this vote should be publishable
                    if (voteData.FinalVote && voteData.VoteType === "Committee" && voteData.CommitteeID && !voteData.ParentCommitteeID) {
                        this.getVoteListForFinalVote(voteData);
                    } else {
                        this.setState({
                            isLoaded: true,
                            voteData: voteData
                        }, () => {
                            this.getVoteActionReferences(true);
                            if (voteData.VoteType === "Floor") {
                                this.getCalendarCategoryTypes();
                            }
                        });
                    }
                    //Get the session data for use in the preview
                    this.props.actions.getSessionById(this.props.votes.voteDetailsResponse[0].SessionID)
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
                this.setState({
                    message: err.toString(),
                    isLoaded: true
                });
            });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    getVoteListForFinalVote(voteData) {
        let voteParams = `?startDate=${moment(voteData.VoteDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')}&endDate=${moment(voteData.VoteDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`;
        voteParams += '&chamberCode=' + voteData.ChamberCode;
        voteParams += '&voteTypeID=' + voteData.VoteTypeID;
        voteParams += '&committeeID=' + voteData.CommitteeID;
        this.props.actions.getVoteList(voteParams)
            .then(() => {
                if (this.props.votes.voteListError) {
                    throw this.props.votes.voteListError;
                }
                if (this.props.votes.voteList.length === 0) {
                    throw 'No Votes in list'; // there should be at least one considering we already retrieved one
                }
                this.setState({
                    isLoaded: true,
                    voteData: voteData,
                    preventPublish: this.props.votes.voteList.find(v => v.VoteID !== voteData.VoteID && v.CommitteeID === voteData.CommitteeID && !v.IsPublic), //don't allow publishing Final Votes before all preceding votes for that committee are published,
                    committeeSameDayVoteList: this.props.votes.voteList.filter(v => v.VoteID !== voteData.VoteID && v.CommitteeID === voteData.CommitteeID).map(v => { return { LegislationNumber: v.LegislationNumber, EventCode: v.EventCode } })
                }, () => {
                    this.getVoteActionReferences(true);
                });
            })
    }

    render() {
        const { voteData, isLoaded, isPreviewing, message, isSaving, pendingSave, preventPublish, selectedVoteActionDescription, voteActionList, selectedCalendarCategoryType, calendarCategoryTypeList, editingVoteLegislation } = this.state;

        const customStyles = (multi) => ({
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: "10px 0 0 0",
                minHeight: 0,
                fontSize: '0.8em',
                height: !multi && "32px",
                zIndex: multi && '10'
            }),
            valueContainer: (base) => ({
                ...base,
                height: !multi && "32px"
            }),
            dropdownIndicator: (base) => ({
                ...base,
                paddingTop: "0px",
                paddingBottom: "0px"
            }),
            input: (base) => ({
                ...base,
                margin: "0px",
                position: multi ? "relative" : "absolute"
            }),
            singleValue: (base, state) => {
                return { ...base, };
            },
            menu: (base) => ({
                ...base,
                position: 'absolute',
                zIndex: '10'
            })
        })

        if (message) {
            return (
                <div className="dlas-forms">
                    <span>{message}</span>
                </div>);
        }
        if (!isLoaded) {
            return (
                <div className="user-forms">
                    <div className="center-spinner spinner">Loading results...</div>
                </div>);
        }
        if (isPreviewing) {
            return (
                <div>
                    <div className="flex-row">
                        <h2>Vote Preview</h2>
                        <ReactToPrint
                            trigger={() => <a type="button" className="button print"> Print</a>}
                            content={() => this.componentRef}
                            pageStyle={"break-inside: avoid"}
                        />
                    </div>
                    <div ref={el => (this.componentRef = el)}>
                        <VoteDetails
                            sessionData={this.props.session.selectedSession}
                            billData={voteData.VoteLegislation ? voteData.VoteLegislation.filter(vl => !vl.DeletionDate) : []}
                            voteMembers={voteData.VoteMember || []}
                            voteData={voteData}
                        />
                    </div>
                    <div className="button-bar">
                        <div></div>
                        <div className="align-right">
                            <button onClick={this.togglePreview} type="button" className="button secondary">Edit</button>
                            <button onClick={() => this.handleSave(true)} type="button" disabled={isSaving || preventPublish} className="button">Publish</button>
                            {preventPublish && <p className="input-feedback">You must publish all preceding votes for this commmittee before publishing this final vote</p>}
                        </div>
                    </div>
                </div>
            );
        }

        let showVoteTallyWarningMessage = false;
        let voteTallyInDescription = voteData.Description.match(/\(\d+-Y\s\d+-N(\s\d+-A)?\)/);
        if (voteTallyInDescription) {
            voteTallyInDescription = voteTallyInDescription[0];
            showVoteTallyWarningMessage = voteTallyInDescription !== voteData.VoteTally;
        }

        return (
            <div className="user-forms">
                <div>
                    <Prompt
                        when={this.state.isDirty}
                        message={`You have unsaved changes. Are you sure you would like to leave?`}
                    />
                </div>
                {this.state.showPortal &&
                    <Popout togglePortal={this.togglePortal} windowTitle="Preview">
                        {this.state.gettingPdf &&
                            <div className="center-spinner spinner">You must save before seeing changes in this PDF preview</div>
                        }
                        <iframe style={{ display: this.state.gettingPdf ? 'none' : 'block', width: '100%', height: '100%' }} ref={this.pdfRef}></iframe>
                    </Popout>
                }
                {this.state.showWarning &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content">
                            <p>You are about to save a change to a vote. Are you sure about this?</p>
                            <button className="button secondary float-right" onClick={() => this.toggleWarning(false)}>No</button>
                            <button className="button primary float-right" onClick={() => this.handleSave()} style={{ marginRight: "15px" }}>Yes</button>
                            <br />
                        </div>
                    </div>
                }
                <div className="dlas-forms">
                    <form className="vote-form">
                        <fieldset className="fieldset-collapse fieldset-open">
                            <legend>Vote Information</legend>
                            <div className="inner-grid two">
                                <div className="multi-row three no margin">
                                    <div>
                                        <label className="label" htmlFor="sequence">Vote Number</label>
                                        <input
                                            id="vote-number"
                                            type="text"
                                            placeholder="Vote Number"
                                            value={voteData.VoteNumber || ''}
                                            onChange={(e) => this.handleInputChange('VoteNumber', e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label className="label" htmlFor="time">Time of Vote</label>
                                        <DatePicker
                                            id="time"
                                            selected={moment(voteData.VoteDate)}
                                            onChange={this.handleDateChange}
                                            showTimeSelect
                                            timeFormat="h:mm A"
                                            timeIntervals={5}
                                            timeCaption="Time"
                                            dateFormat="MM/DD/YYYY h:mm A"
                                        />
                                    </div>
                                    <div>
                                        <label className="label" htmlFor="pass-fail">Pass/Fail</label>
                                        <Select
                                            id="pass-fail"
                                            className="grid-selector-dropdown"
                                            styles={customStyles()}
                                            options={PassFailOptions}
                                            value={PassFailOptions.find(o => o.value === voteData.PassFail)}
                                            onChange={(value) => this.handleInputChange("PassFail", value.value)} />
                                    </div>
                                </div>
                                <div className="multi-row two no margin" style={{ gap: '15px', gridTemplateColumns: '80% 20%' }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 100px' }}>
                                        <div>
                                            <div>
                                                <label className="label">Bills</label>
                                                <a className={`icon ${editingVoteLegislation ? 'save' : 'edit'}`} style={{ marginLeft: '7px' }} onClick={this.toggleEditVoteLegislation} />
                                                {editingVoteLegislation && <a className="icon delete" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditVoteLegislation(true)} />}
                                            </div>
                                            {voteData.VoteLegislation &&
                                                (editingVoteLegislation ?
                                                    <Select
                                                        styles={customStyles(true)}
                                                        options={this.state.billList.filter(bl => !voteData.VoteLegislation.filter(vl => !vl.DeletionDate).map(vl => vl.LegislationID).includes(bl.LegislationID))}
                                                        value={voteData.VoteLegislation.filter(vl => !vl.DeletionDate)}
                                                        getOptionLabel={vl => vl.LegislationNumber}
                                                        onChange={this.handleVoteLegislationChange}
                                                        isMulti
                                                    />
                                                    :
                                                    <input
                                                        type="text"
                                                        value={voteData.VoteLegislation.filter(vl => !vl.DeletionDate).map((leg, index) => leg.LegislationNumber).join(', ')}
                                                        title={voteData.VoteLegislation.filter(vl => !vl.DeletionDate).map((leg, index) => leg.LegislationNumber).join(', ')}
                                                        disabled
                                                    />)
                                            }
                                        </div>
                                        <div style={{ position: 'relative', left: "20px" }}>
                                            <div className="flex-row" style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <div style={{ textAlign: 'left', width: '60px' }}>
                                                    <label htmlFor="reprint-button" style={{ paddingLeft: '0px' }} className="checkbox-label label no-background">Is Block</label>
                                                    <div className="toggle-switch" disabled={this.state.settingIsBlock || (voteData.IsBlock && voteData.VoteLegislation && voteData.VoteLegislation.filter(v => !v.DeletionDate).length > 1)} onClick={() => this.handleInputChange("IsBlock", !voteData.IsBlock)}>
                                                        <input id="reprint-button" checked={voteData.IsBlock} type="checkbox" />
                                                        <span className="slider"></span>
                                                    </div>
                                                </div>
                                                {this.state.settingIsBlock && <div className="spinner small-spinner" style={{ height: '0px' }} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="published-status multi-row two">
                                        {voteData.VoteType === "Committee" && voteData.CommitteeID && !voteData.ParentCommitteeID && voteData.VoteDate &&
                                            <div style={{ width: '102%', textAlign: 'left' }}>
                                                <label htmlFor="reprint-button" style={{ paddingLeft: '0px' }} className="checkbox-label label no-background">Final Vote</label>
                                                <div className="toggle-switch" onClick={() => this.handleInputChange("FinalVote", !voteData.FinalVote)}>
                                                    <input id="reprint-button" checked={voteData.FinalVote} type="checkbox" />
                                                    <span className="slider"></span>
                                                </div>
                                            </div>
                                        }
                                        {voteData.IsPublic ? <p style={{ textAlign: 'right', marginTop: '0px' }}>Published</p> : <p style={{ marginTop: '0px' }} className="dirty">Not Published</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="multi-row three-one-and-two no margin">
                                <div>
                                    <label className="label" htmlFor="vote-act">Vote Action</label>
                                    <Select
                                        id="vote-act"
                                        value={selectedVoteActionDescription}
                                        options={voteActionList}
                                        getOptionLabel={v => v.EventCode + " - " + v.Description}
                                        getOptionValue={v => v.EventCode}
                                        styles={customStyles()}
                                        placeholder={this.state.loadingVoteActionList ? 'Loading...' : 'Select...'}
                                        onChange={this.handleVoteActionDescriptionChange}
                                    />
                                </div>
                                <div>
                                    <label className="label" htmlFor="motion-code">Vote Tally</label>
                                    <input
                                        id="vote-tally"
                                        className={showVoteTallyWarningMessage ? 'input-feedback-warning-background' : ''}
                                        type="text"
                                        placeholder="Vote Tally"
                                        value={voteData.VoteTally}
                                        disabled
                                    />
                                </div>
                                {voteData.VoteType === "Floor" &&
                                    <div>
                                        <label className="label" htmlFor="vote-act">Calendar Category</label>
                                        <Select
                                            id="vote-act"
                                            value={selectedCalendarCategoryType}
                                            options={calendarCategoryTypeList}
                                            getOptionLabel={v => v.Description + (v.DisplayType ? ' (' + v.CategoryType + ')' : '')}
                                            getOptionValue={v => v.CalendarCategoryTypeID}
                                            styles={customStyles()}
                                            onChange={this.handleCalendarCategoryTypeChange}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="multi-row three-and-one no margin">
                                <div>
                                    <label className="label" htmlFor="vote-desc">Vote Description (Appears on the Tally Sheet)</label>
                                    <input
                                        style={showVoteTallyWarningMessage ? { marginBottom: '0px' } : {}}
                                        className={showVoteTallyWarningMessage ? 'input-feedback-warning-background' : ''}
                                        id="vote-desc"
                                        type="text"
                                        placeholder="Vote Description"
                                        value={voteData.Description}
                                        onChange={(e) => this.handleInputChange("Description", e.target.value)}
                                    />
                                    {showVoteTallyWarningMessage && <p className="input-feedback-warning" style={{ marginTop: '2px' }}>{voteTallyInDescription} in the Vote Description does not match the Vote Tally {voteData.VoteTally}. This will not prevent you from saving.</p>}
                                </div>
                            </div>
                            <div>
                                <button type="button" className="button" onClick={this.toggleChangeVotes}>{!this.state.changeVotes ? 'Change Votes' : 'Cancel'}</button>
                            </div>
                            <VoteMembers
                                voteMembers={this.state.voteData.VoteMember || []}
                                handleVoteChange={this.handleVoteChange}
                                changeVotes={this.state.changeVotes}
                            />
                            {voteData.VoteType === "Floor" &&
                                <VoteStatements
                                    members={voteData.VoteMember || []}
                                    updateStatement={this.updateStatement}
                                />
                            }
                            <div>
                                <p className="title">{"Vote Comment" + (this.state.voteData.ChamberCode === "S" ? " (ex. Lt. Governor's vote in the event of a tie)" : "")}</p>
                                <input
                                    style={{ width: "100%" }}
                                    type="text"
                                    value={this.state.voteData.VoteComment}
                                    placeholder="Vote Comment"
                                    onChange={(e) => this.handleInputChange("VoteComment", e.target.value)}
                                />
                            </div>
                        </fieldset>
                        <div className="button-bar">
                            <div>
                                <button type="button" className="button" onClick={this.resetForm}>Reset</button>
                            </div>
                            <div className="align-right">
                                {this.state.showEditingVoteLegislationMessage && <span style={{ fontSize: '15px' }}>Please finish updating the vote legislation</span>}
                                <button disabled={isSaving} onMouseEnter={() => { if (editingVoteLegislation) { this.setState({ showEditingVoteLegislationMessage: true }) } }} onClick={() => editingVoteLegislation ? null : this.state.changeVotes && this.state.isDirty ? this.toggleWarning() : this.handleSave(voteData.IsPublic)} type="button" className="button">{voteData.IsPublic ? "Republish" : "Save"}</button>
                                {!voteData.IsPublic && <button disabled={pendingSave || isSaving} onClick={this.togglePreview} type="button" className="button secondary">Review</button>}
                                {!voteData.IsPublic && voteData.VoteType === "Floor" && voteData.ChamberCode === "S" && <button disabled={pendingSave || isSaving} onClick={this.togglePortal} type="button" className="button secondary">Preview Tally</button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>);
    }
}

const VoteMembers = props => {
    const responseStyle = (res) => {
        switch (res) {
            case 'Y':
                return 'yea';
            case 'N':
                return 'nay';
            case 'A':
                return 'abs';
            default:
                return '';
        }
    }

    let arrangedVoteMembers = [[]];
    let voteColumns = 0;

    props.voteMembers.map((member, index) => {
        if (index % 10 > 0 || (voteColumns === 0 && arrangedVoteMembers[0].length === 0)) {
            arrangedVoteMembers[voteColumns].push(member)
        } else {
            voteColumns++;
            arrangedVoteMembers.push([member])
        }
    })

    return (
        <div className="no-gap-grid four vote-members">
            {arrangedVoteMembers.map((memberColumn, i) =>
                <div key={"column-" + i} className="inner-grid ten-vert">
                    {memberColumn.map((memberVote, j) =>
                        <div className="inner-grid two vote-member-container" key={"member-" + j}>
                            <span>{memberVote.PatronDisplayName}</span>
                            <div>
                                <select disabled={!props.changeVotes} onChange={(e) => props.handleVoteChange(parseInt(i.toString() + j.toString()), e.target.value)} className={"vote-response " + responseStyle(memberVote.ResponseCode)} value={memberVote.ResponseCode}>
                                    <option value="Y">Y</option>
                                    <option value="N">N</option>
                                    <option value="A">A</option>
                                    <option value="X">X</option>
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>);
}

class VoteStatements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMember: '',
            editorContent: '',
            newVoteStatement: []
        }
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMemberSelection = this.handleMemberSelection.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.addStatement = this.addStatement.bind(this);
        this.exitEdit = this.exitEdit.bind(this);
    }

    handleMemberSelection(val) {
        this.setState({
            selectedMember: val
        });
    }

    toggleEditing(statementIndex, memberId) {
        let members = [...this.props.members];
        const memberIndex = members.findIndex(member => member.MemberID === memberId);
        members[memberIndex].isEditing = !members[memberIndex].isEditing;
        this.state.newVoteStatement[statementIndex] = members[memberIndex].VoteStatement;
        this.props.updateStatement(members);
    }

    exitEdit() {
        this.setState({
            selectedMember: "",
            editorContent: ""
        })
    }

    handleChange(statementIndex, val) {
        let newVoteStatement = [...this.state.newVoteStatement];
        newVoteStatement[statementIndex] = val
        this.setState({
            newVoteStatement: newVoteStatement
        });
    }

    saveChange(statementIndex, memberId) {
        let members = [...this.props.members];
        const memberIndex = members.findIndex(member => member.MemberID === memberId);
        members[memberIndex].VoteStatement = this.state.newVoteStatement[statementIndex];
        members[memberIndex].isEditing = false;
        this.props.updateStatement(members);
    }

    addStatement() {
        let members = [...this.props.members];
        const memberIndex = members.findIndex(member => member.MemberID === this.state.selectedMember.MemberID);
        members[memberIndex].VoteStatement = this.state.editorContent;
        this.props.updateStatement(members);
        this.setState({
            selectedMember: '',
            editorContent: ''
        });
    }

    removeStatement(memberId) {
        let members = [...this.props.members];
        const memberIndex = members.findIndex(member => member.MemberID === memberId);
        members[memberIndex].VoteStatement = '';
        this.props.updateStatement(members);
    }

    render() {
        const { selectedMember, editorContent, newVoteStatement } = this.state;
        let memberOptions = [];
        this.props.members.forEach(member => {
            if (!member.VoteStatement) {
                member.label = member.PatronDisplayName;
                member.value = member.MemberID;
                memberOptions.push(member);
            }
        });
        const voteStatements = this.props.members.filter(member => member.VoteStatement);
        const customStyles = {
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: 0,
                minHeight: 0,
                fontSize: '0.8em',
            }),
            singleValue: (base, state) => {
                return { ...base, };
            }
        }
        return (
            <div className="vote-statements">
                <p className="title">Add a Vote Statement</p>

                <div className="vote-statement-text no-gap-grid wide-middle">
                    <div className="vote-statement-header">
                        <span>Member</span>
                    </div>
                    <div className="vote-statement-header">
                        <span>Statement</span>
                    </div>
                    <div className="vote-statement-header">
                        <span></span>
                    </div>
                    {voteStatements.map((statement, index) =>
                        <React.Fragment key={index}>
                            <div className="vote-statement-content">
                                <span>{statement.PatronDisplayName}</span>
                            </div>
                            {statement.isEditing ?
                                <React.Fragment>
                                    <div className="vote-statement-content">
                                        <input
                                            type="text"
                                            value={newVoteStatement[index]}
                                            onChange={e => this.handleChange(index, e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <button onClick={() => this.saveChange(index, statement.MemberID)} type="button" className="icon save"></button>
                                        <button onClick={() => this.toggleEditing(index, statement.MemberID)} type="button" className="icon delete"></button>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="vote-statement-content">
                                        <span>{statement.VoteStatement}</span>
                                    </div>
                                    <div>
                                        <button onClick={() => this.toggleEditing(index, statement.MemberID)} type="button" className="icon edit"></button>
                                        <button onClick={() => this.removeStatement(statement.MemberID)} type="button" className="icon delete"></button>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    )}
                    <div className="member-dropdown">
                        <Select
                            className="grid-selector-dropdown"
                            styles={customStyles}
                            options={memberOptions}
                            value={selectedMember}
                            onChange={this.handleMemberSelection}
                        />
                    </div>
                    <div>
                        {selectedMember ?
                            <input
                                type="text"
                                id="new-vote-statement-text"
                                value={editorContent}
                                onChange={(e) => {
                                    this.setState({
                                        editorContent: e.target.value
                                    });
                                }}
                            />
                            :
                            <span></span>
                        }
                    </div>
                    <div>
                        {selectedMember ?
                            <React.Fragment>
                                <button onClick={this.addStatement} type="button" className="icon save"></button>
                                <button onClick={this.exitEdit} type="button" className="icon delete"></button>
                            </React.Fragment>
                            :
                            <span></span>
                        }
                    </div>

                </div>


            </div>);
    }
}

export default connect(
    (state) => {
        const { votes, nav, session, calendar, bills } = state;
        return {
            votes,
            nav,
            session,
            calendar,
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, navActionCreators, sessionActionCreators, calendarActionCreators, billActionCreators), dispatch)
        }
    }
)(VoteForm)