import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import '../../../stylesheets/lis-public-view/public-view.css';

class ResourcesComponent extends React.Component {
    constructor(props) {
        super(props)

    }

    render() {
        const { session, isAuthenticated } = this.props;

        return (
            <div>
                <h3 className="homepage-header">Bills & Resolutions</h3>
                <hr />
                {!this.props.isLoaded ?
                    <div className="center-spinner spinner small-height" />
                    :
                    <div className="homepage-links">
                        <div className="homepage-content-group">
                            <span>{session.selectedSession.SessionYear + " Session: "}</span>
                            <Link to="/bill-search">Browse Bills by Category</Link>
                        </div>
                        <div className="homepage-content-group">
                            <span>Budget Bill: </span>
                            <a href={"http://budget.lis.virginia.gov/"}>State Budget</a>
                        </div>
                        <div className="homepage-content-group">
                            <span>Bill Indexes: </span>
                            <a href={"https://index.lis.virginia.gov?ses=" + session.selectedSession.SessionCode}>Cumulative Index</a>
                        </div>
                        <div style={{ marginTop: "8px", marginBottom: "4px" }}>
                            <Link to={isAuthenticated ? "/bill-search" : "/register-account"}>LOBBYIST in a BOX</Link>
                        </div>
                    </div>
                }
                <h3 className="homepage-header" style={{ marginTop: "14px" }}>Virginia Law</h3>
                <hr />
                <div className="homepage-links">
                    <div className="homepage-content-group">
                        <a href="https://law.lis.virginia.gov/vacode/">Code of Virginia</a>
                    </div>
                    <div className="homepage-content-group">
                        <a href="https://law.lis.virginia.gov/admincode/">Virginia Administrative Code</a>
                    </div>
                    <div className="homepage-content-group">
                        <a href="https://law.lis.virginia.gov/constitution/">Constitution of Virginia</a>
                    </div>
                </div>
                <h3 className="homepage-header" style={{ marginTop: "14px" }}>Legislative Resources</h3>
                <hr />
                <div className="homepage-links">
                    <div className="homepage-content-group">
                        <a href="https://liaison.lis.virginia.gov/">State Agency Legislative Liaisons</a>
                    </div>
                    <div className="homepage-content-group">
                        <a href="https://rga.lis.virginia.gov/">Reports to the General Assembly</a>
                    </div>
                    <div className="homepage-content-group">
                        <a href="http://dls.virginia.gov/pubs_calendar.html">Procedural Calendars</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { nav, session } = state;
        return {
            nav,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, sessionActionCreators), dispatch)
        }
    }
)(ResourcesComponent)