import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { collectionActionCreators } from '../../../stores/lis-collection-store';
import { authActionCreators } from '../../../stores/lis-auth-store';

class CollectionNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            expanded: false
        };

        this.toggleExpandNotes = this.toggleExpandNotes.bind(this);
    }

    componentDidMount() {
        this.props.actions.getCollections("?SessionID=" + this.props.sessionId)
            .then(() => {
                const legId = this.props.billData.LegislationID;
                let notes = [];
                this.props.collection.collections.forEach(collection => {
                    const legislationInCollection = collection.WatchListLegislation.find(leg => leg.LegislationID === legId);
                    if (legislationInCollection && legislationInCollection.Note) {
                        notes.push({
                            ...legislationInCollection,
                            Name: collection.Name,
                            Description: collection.Description
                        });
                    }
                });
                this.setState({
                    notes: notes
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
            });
    }

    toggleExpandNotes() {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    render() {
        if (this.state.notes.length === 0) {
            return (<div></div>);
        }
        return (<div>
            <h4>Your Notes</h4>
            {this.state.notes.map((note, noteIndex) =>
                <div key={noteIndex} className='note-wrapper'>
                    <span className="small-text"><span className="bold">{note.Name}</span>: {note.Note.length < 345 || this.state.expanded ? note.Note : note.Note.substring(0, 345) + "..."}</span>
                </div>
            )}
            {this.state.notes.find(x => x.Note.length > 400) && <button className='button small-button note-button' type="button" onClick={() => this.toggleExpandNotes()}>{(this.state.expanded ? "Collapse" : "Expand") + " Notes"}</button>}
        </div>)
    }
}

export default connect(
    (state) => {
        const { collection, login } = state;
        return {
            collection,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, collectionActionCreators), dispatch)
        }
    }
)(CollectionNotes)