import React from 'react';
import { bindActionCreators } from 'redux';
import { batch, connect } from 'react-redux';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { cancelRequest } from '../../../services/request.service';
import Popout from '../../../lis-shared/lis-layout/components/lis-popout-component';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone'
import renderHTML from 'react-render-html';
import PrintRoomGrid from './lis-print-room-grid'
import { memberActionCreators } from '../../../stores/lis-members-store';
import { printActionCreators } from '../../../stores/lis-print-store';

const versionOptions = [
    { label: "Introduced", value: 1 },
    { label: "Substitute", value: 5 },
    { label: "Engrossed", value: 2 },
    { label: "Reengrossed", value: 6 },
    { label: "Enrolled", value: 3 },
    { label: "Reenrolled", value: 7 },
    { label: "Chaptered", value: 4 }
]

class PrintManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionOptions: [],
            billRoomView: false,
            selectedSession: '',
            chamberOptions: [
                { label: 'Both', value: 'Both' },
                { label: 'House', value: 'H' },
                { label: 'Senate', value: 'S' }
            ],
            selectedChamber: { label: 'Both', value: 'Both' },
            introStartDate: '',
            introEndDate: '',
            search: '',
            isLoaded: false,
            isSearching: false,
            textResults: [],
            showPortal: false,
            selectedBill: '',
            isSaving: false,
            versionOptions: versionOptions,
            selectedVersion: { label: 'All', value: 'All' },
            lastCheckedBills: [],
            anyChecked: false,
            urlBatchId: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.toggleText = this.toggleText.bind(this);
        this.togglePortal = this.togglePortal.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handleReleaseToPrint = this.handleReleaseToPrint.bind(this);
        this.handlePrintComplete = this.handlePrintComplete.bind(this);
        this.handlePrintAllEnrolled = this.handlePrintAllEnrolled.bind(this);
        this.toggleBillRoomView = this.toggleBillRoomView.bind(this);
        this.toggleCommentEdit = this.toggleCommentEdit.bind(this);
        this.markPdf = this.markPdf.bind(this);
    }

    handleChange(key, val) {
        this.setState({
            [key]: val
        }, () => {
            if (key === 'selectedSession' || key === 'selectedVersion') {
                this.handleSearch();
            } else {
                this.handleFilter();
            }
        });
    }

    handleSearch() {
        this.setState({
            isSearching: true
        });
        // Get the list of members for this session so the patron for the legislation can be shown in the grid
        this.props.actions.getMemberList('sessionID=' + this.state.selectedSession.SessionID)

        let params = '?isReleased=' + this.state.billRoomView + '&sessionID=' + this.state.selectedSession.SessionID;
        if (this.state.selectedVersion.value !== 'All') {
            params += '&legislationVersionID=' + this.state.selectedVersion.value;
        }
        if (!this.state.showComplete) {
            params += '&IsPrinted=false'
        }
        this.props.actions.getPrintDocuments(params)
            .then(() => {
                if (this.props.print.printDocuments.length === 0) {
                    throw 'No Results Found';
                }
                let promises = [];
                this.props.print.printDocuments.forEach(batch => {
                    batch.BatchItems.forEach(text => {
                        //Set URL for non-public enrolled PDFs
                        if (!text.SasURL && text.LegislationVersionID === versionOptions.find(v => v.label === "Enrolled").value && !text.IsPublic) {
                            promises.push(new Promise((res, rej) => {
                                this.props.actions.getSasURL('?billNumber=' + text.LegislationNumber).then(() => {
                                    text.SasURL = this.props.bills.sasURLLink;
                                    res();
                                }).catch(() => {
                                    console.log("Failed to retrieve SasURL for bill " + text.LegislationNumber);
                                });
                            }));
                        }

                        text.checked = false;
                    })
                })
                Promise.all(promises).then(() => {
                    // Filter out any entries if any of the filter options are set
                    this.handleFilter()
                    this.setState({
                        isSearching: false,
                        message: ''
                    });
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isSearching: false,
                    message: err.toString()
                });
            });
    }

    handleFilter() {
        // This is done so the redux state is not mutated by the filters
        let printDocuments = JSON.parse(JSON.stringify(this.props.print.printDocuments));
        const { search, introStartDate, introEndDate, selectedChamber, showComplete, showReprints } = this.state;

        let batchNumber = 1
        let oldBatchDate = null;
        printDocuments.forEach(batch => {
            // This gets the batch number. It is used for the bill room page
            if (moment(batch.BatchDate).format('L') !== oldBatchDate) {
                batchNumber = 1;
                oldBatchDate = moment(batch.BatchDate).format('L');
            } else {
                batchNumber++;
            }
            batch.batchNumber = batchNumber;

            if (showReprints) {
                batch.BatchItems = batch.BatchItems.filter(text => text.IsReprint);
            }
            // Filter out based on the chambercode. The chambercode is not in the response so assume it using the first letter of the legislation number
            if (selectedChamber.value !== 'Both') {
                batch.BatchItems = batch.BatchItems.filter(text => {
                    return text.LegislationNumber[0].toUpperCase() === selectedChamber.value;
                });
            }
            // Check if the search field is blank before filtering
            const formattedSearch = search.toLowerCase().replace(/ /g, '');
            if (formattedSearch) {
                batch.BatchItems = batch.BatchItems.filter(text => {
                    if (text.LegislationNumber && text.LegislationNumber.toLowerCase().includes(formattedSearch)) {
                        return true;
                    }
                    if (text.DocumentCode && text.DocumentCode.toLowerCase().includes(formattedSearch)) {
                        return true;
                    }
                    if (text.LDNumber && text.LDNumber.toLowerCase().includes(formattedSearch)) {
                        return true;
                    }
                    if (text.LegislationDescription && text.LegislationDescription.replace(/ /g, '').toLowerCase().includes(formattedSearch)) {
                        return true;
                    }
                    const patron = this.props.members.memberList.find(member => member.MemberID === text.MemberID);
                    if (patron) {
                        if (patron.PatronDisplayName.toLowerCase().includes(formattedSearch)) {
                            return true;
                        }
                    }
                });
            }
            // Filter entries based on the draft date if the start date or end date are set
            batch.BatchItems = batch.BatchItems.filter(text => {
                let returnItem = true;
                if (introStartDate) {
                    let formattedStartDate = introStartDate.clone().hour(0);
                    returnItem = moment(text.VersionDate).isSameOrAfter(formattedStartDate);
                }
                if (introEndDate) {
                    let formattedEndDate = introEndDate.clone().endOf('day');
                    returnItem = moment(text.VersionDate).isSameOrBefore(formattedEndDate);
                }
                if (introStartDate && introEndDate) {
                    //Clone them to not mutate the state
                    let formattedStartDate = introStartDate.clone().hour(0);
                    let formattedEndDate = introEndDate.clone().endOf('day');
                    // Add one day to the dates so that results at the outer limits are included as well
                    returnItem = moment(text.VersionDate).isBetween(formattedStartDate, formattedEndDate);
                }
                return returnItem;
            });

            //This gets the page count
            //This is done here because the bill room's page needs the page count and this is the only loop that runs regardless.
            let totalPageCount = 0;
            batch.BatchItems.forEach(text => {
                if (text.PDFFile && text.PDFFile.length > 0) {
                    if (!isNaN(text.PDFFile[0].PageCount)) {
                        totalPageCount += text.PDFFile[0].PageCount
                    }
                }

                text.checked = false;
            });

            if (this.state.textResults.length) {
                const batchIndex = this.state.textResults.findIndex(c => c.BatchDate === batch.BatchDate);
                if (batchIndex > -1) {
                    this.state.textResults[batchIndex].BatchItems
                        .forEach(x => {
                            //If the current dataset has a checked bill that isn't in the new filtered set then add that bill back into the result set
                            const billInNewFilteredDataset = batch.BatchItems.find(y => y.LegislationTextID === x.LegislationTextID);
                            if (x.checked && !billInNewFilteredDataset) {
                                batch.BatchItems.push(x)
                            } else if (billInNewFilteredDataset) {
                                billInNewFilteredDataset.checked = this.state.textResults[batchIndex].BatchItems.find(y => y.LegislationTextID === x.LegislationTextID).checked;
                            }
                        });
                }
            }

            batch.totalPageCount = totalPageCount;
        });

        this.setState({
            textResults: printDocuments
        });
    }

    toggleSelectAll(batchIndex) {
        let textResults = [...this.state.textResults];
        const opposite = !textResults[batchIndex].selectAll;

        textResults[batchIndex].selectAll = opposite;
        textResults[batchIndex].BatchItems.forEach(text => text.checked = opposite);
        this.setState({
            textResults: textResults,
        })
    }

    toggleText(batchIndex, textIndex, e) {
        let textResults = [...this.state.textResults];
        let lastCheckedBills = this.state.lastCheckedBills;
        let lastCheckedBill = lastCheckedBills[batchIndex];
        if (e.nativeEvent.shiftKey && !isNaN(lastCheckedBill) && lastCheckedBill !== -1) {
            textResults[batchIndex].BatchItems.forEach(agenda => agenda.checked = false);
            for (let i = 0; i < Math.abs(textIndex - lastCheckedBill); i++) {
                const index = lastCheckedBill + i * (textIndex - lastCheckedBill) / Math.abs(textIndex - lastCheckedBill);
                textResults[batchIndex].BatchItems[index].checked = true;
            }
        } else {
            lastCheckedBill = textIndex;
        }
        textResults[batchIndex].BatchItems[textIndex].checked = !textResults[batchIndex].BatchItems[textIndex].checked;
        lastCheckedBills[batchIndex] = lastCheckedBill;

        this.setState({
            textResults: textResults,
            lastCheckedBills: lastCheckedBills
        }, () => {
            //If we unchecked one that doesn't meet filter requirements we need to take it out of the current dataset
            if (!textResults[batchIndex].BatchItems[textIndex].checked) {
                this.handleFilter();
            }

            let checked = false;

            this.state.textResults.forEach((batch) => {
                batch.BatchItems.forEach((item) => {
                    if (item.checked) {
                        checked = true;
                    }
                });
            });

            this.setState({
                anyChecked: checked
            })
        })
    }

    togglePortal(showPortal, e, textId) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({
            showPortal: !showPortal,
            selectedBill: { message: 'Loading...' }
        });
        if (textId) {
            this.props.actions.getBillText('?legislationTextID=' + textId)
                .then(() => {
                    if (this.props.bills.billTextError) {
                        throw this.props.bills.billTextError.toString()
                    }
                    // We are getting it by ID, so there should only be one
                    if (this.props.bills.billText.length !== 1) {
                        throw 'Draft text could not be found'
                    }
                    this.setState({
                        selectedBill: this.props.bills.billText[0]
                    });
                }).catch(err => {
                    this.setState({
                        selectedBill: { message: err }
                    })
                })
        }
    }

    handleCommentChange(batchIndex, textIndex, value) {
        let textResults = [...this.state.textResults];
        textResults[batchIndex].BatchItems[textIndex].Comment = value;

        this.setState({
            textResults: textResults
        })
    }

    handleReleaseToPrint(vendorBatch) {
        let isReleased = vendorBatch !== undefined ? true : false;
        this.setState({
            isSaving: true
        }, () => {
            let releaseDate = new Date();
            let batchItemsToSave = [];
            let itemsToRemove = [];
            this.state.textResults.forEach((batch, batchIndex) => {
                batch.BatchItems.forEach((item, itemIndex) => {
                    if (item.checked) {
                        batchItemsToSave.push(item);
                        itemsToRemove.push({ batch: batchIndex, item: itemIndex })
                    }
                });
            });
            if (batchItemsToSave.length === 0) {
                this.setState({
                    isSaving: false
                });
                return;
            }

            //Need to decide here whether or not to call createPrintDocument or savePrintDocument based on whether or not we have a PrintBatchID
            if (this.state.textResults[0].PrintBatchID) {
                this.props.actions.savePrintDocument(
                    {
                        BatchDate: releaseDate,
                        VendorBatch: vendorBatch,
                        SessionID: this.state.selectedSession.SessionID,
                        BatchItems: batchItemsToSave,
                        IsReleased: isReleased,
                        PrintBatchID: this.state.textResults[0].PrintBatchID,
                        ModificationDate: this.state.textResults[0].ModificationDate
                    })
                    .then(() => {
                        this.props.actions.makeToast([{ message: (batchItemsToSave.length + " Item(s)") + (isReleased ? " Released to " + (vendorBatch ? "Vendor" : "Print") : " Saved"), type: "success", long: true }]);
                        this.handleSearch();
                        this.setState({
                            isSaving: false
                        });
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                        console.log(this.props.print.printSaveError);
                        this.setState({
                            isSaving: false
                        });
                    });
            } else {
                this.props.actions.createPrintDocument(
                    {
                        BatchDate: releaseDate,
                        VendorBatch: vendorBatch,
                        SessionID: this.state.selectedSession.SessionID,
                        BatchItems: batchItemsToSave,
                        isReleased: isReleased,
                    })
                    .then(() => {
                        this.props.actions.makeToast([{ message: (batchItemsToSave.length + " Item(s)") + (isReleased ? " Released to " + (vendorBatch ? "Vendor" : "Print") : " Saved"), type: "success", long: true }]);
                        this.handleSearch();
                        this.setState({
                            isSaving: false
                        });
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                        console.log(this.props.print.printCreateError);
                        this.setState({
                            isSaving: false
                        });
                    });
            }
        });
    }

    handlePrintComplete() {
        const completeDate = new Date();
        let completedItems = [];
        this.state.textResults.forEach(batch => {
            batch.BatchItems.forEach(item => {
                if (item.checked) {
                    completedItems.push({ BatchItemID: item.BatchItemID })
                }
            });
        });
        if (completedItems.length !== 0) {
            this.setState({
                isSaving: true
            }, () => {
                this.props.actions.completeBatchItem({
                    PrintDate: completeDate,
                    BatchItemIDs: completedItems,
                })
                    .then(() => {
                        this.props.actions.makeToast([{ message: "Save Success", type: "success" }]);
                        this.handleSearch();
                        this.setState({
                            isSaving: false
                        });
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                        console.log(this.props.print.batchItemCompleteError);
                        this.setState({
                            isSaving: false
                        });
                    });
            });
        }
    }

    handlePrintAllEnrolled() {
        this.setState({
            isSaving: true
        }, () => {
            this.props.actions.printAllEnrolled("?SessionID=" + this.state.selectedSession.SessionID)
                .then(() => {
                    this.props.actions.makeToast([{ message: "Request Successful", type: "success" }]);
                    this.setState({
                        isSaving: false
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Request Failed", type: "failure" }]);
                    console.log(this.props.print.batchItemCompleteError);
                    this.setState({
                        isSaving: false
                    });
                });
        });
    }

    toggleBillRoomView() {
        this.setState(state => ({
            billRoomView: !state.billRoomView
        }), () => this.handleSearch());
    }

    toggleCommentEdit(e, batchIndex, textIndex) {
        e.preventDefault();
        let textResults = [...this.state.textResults];
        textResults[batchIndex].BatchItems[textIndex].Editing = !textResults[batchIndex].BatchItems[textIndex].Editing;

        this.setState({
            textResults: textResults
        })
    }

    markPdf(batchIndex, textIndex) {
        let textResults = [...this.state.textResults];
        textResults[batchIndex].BatchItems[textIndex].pdfMarked = true;

        this.setState({
            textResults: textResults
        })
    }

    componentDidMount() {
        this.props.actions.getSessionList()
            .then(() => {
                const sessionList = [...this.props.session.sessionList];
                let selectedSession = '';
                sessionList.forEach(session => {
                    session.label = session.SessionYear + " " + session.DisplayName
                    session.value = session.SessionID;
                    if (session.IsDefault) {
                        selectedSession = session;
                    }
                });
                sessionList.reverse();
                this.setState({
                    sessionOptions: sessionList,
                    selectedSession: selectedSession,
                    isLoaded: true
                }, () => {
                    if (this.props.location.search.substr(9, this.props.location.search.length)) {
                        this.toggleBillRoomView();
                        this.setState({
                            urlBatchId: this.props.location.search.substr(9, this.props.location.search.length)
                        })
                    } else if (selectedSession) {
                        this.handleSearch()
                    }
                });
            })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { versionOptions, selectedVersion, sessionOptions,
            selectedSession, chamberOptions, selectedChamber,
            introStartDate, introEndDate, search,
            textResults, showReprints, showComplete } = this.state;
        const customStyles = {
            container: base => ({
                ...base,
                lineHeight: 'normal',
            }),
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: 0,
                minHeight: 0,
                fontSize: '0.8em',
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: '0px 8px'
            })
        }
        if (!this.state.isLoaded) {
            return (<div className="center-spinner spinner">Loading...</div>)
        }
        return (<div>
            <div className="header-half">
                <h1>{this.state.billRoomView ? "Bill Room Management" : "Print Management"}</h1>
                <p><button className="button" onClick={this.toggleBillRoomView} type="button">{this.state.billRoomView ? "Print Management" : "Bill Room Management"}</button></p>
            </div>
            {this.state.showPortal &&
                <Popout togglePortal={this.togglePortal} windowTitle={this.state.selectedBill.LegislationNumber || ''}>
                    <LegislationText
                        text={this.state.selectedBill}
                    />
                </Popout>
            }
            <div className="user-forms dlas-forms">
                {this.state.billRoomView &&
                    <React.Fragment>
                        <div className="button-bar floating-button-bar" style={{ paddingBottom: "15px", paddingRight: "15px" }}>
                            <div />
                            <div>
                                <button
                                    id="bill-room-submit"
                                    onClick={this.handlePrintComplete}
                                    type="button"
                                    disabled={this.state.isSaving || !this.state.anyChecked}
                                    className="button">
                                    {this.state.isSaving ? "Saving..." : "Complete"}
                                </button>
                                <button
                                    id="bill-room-submit"
                                    onClick={this.handlePrintAllEnrolled}
                                    type="button"
                                    disabled={this.state.isSaving}
                                    className="button">
                                    {this.state.isSaving ? "Saving..." : "Print All Enrolled Versions"}
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <fieldset className="fieldset-collapse">
                    <div>
                        <div className="inner-grid five align-end">
                            <div>
                                <label htmlFor="version-dropdown">Version Type</label>
                                <Select
                                    options={versionOptions}
                                    value={selectedVersion}
                                    id="version-dropdown"
                                    styles={customStyles}
                                    onChange={(val) => this.handleChange("selectedVersion", val)}
                                />
                            </div>
                            <div className="advanced-search dlas-forms">
                                <div className="checkbox-container">
                                    <div className="checkbox flex-row flex-vertical-center flex-start" style={{ width: '102%' }}>
                                        <div className="toggle-switch" onClick={() => this.handleChange("showReprints", !showReprints)}>
                                            <input id="reprint-button" checked={showReprints} type="checkbox" />
                                            <span className="slider"></span>
                                        </div>
                                        <label htmlFor="reprint-button" className="checkbox-label no-background">Show Only Reprinted Items</label>
                                    </div>
                                </div>
                            </div>
                            {this.state.billRoomView &&
                                <div className="advanced-search dlas-forms">
                                    <div className="checkbox-container">
                                        <div className="checkbox flex-row flex-vertical-center flex-start">
                                            <div className="toggle-switch" onClick={() => this.handleChange("showComplete", !showComplete)}>
                                                <input id="complete-button" checked={showComplete} type="checkbox" />
                                                <span className="slider"></span>
                                            </div>
                                            <label htmlFor="complete-button" className="checkbox-label no-background">Include Completed Items</label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="inner-grid six">
                            <div>
                                <label htmlFor="session-dropdown">Session</label>
                                <Select
                                    options={sessionOptions}
                                    value={selectedSession}
                                    id="session-dropdown"
                                    styles={customStyles}
                                    onChange={(val) => this.handleChange("selectedSession", val)}
                                />
                            </div>
                            <div>
                                <label htmlFor="chamber-dropdown">Chamber</label>
                                <Select
                                    options={chamberOptions}
                                    value={selectedChamber}
                                    id="chamber-dropdown"
                                    styles={customStyles}
                                    onChange={(val) => this.handleChange("selectedChamber", val)}
                                />
                            </div>
                            <div>
                                <label htmlFor="intro-start-date">Date</label>
                                <DatePicker
                                    selected={introStartDate || null}
                                    isClearable
                                    id="intro-start-date"
                                    placeholderText="Start Date"
                                    onChange={(val) => this.handleChange("introStartDate", val)}
                                />
                            </div>
                            <div>
                                <br />
                                <DatePicker
                                    selected={introEndDate || null}
                                    isClearable
                                    id="intro-end-date"
                                    placeholderText="End Date"
                                    onChange={(val) => this.handleChange("introEndDate", val)}
                                />

                            </div>
                            <div>
                                <label htmlFor="search-filter">Search filter</label>
                                <input
                                    type="text"
                                    value={search}
                                    id="search-filter"
                                    onChange={(e) => this.handleChange("search", e.target.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="inner-grid six">
                            {!this.state.billRoomView &&
                                <React.Fragment>
                                    <button
                                        id="print-management-submit"
                                        onClick={() => this.handleReleaseToPrint(false)}
                                        type="button"
                                        disabled={this.state.isSaving || !this.state.anyChecked}
                                        className="button">
                                        {this.state.isSaving ? "Saving..." : "Release To Print"}
                                    </button>
                                    <button
                                        id="print-management-submit-vendor"
                                        onClick={() => this.handleReleaseToPrint(true)}
                                        type="button"
                                        disabled={this.state.isSaving || !this.state.anyChecked}
                                        className="button">
                                        {this.state.isSaving ? "Saving..." : "Release To Vendor"}
                                    </button>
                                    <button
                                        id="print-management-save-batch"
                                        onClick={() => this.handleReleaseToPrint(undefined)}
                                        type="button"
                                        disabled={this.state.isSaving || !this.state.anyChecked}
                                        className="button">
                                        {this.state.isSaving ? "Saving..." : "Save Batch Without Releasing"}
                                    </button>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </fieldset>
                <div>
                    {this.state.isSearching ?
                        <div className="center-spinner spinner">Searching...</div>
                        :
                        <React.Fragment>
                            {this.state.message ?
                                <div className="center">{this.state.message}</div>
                                :
                                <React.Fragment>
                                    {this.state.billRoomView ?
                                        <PrintRoomGrid
                                            textResults={textResults}
                                            toggleSelectAll={this.toggleSelectAll}
                                            toggleText={this.toggleText}
                                            showBillText={this.showBillText}
                                            togglePortal={this.togglePortal}
                                            showPortal={this.state.showPortal}
                                            members={this.props.members.memberList}
                                            selectedSession={this.state.selectedSession}
                                            urlBatchId={this.state.urlBatchId}
                                            showComplete={this.state.showComplete}
                                            introStartDate={this.state.introStartDate}
                                            introEndDate={this.state.introEndDate}
                                            markPdf={this.markPdf}
                                        />
                                        :
                                        <BillGrid
                                            textResults={textResults}
                                            toggleSelectAll={this.toggleSelectAll}
                                            toggleText={this.toggleText}
                                            showBillText={this.showBillText}
                                            togglePortal={this.togglePortal}
                                            showPortal={this.state.showPortal}
                                            members={this.props.members.memberList}
                                            selectedSession={this.state.selectedSession}
                                            handleCommentChange={this.handleCommentChange}
                                            toggleCommentEdit={this.toggleCommentEdit}
                                        />
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>)
    }
}

const BillGrid = props => {
    const findMember = memberId => {
        const patron = props.members.find(member => member.MemberID === memberId);
        if (patron) {
            return patron.PatronDisplayName;
        } else {
            return '';
        }
    }
    return (<div>
        {props.textResults.map((batch, batchIndex) =>
            <form key={batchIndex}>
                <div className="bill-grid print-bill-grid">
                    <div className="bill-grid-header">
                        <input
                            type="checkbox"
                            checked={batch.selectAll || ''}
                            onChange={() => props.toggleSelectAll(batchIndex)} />
                    </div>
                    <div className="bill-grid-header">
                        <span>Bill #</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>Pages</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>Document</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>Date</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>Patron</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>Caption</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>LD#</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>Comment</span>
                    </div>
                    <div className="bill-grid-header">
                        <span>View</span>
                    </div>
                </div>
                <div>
                    {batch.BatchItems
                        .sort((a, b) => !a.checked && b.checked ? 1 : a.checked && !b.checked ? -1 : moment(a.VersionDate).isAfter(b.VersionDate, "days") ? 1 : moment(b.VersionDate).isAfter(a.VersionDate, "days") ? -1 : 0)
                        .map((text, textIndex) =>
                            <div key={textIndex} className="bill-grid print-bill-grid">
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={text.checked || ''}
                                        onChange={(e) => props.toggleText(batchIndex, textIndex, e)} />
                                </div>
                                <div>
                                    <span><a
                                        target="_blank"
                                        href={`/bill-details/${props.selectedSession.SessionCode}/${text.LegislationNumber}`}>
                                        {text.LegislationNumber}
                                    </a></span>
                                </div>
                                <div>
                                    <span>{text.PDFFile[0] && text.PDFFile[0].PageCount}</span>
                                </div>
                                <div>
                                    {text.IsReprint && <div className="small-text message-error">REPRINT</div>}
                                    <span>{text.DocumentCode}</span>
                                </div>
                                <div>
                                    <span>{moment(text.VersionDate).format('L')}</span>
                                </div>
                                <div>
                                    <span>{findMember(text.MemberID)}</span>
                                </div>
                                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span
                                        title={text.LegislationDescription || text.Description}
                                        style={{ whiteSpace: 'nowrap' }}>
                                        {text.LegislationDescription || text.Description}
                                    </span>
                                </div>
                                <div>
                                    <span>{text.LDNumber}</span>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 25px" }}>
                                    <input
                                        key={text.LegislationTextID}
                                        onChange={(e) => props.handleCommentChange(batchIndex, textIndex, e.target.value)}
                                        value={text.Comment}
                                        disabled={!props.textResults[batchIndex].BatchItems[textIndex].Editing}
                                    />
                                    {props.textResults[batchIndex].BatchItems[textIndex].Editing
                                        ? <button className="icon save" onClick={(e) => props.toggleCommentEdit(e, batchIndex, textIndex)}></button>
                                        : <button type="button" className="icon edit" onClick={(e) => props.toggleCommentEdit(e, batchIndex, textIndex)}></button>
                                    }
                                </div>
                                <div>
                                    <div className="inner-grid three">
                                        {text.SasURL || (text.PDFFile && text.PDFFile.length > 0) ?
                                            <a target="_blank" href={text.SasURL || text.PDFFile[0].FileURL}><span className="icon pdf"></span></a>
                                            :
                                            <br />
                                        }
                                        <div>
                                            <span
                                                onClick={(e) => props.togglePortal(props.showPortal, e, text.LegislationTextID)}
                                                className="icon paper"></span>
                                        </div>
                                        <div>
                                            {text.IsPublic &&
                                                <i className="icon book" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </form>
        )}
    </div>);
}

const LegislationText = props => {
    if (props.text.message) {
        return (<p>{props.text.message}</p>)
    }
    return (
        <div style={{ margin: '10px' }}>
            {renderHTML(props.text.DraftText)}
        </div>
    );
}

export default connect(
    (state) => {
        const { print, session, nav, bills, members } = state;
        return {
            nav,
            print,
            session,
            bills,
            members
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, printActionCreators, sessionActionCreators, billActionCreators, navActionCreators, memberActionCreators), dispatch)
        }
    }
)(PrintManagement)