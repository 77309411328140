import React from "react";
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import '../../../stylesheets/shared/react-table.css';
import moment from 'moment-timezone';


class SubcommitteeManagementGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            selected: {},
            selectedError: null,
            currentDate: new Date(),
            subcommitteeData: [],
            showCreateSuccess: false,
            subcommitteeNameInput: '',
            subcommitteeNumberInput: '',
            calculatedFullCommitteeNumber: ''

        };
        this.subcommitteeNameChange = this.subcommitteeNameChange.bind(this);
        this.subcommitteeNumberChange = this.subcommitteeNumberChange.bind(this);
        this.calculateFullCommitteeNumber = this.calculateFullCommitteeNumber.bind(this);
        this.sendSubcommitteeCreateDetails = this.sendSubcommitteeCreateDetails.bind(this);

    };

    subcommitteeNameChange(e) {
        const { value } = e.target;
        const subcommitteeName = value;
        this.setState({ subcommitteeNameInput: subcommitteeName });
    }

    subcommitteeNumberChange(e) {
        let { value } = e.target;
        if (value.length < 4) {
            value = value ? parseInt(value) : '';
            const subcommitteeNumber = value;
            const calculatedFullCommitteeNumber = this.calculateFullCommitteeNumber(subcommitteeNumber);
            this.setState({ subcommitteeNumberInput: subcommitteeNumber, calculatedFullCommitteeNumber });
        }
    }

    calculateFullCommitteeNumber(number) {
        if (!number) { return '' }
        let calculatedFullCommitteeNumber = this.props.parentCommitteeNumber;
        calculatedFullCommitteeNumber += String(number).padStart(3, '0');
        return calculatedFullCommitteeNumber;
    }

    sendSubcommitteeCreateDetails() {
        if (!Number.isInteger(this.state.subcommitteeNumberInput) || this.state.subcommitteeNumberInput < 1 || this.state.subcommitteeNumberInput > 999) {
            this.setState({ error: 'Subcommittee number must be between 1 and 999' });
            return;
        } else {
            this.setState({ error: null })
        }
        let params = {
            Name: this.state.subcommitteeNameInput,
            CommitteeNumber: this.state.calculatedFullCommitteeNumber,
        }
        this.props.createSubcommittee(params);
    }

    render() {
        return (
            <div>
                <ReactTable
                    resizable={false}
                    data={this.props.subcommitteeData}
                    ref={r => this.reactTable = r}
                    noDataText={this.props.subcommitteeMessage}
                    loading={!this.props.isLoaded}
                    columns={[
                        {
                            Header: "Subcommittee Name",
                            columns: [

                                {
                                    Header: (
                                        <div className="dlas-forms">
                                            {!this.state.showCreateSuccess && !this.state.showCreateFailure &&
                                                <div className="committee-grid-header-inputs subcommittee">
                                                    <div><input id="insert-new-committee" type="text" value={this.state.subcommitteeNameChange} placeholder="Type new subcommittee name and subcommittee number, click create" onChange={this.subcommitteeNameChange} /></div>
                                                    <div>
                                                        <input id="insert-new-committee-number" type="number" min={1} max={999} value={this.state.subcommitteeNumberInput.toString()} placeholder="Subcommittee number" onChange={this.subcommitteeNumberChange} />
                                                        {this.state.calculatedFullCommitteeNumber && <span className="subcommittee-number">({this.state.calculatedFullCommitteeNumber})</span>}
                                                        {this.state.error && <div className="input-feedback">{this.state.error}</div>}
                                                    </div>
                                                </div>
                                            }
                                            {this.props.showCreateSuccess &&
                                                <span className="insert-message">Subcommittee was created successfully!</span>
                                            }
                                            {this.props.showCreateFailure &&
                                                <span className="insert-message fail">Subcommittee creation failed</span>
                                            }
                                        </div>
                                    ),
                                    headerClassName: "header-insert",
                                    accessor: "Name",
                                    sortable: false,
                                    filterAll: true,
                                    Cell: row => {
                                        return (
                                            <span id={'cm-namecell-' + row.index}>{row.original.Name}</span>
                                        )
                                    }
                                }
                            ]
                        },
                        {
                            Header: "Service Start Date",
                            columns: [
                                {
                                    headerClassName: "header-insert",
                                    accessor: "PendingChange",
                                    sortable: false,
                                    filterAll: true,
                                    width: 150,
                                    Cell: row => {
                                        if (row.original.ServiceBeginDate === '0001-01-01T00:00:00') {
                                            return <span className="txt-greyed" id={'cm-pending-' + row.index}>Pending</span>
                                        }
                                        else {
                                            return <span id={'cm-pending-' + row.index}>{row.original.ServiceBeginDate ? moment(row.original.ServiceBeginDate).format('L') : ''}</span>
                                        }

                                    }
                                }
                            ]
                        },
                        {
                            Header: "Actions",
                            headerClassName: "submit-action",
                            columns: [

                                {
                                    Header: (<span><button type="button" id="submit-new-committee" disabled={!this.state.subcommitteeNameInput || !this.state.subcommitteeNumberInput} onClick={this.sendSubcommitteeCreateDetails} className="button">Create</button></span>),
                                    headerClassName: "header-action",
                                    accessor: "action",
                                    sortable: false,
                                    className: "action-column",
                                    width: 50,
                                    Cell: row => {
                                        return (
                                            <Link id={'cm-edit-' + row.index} to={`/committee-management/edit-subcommittee/${this.props.parentCommitteeID}/${row.original.CommitteeID}/`} className="button edit">Edit</Link>
                                        )
                                    }
                                },
                                {
                                    Header: "",
                                    headerClassName: "flag-header",
                                    accessor: "PendingChange",
                                    width: 40,
                                    Cell: row => {
                                        if (row.original.PendingChange) {
                                            return <span id={'cm-flag-' + row.index} className="button flag">Flag</span>
                                        } else {
                                            return <span></span>
                                        }
                                    }
                                }
                            ]
                        },
                    ]}
                    defaultSorted={[
                        {
                            id: "Name",
                            desc: false
                        }
                    ]}
                    showPagination={true}
                    defaultPageSize={10}
                    className="subcommittee-table -striped -highlight"
                />
            </div>
        );
    }
}


export default SubcommitteeManagementGrid;