import React from 'react';
import '../../../stylesheets/lis-public-view/public-view.css';
import moment from 'moment-timezone';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DatePicker from 'react-datepicker';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default'
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/spellchecker/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/table/plugin';
import '../../../stylesheets/tinymce/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import renderHTML from 'react-render-html';
import { collectionActionCreators } from '../../../stores/lis-collection-store';
import { navActionCreators } from '../../../stores/lis-nav-store';

const defaultStyle = "primary";
const freshMessage = (style) => ({ IsActive: true, BulletinMessage: `<div class="alert alert-${style ?? 'primary'} mb-0"><p></p></div>`, StartDate: moment(), EndDate: moment().add(1, 'day') });

class MessageOfTheDay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminView: false,
            editing: false,
            previewing: false,
            linking: false,
            linkText: '',
            linkUrl: '',
            editMessage: freshMessage(defaultStyle),
            style: defaultStyle,
            showAll: false
        }

        this.toggleEdit = this.toggleEdit.bind(this);
        this.selectStyle = this.selectStyle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleLink = this.toggleLink.bind(this);
        this.handleLink = this.handleLink.bind(this);
        this.toggleAdminView = this.toggleAdminView.bind(this);
        this.togglePreview = this.togglePreview.bind(this);
        this.saveMessage = this.saveMessage.bind(this);
        this.toggleShowAll = this.toggleShowAll.bind(this);

        this.newMessageRef = React.createRef();
    }

    toggleShowAll() {
        this.setState({ showAll: !this.state.showAll })
    }

    toggleEdit(bulletinID) {
        const messages = this.state.adminView ? [...this.props.adminMessages] : [...this.props.messages];
        const editMessage = bulletinID ? messages.find(b => b.BulletinID === bulletinID) : freshMessage(defaultStyle);
        let style = defaultStyle;
        try {
            if (bulletinID) {
                const el = document.createElement('div');
                el.innerHTML = editMessage.BulletinMessage;
                const alert = el.querySelector('.alert');
                const classes = alert.classList;
                for (const c of classes) {
                    switch (c) {
                        case 'alert-default':
                            style = "default";
                            break;
                        case 'alert-primary':
                            style = "primary";
                            break;
                        case 'alert-info':
                            style = "info";
                            break;
                        case 'alert-warning':
                            style = "warning";
                            break;
                        case 'alert-danger':
                            style = "danger";
                            break;
                        case 'alert-success':
                            style = "success";
                            break;
                        default:
                            break;
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
        this.setState({
            editMessage,
            style,
            editing: !this.state.editing,
            previewing: false
        })
    }

    toggleAdminView() {
        if (!this.state.adminView) {
            this.setState({ adminView: true }, () => {
                this.props.getAdminMessages();
            });
        } else {
            this.setState({ adminView: false })
        }
    }

    togglePreview() {
        this.setState({ previewing: !this.state.previewing })
    }

    selectStyle(style) {
        this.setState({ style }, () => {
            let editMessage = { ...this.state.editMessage };
            const el = document.createElement('div');
            el.innerHTML = editMessage.BulletinMessage;
            const alert = el.querySelector('.alert');
            alert.classList = `alert alert-${this.state.style} mb-0`;
            editMessage.BulletinMessage = el.innerHTML;
            this.setState({ editMessage })
        })
    }

    handleChange(key, value) {
        if (key === "BulletinMessage" && !value)
            value = freshMessage(this.state.style).BulletinMessage;

        let editMessage = { ...this.state.editMessage };
        editMessage[key] = value;
        this.setState({ editMessage })
    }

    handleBold = (e) => {
        e.preventDefault();
        document.execCommand('bold');
    };

    handleItalic = (e) => {
        e.preventDefault();
        document.execCommand('italic', false, null);
    };

    toggleLink = () => {
        const selected = document.getSelection();
        this.setState({ selected, linking: !this.state.linking, linkUrl: '', linkText: '' })
    }

    setLinkUrl = (e) => {
        this.setState({ linkUrl: e.target.value })
    }

    setLinkText = (e) => {
        this.setState({ linkText: e.target.value })
    }

    handleLink = () => {
        let editMessage = { ...this.state.editMessage };
        const el = document.createElement('div');
        el.innerHTML = editMessage.BulletinMessage;
        let alert = el.querySelector('.alert');
        const anchor = document.createElement('a');
        anchor.href = this.state.linkUrl;
        anchor.textContent = this.state.linkText;
        alert.appendChild(anchor);
        this.handleChange('BulletinMessage', el.innerHTML);
        this.toggleLink();
    };

    saveMessage() {
        this.setState({ saving: true }, () => {
            let message = { ...this.state.editMessage }
            let apiCall = message.BulletinID ? this.props.actions.saveMessage : this.props.actions.createMessage;
            apiCall(message).then(() => {
                this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
                this.setState({ saving: false }, () => {
                    this.toggleEdit();
                    this.props.getAdminMessages();
                    this.props.getMessages();
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                this.setState({ saving: false })
            })
        })
    }

    render() {
        const { editMessage } = this.state;

        const filteredMessages = (this.state.adminView ? [...this.props.adminMessages] : [...this.props.messages]).filter(m => (this.state.adminView || moment.utc(m.StartDate).isBefore(moment())) && moment.utc(m.EndDate).isAfter(moment()));
        const messagesToDisplay = this.state.adminView ? filteredMessages.slice(0, this.state.showAll ? undefined : 5) : filteredMessages;

        return (
            <div className="dlas-forms message-of-the-day">
                {!this.state.previewing && this.props.login.userClaims.roles.find((x) => x === "Admin") &&
                    <div className="message-header">
                        <h3 className="homepage-header">Message of the Day</h3>
                        <a className={`icon ${this.state.editing ? 'delete' : 'add'}`} onClick={() => this.toggleEdit()} />
                        {!this.state.editing && this.props.login.userClaims.roles.find((x) => x === "Admin") && <a className={`icon ${this.state.adminView ? 'button view' : ' search'}`} onClick={this.toggleAdminView} title={this.state.adminView ? "View Public Messages" : "View All Messages As Admin"} />}
                    </div>
                }
                {!this.state.editing ?
                    <div>
                        {
                            (this.props.login.userClaims.roles.find((x) => x === "Admin") && ((this.state.adminView && this.props.gettingAdminMessages) || (!this.state.adminView && this.props.gettingMessages))) ? <div className="spinner center-spinner small-spinner small-height" />
                                :
                                (this.props.login.userClaims.roles.find((x) => x === "Admin") && !messagesToDisplay?.length) ?
                                    <p style={{ margin: '0px' }}>No {this.state.adminView ? "admin" : "public"} messages.</p>
                                    :
                                    <MessageList showAll={this.state.adminView && filteredMessages.length > 5 ? this.state.showAll : true} admin={this.props.login.userClaims.roles.find((x) => x === "Admin")} toggleShowAll={this.state.adminView && filteredMessages.length > 5 ? this.toggleShowAll : null} messages={messagesToDisplay} toggleEdit={this.toggleEdit} />
                        }
                    </div>
                    :
                    <div>
                        {!this.state.previewing ?
                            <>
                                <div className="date-pickers">
                                    <div>
                                        <label>Start Date</label>
                                        <DatePicker
                                            id="motd-start-date"
                                            selected={moment.utc(editMessage.StartDate).local()}
                                            onChange={(val) => this.handleChange('StartDate', val.utc())}
                                            showTimeSelect
                                            dateFormat="MM/DD/yyyy h:mm a"
                                            popperPlacement='bottom'
                                        />
                                    </div>
                                    <div>
                                        <label>End Date</label>
                                        <DatePicker
                                            id="motd-end-date"
                                            selected={moment.utc(editMessage.EndDate).local()}
                                            onChange={(val) => this.handleChange('EndDate', val.utc())}
                                            showTimeSelect
                                            dateFormat="MM/DD/yyyy h:mm a"
                                            popperPlacement='bottom'
                                        />
                                    </div>
                                </div>
                                <div className="color-pickers">
                                    <div className={`color-picker alert-default${this.state.style === 'default' ? ' selected' : ''}`} onClick={() => this.selectStyle('default')} />
                                    <div className={`color-picker alert-danger${this.state.style === 'danger' ? ' selected' : ''}`} onClick={() => this.selectStyle('danger')} />
                                    <div className={`color-picker alert-warning${this.state.style === 'warning' ? ' selected' : ''}`} onClick={() => this.selectStyle('warning')} />
                                    <div className={`color-picker alert-success${this.state.style === 'success' ? ' selected' : ''}`} onClick={() => this.selectStyle('success')} />
                                    <div className={`color-picker alert-primary${this.state.style === 'primary' ? ' selected' : ''}`} onClick={() => this.selectStyle('primary')} />
                                    <div className={`color-picker alert-info${this.state.style === 'info' ? ' selected' : ''}`} onClick={() => this.selectStyle('info')} />
                                </div>
                                <div style={{ margin: 'auto', width: '80%' }}>
                                    <Editor
                                        value={editMessage.BulletinMessage}
                                        init={{
                                            plugins: 'link paste save lists spellchecker preview searchreplace pagebreak code copyheadstyle',
                                            toolbar: 'undo redo | bold italic strikethrough link',
                                            skin: false,
                                            valid_children: '+body[style]',
                                            content_css: false,
                                            content_style: 'table { border-collapse: collapse; border: 1px solid #FFFFFF }' + 'td { padding: 12px 0px }' + 'a {color: #196090 !important; text-decoration: underline !important}',
                                        }}
                                        onEditorChange={content => this.handleChange('BulletinMessage', content)}
                                    />
                                </div>
                            </>
                            :
                            <MessageList admin={false} messages={[editMessage]} />
                        }
                        <div className="inline-list align-right" style={{ marginTop: '5px', maxWidth: this.state.previewing ? '75%' : '90%' }}>
                            {!this.state.previewing &&
                                <div className="checkbox flex-row flex-vertical-center flex-start" style={{ display: 'inline-flex' }}>
                                    <label htmlFor="report-current-status" className="checkbox-label no-background">Is Active</label>
                                    <div className="toggle-switch" onClick={() => this.handleChange("IsActive", !editMessage.IsActive)}>
                                        <input id="report-current-status" checked={editMessage.IsActive} type="checkbox" style={{ width: "auto" }} />
                                        <span className="slider"></span>
                                    </div>
                                </div>
                            }
                            <button type="button" className='button' disabled={this.state.saving} onClick={this.togglePreview}>{this.state.previewing ? "Edit" : "Preview"}</button>
                            <button type="button" className='button' disabled={this.state.saving} onClick={this.saveMessage}>Save</button>
                        </div>
                        {this.state.previewing && <br />}
                        {this.state.editing && <hr />}
                    </div>
                }
            </div>
        );
    }
}

const MessageList = (props) => {
    const { messages, admin, showAll, toggleEdit, toggleShowAll } = props;
    return (
        <div className="message-list">
            {admin && toggleShowAll &&
                <div>
                    <button onClick={toggleShowAll} className="expander button-link" type="button">Show {showAll ? "Less" : "All"}</button>
                </div>
            }
            {messages.map((message, idx) =>
                <div className="message-item" key={idx}>
                    {renderHTML(message.BulletinMessage)}
                    {admin && <a className="icon edit" style={{ marginLeft: '7px' }} onClick={() => toggleEdit(message.BulletinID)} />}
                </div>
            )}
        </div>
    )
}

export default connect(
    (state) => {
        const { login, nav, collection } = state;
        return {
            login,
            nav,
            collection
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, navActionCreators, collectionActionCreators), dispatch)
        }
    }
)(MessageOfTheDay)