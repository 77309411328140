import React from "react";
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import '../../../stylesheets/shared/react-table.css';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { voteActionCreators } from '../../../stores/lis-votes-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { cancelRequest } from '../../../services/request.service';
import { authActionCreators } from "../../../stores/lis-auth-store";

const customStyles = {
    option: (base, state) => ({
        ...base,
        fontSize: '0.8em',
    }),
    control: (base) => ({
        ...base,
        padding: '1px',
        margin: 0,
        minHeight: 0,
        fontSize: '0.8em',
    }),
    singleValue: (base, state) => {
        return { ...base, };
    },
    input: (base) => ({
        ...base,
        padding: '0px'
    }),
    dropdownIndicator: (base) => ({
        ...base,
        height: '29px',
        padding: '6px'
    }),
    clearIndicator: (base) => ({
        ...base,
        height: '29px',
        padding: '6px'
    })
}

class VoteGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectAll: false,
            showDeleteModal: false,
            voteOriginOptions: [],
            chamberOptions: [
                { label: 'House', value: 'H' },
                { label: 'Senate', value: 'S' }
            ],
            saving: false
        };

        this.getVoteTypeReferences = this.getVoteTypeReferences.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.saveVotes = this.saveVotes.bind(this);
        this.toggleDeleteVoteModal = this.toggleDeleteVoteModal.bind(this);
        this.deleteVote = this.deleteVote.bind(this);
    }

    toggleRow(index, voteIndex) {
        let dateList = [...this.props.organizedVotes];
        if (voteIndex !== undefined) {
            dateList[index].Votes[voteIndex].checked = !dateList[index].Votes[voteIndex].checked;
        } else {
            dateList[index].checked = !dateList[index].checked;
            dateList[index].Votes.forEach(vote => vote.checked = dateList[index].checked);
        }
        this.setState({
            organizedVotes: dateList
        });
    }

    toggleSelectAll() {
        const opposite = !this.state.selectAll;
        let dateList = [...this.props.organizedVotes];
        dateList.forEach(date => {
            date.checked = opposite;
            date.Votes.forEach(vote => {
                vote.checked = opposite
            });
        });
        this.setState({
            selectAll: opposite
        });
    }

    saveVotes(publish, voteToDelete) {
        this.setState({ saving: true }, () => {
            let votesToSave = [];

            if (!voteToDelete) {
                const datesToIterate = [...this.props.organizedVotes].filter(date => date.Votes.find(v => v.checked));
                let checkedVotes = 0;
                let invalidFinalVotePublish = false;
                datesToIterate.some(date => {
                    date.Votes.some(vote => {
                        // Select all votes that are checked and are not already published or not-published
                        if (vote.checked) {
                            checkedVotes++; //we use this when alerting the user if no checked votes were published/unpublished (based on the below logic)
                            if (vote.IsPublic !== publish) {
                                if (this.props.voteOrigin.label === "Committee" && publish && vote.FinalVote && date.Votes.find(v => !v.checked && !v.IsPublic && v.CommitteeID === vote.CommitteeID)) {
                                    this.props.actions.makeToast([{ message: `Before publishing a final committee vote, you must publish all preceding votes from this committee${datesToIterate.length > 1 ? ' (' + moment(date.Date).format("L") + ')' : ''}`, type: "failure", long: true }]);
                                    invalidFinalVotePublish = true; //exit loop if we threw an error toast
                                    return true;
                                }
                                const voteObj = { VoteID: vote.VoteID };
                                if (vote.FinalVote) {
                                    voteObj.LegislationNumber = vote.LegislationNumber;
                                    voteObj.FinalVote = vote.FinalVote;
                                    voteObj.ChamberCode = vote.ChamberCode;
                                    voteObj.CommitteeID = vote.CommitteeID;
                                    voteObj.VoteDate = vote.VoteDate;
                                    voteObj.SessionID = vote.SessionID;
                                    voteObj.CommitteeVotedOnLegislation = date.Votes.filter(v => v.LegislationNumber && (v.IsPublic || v.checked) && v.CommitteeID === vote.CommitteeID).map(v => { return { LegislationNumber: v.LegislationNumber, EventCode: v.EventCode } })
                                }
                                votesToSave.push(voteObj);
                            }
                        }
                    });
                    return invalidFinalVotePublish; //exit loop if we threw an error toast
                });

                if (invalidFinalVotePublish) {
                    this.setState({ saving: false })
                    return;
                }

                if (votesToSave.length > 0) {
                    this.props.actions.saveVoteBulk({ IsPublic: publish, VoteIDs: votesToSave })
                        .then(async () => {
                            // Create a file for all the votes, and create the chairman's report summarized on minutes if FinalVote
                            let chairmansReportFailures = [];
                            if (publish) {
                                for (const vote of votesToSave) {
                                    this.props.actions.voteFileGen('?voteID=' + vote.VoteID)
                                        .then(() => {
                                            if (this.props.votes.voteFileError) {
                                                console.log(this.props.votes.voteFileError.toString());
                                            }
                                        });
                                    if (this.props.voteOrigin.label === "Committee" && vote.FinalVote && vote.CommitteeID && vote.VoteDate) {
                                        const success = await this.props.createChairmansReportSummarizedOnMinutes(vote.SessionID, vote.ChamberCode, vote.CommitteeID, moment(vote.VoteDate).utc().hour(0), vote.CommitteeVotedOnLegislation);
                                        if (!success) { chairmansReportFailures.push(vote.LegislationNumber) }
                                    }
                                };
                            }

                            if (chairmansReportFailures.length) {
                                const chairmansReportVotes = votesToSave.filter(v => v.FinalVote && v.CommitteeID && v.VoteDate);
                                this.props.actions.makeToast([{ message: "Vote Publish Successful, Chairman's Report Save Failed" + (chairmansReportVotes.length > 1 ? " For " + chairmansReportVotes.join(', ') : ''), type: "failure", long: true }]);
                            } else {
                                this.props.actions.makeToast([{ message: "Save Successful", type: "success" }]);
                            }
                            this.props.getVotes();
                            this.setState({ saving: false })
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            console.log(err.toString());
                            this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                            this.setState({ saving: false })
                            this.props.getVotes();
                        });
                } else if (checkedVotes === 0) {
                    this.props.actions.makeToast([{ message: "You have not selected any votes", type: "warning" }]);
                    this.setState({ saving: false })
                } else {
                    this.props.actions.makeToast([{ message: (checkedVotes === 1 ? "This vote has" : "The selected votes have") + (publish ? " already been published" : " not yet been published"), type: "warning", long: true }]);
                    this.setState({ saving: false })
                }
            } else {
                this.setState({
                    deletingVote: true
                }, () => {
                    this.props.actions.getVoteDetails("?voteID=" + this.state.voteToDelete.VoteID, true)
                        .then(() => {
                            votesToSave.push(
                                this.props.votes.voteDetailsResponse[0]
                            )
                            votesToSave[0].DeletionDate = moment();

                            this.props.actions.saveVote({ VoteList: votesToSave })
                                .then(() => {
                                    this.setState({
                                        deletingVote: false,
                                        showDeleteModal: false,
                                        voteToDelete: undefined,
                                        saving: false
                                    }, () => {
                                        this.props.actions.makeToast([{ message: "Vote Deleted", type: "success" }]);
                                        this.props.getVotes();
                                    })
                                }).catch(err => {
                                    if (err === 'Aborted') {
                                        return;
                                    }
                                    console.log(err.toString());
                                    this.props.actions.makeToast([{ message: "Vote Delete Failed", type: "failure" }]);
                                    this.setState({ deletingVote: false, saving: false })
                                    this.props.getVotes();
                                });
                        })
                })
            }
        })
    }

    toggleDeleteVoteModal(voteToDelete) {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            voteToDelete: voteToDelete
        })
    }

    deleteVote() {
        this.saveVotes(false, this.state.voteToDelete)
    }

    async getVoteTypeReferences() {
        await this.props.actions.getVoteTypes()
            .then(() => {
                let voteOriginOptions = [];
                this.props.votes.voteTypes.forEach(type => {
                    type.label = type.Name;
                    type.value = type.VoteTypeID;
                    voteOriginOptions.push(type);
                });
                const voteOrigin = voteOriginOptions.find(o => o.Name === "Floor") ? voteOriginOptions.find(o => o.Name === "Floor") : null;
                this.setState({
                    voteOriginOptions: voteOriginOptions,
                    voteOrigin: voteOrigin
                }, () => {
                    if (voteOrigin) {
                        this.props.getVotes();
                    }
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
            });
    }

    async getCommittees() {
        await new Promise(res => {
            this.setState({ fetchingCommittees: true }, async () =>
                await this.props.actions.getCommitteeList('includeSubCommittees=true')
                    .then(() => {
                        this.props.committee.committeeList.forEach(cmte => {
                            cmte.label = cmte.Name;
                            cmte.value = cmte.CommitteeID
                        })

                        this.setState({ fetchingCommittees: false, committeeList: this.props.committee.committeeList })
                        res();
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        console.log(err);
                        this.setState({ fetchingCommittees: false })
                    })
            )
        })
    }

    handleChange(key, value) {
        this.props.handleChange(key, value, () => {
            let organizedVotes = [...this.props.organizedVotes];
            organizedVotes.forEach((date, idx) => {
                if (date.show) this.props.showVotes(idx, false)
            });
            this.props.getVotes();
        })
    }

    sortVotes(votes) {
        let organizedVotes = [...this.props.organizedVotes];
        let voteDay = organizedVotes.findIndex(x => moment(x.Date).isSame(votes.original.Date, "days"));

        if (organizedVotes[voteDay].sortTimeDesc) {
            organizedVotes[voteDay].Votes.sort((a, b) => a.FinalVote === b.FinalVote || this.props.voteOrigin.label === "Floor" ? moment(a.VoteDate).isBefore(b.VoteDate, "seconds") ? 1 : moment(a.VoteDate).isAfter(b.VoteDate, "seconds") ? -1 : 0 : a.FinalVote ? 1 : -1)
        } else {
            organizedVotes[voteDay].Votes.sort((a, b) => a.FinalVote === b.FinalVote || this.props.voteOrigin.label === "Floor" ? moment(a.VoteDate).isAfter(b.VoteDate, "seconds") ? 1 : moment(a.VoteDate).isBefore(b.VoteDate, "seconds") ? -1 : 0 : a.FinalVote ? 1 : -1)
        }

        organizedVotes[voteDay].sortTimeDesc = !organizedVotes[voteDay].sortTimeDesc;

        this.setState({
            organizedVotes
        })
    }

    componentDidMount() {
        //set the default chamber to house or senate depending on the user's role
        let disableChamberSelector = false;
        let selectedChamber = null;

        if (!this.props.login.userClaims.claims.find(x => x.RoleName === "Admin")) {
            //Find if the user is a house vote editor, if so select house, otherwise select senate, and disable the chamber selector
            selectedChamber = this.props.login.userClaims.claims.find(x => x.Resource === "Vote" && x.Scope === "House") ? this.state.chamberOptions.find(chamber => chamber.label === "House") : this.props.login.userClaims.claims.find(x => x.Resource === "Vote" && x.Scope === "Senate") ? this.state.chamberOptions.find(chamber => chamber.label === "Senate") : null;
            disableChamberSelector = true;
            this.handleChange('chamber', selectedChamber);
        }

        this.setState({
            disableChamberSelector: disableChamberSelector
        });

        Promise.all([this.getVoteTypeReferences(), this.getCommittees()]).then(() => {
            this.props.getVotes();
        })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { committeeList, fetchingCommittees, voteOriginOptions, chamberOptions, saving } = this.state;
        const { startDate, endDate, committeeVal, chamber, voteOrigin, isLoaded, organizedVotes } = this.props;

        const VoteActions = (vote) =>
            <div className="vote-information-actions">
                {vote.IsPublic ?
                    <Link to={`/vote-management/edit-vote/${vote.VoteID}`} className="icon edit"></Link>
                    :
                    <React.Fragment>
                        <Link to={`/vote-management/edit-vote/${vote.VoteID}`} className="icon edit"></Link>
                        <span className="icon flag"></span>
                    </React.Fragment>
                }
                <span onClick={() => this.toggleDeleteVoteModal(vote)} className="icon delete"></span>
                {vote.FinalVote && <span className="bold">(Final Vote)</span>}
            </div>

        return (
            <div>
                {this.state.showDeleteModal &&
                    <div className='schedule-modal'>
                        <div className='schedule-modal-content'>
                            <div className='grid-wrapper two'>
                                <div className='inner-grid eight-one-and-one'>
                                    <span>Are you sure you want to delete vote {this.state.voteToDelete.VoteNumber}?</span>
                                    {this.state.deletingVote
                                        ? <div className="small-spinner"></div>
                                        : <button className='button primary' onClick={() => this.deleteVote()}>Yes</button>
                                    }
                                    <button className='button secondary' onClick={() => this.toggleDeleteVoteModal()}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="header-half">
                    <h1>Vote Management</h1>
                </div>

                <div className="toolbar dlas-forms">
                    <div className="flex-row">
                        <div style={{ display: 'grid', gridTemplateColumns: 'min-content min-content 120px 150px 350px', gap: '10px' }}>
                            <div>
                                <label className="label" htmlFor="start-date">Start Date</label>
                                <DatePicker
                                    name="start-date"
                                    selected={startDate}
                                    onChange={value => this.props.handleDateChange('startDate', value, () => {
                                        let organizedVotes = [...this.props.organizedVotes];
                                        organizedVotes.forEach((date, idx) => {
                                            if (date.show) this.props.showVotes(idx, false)
                                        });
                                        this.props.getVotes();
                                    })}
                                />
                            </div>
                            <div>
                                <label className="label" htmlFor="end-date">End Date</label>
                                <DatePicker
                                    name="end-date"
                                    selected={endDate}
                                    onChange={value => this.props.handleDateChange('endDate', value, () => {
                                        let organizedVotes = [...this.props.organizedVotes];
                                        organizedVotes.forEach((date, idx) => {
                                            if (date.show) this.props.showVotes(idx, false)
                                        });
                                        this.props.getVotes();
                                    })}
                                />
                            </div>
                            <div>
                                <label className="label" htmlFor="chamber">Chamber</label>
                                <Select
                                    styles={customStyles}
                                    name="chamber"
                                    options={chamberOptions}
                                    isDisabled={this.state.disableChamberSelector}
                                    onChange={value => this.handleChange('chamber', value)}
                                    value={chamber}
                                    style={{ width: "150px" }}
                                />
                            </div>
                            <div>
                                <label className="label" htmlFor="committee">Vote Origin</label>
                                <Select
                                    styles={customStyles}
                                    name="vote-origin"
                                    options={voteOriginOptions}
                                    onChange={value => this.handleChange('voteOrigin', value)}
                                    value={voteOrigin}
                                    style={{ width: "150px" }}
                                />
                            </div>
                            <div>
                                {voteOrigin && voteOrigin.label !== "Floor" &&
                                    <React.Fragment>
                                        <label className="label" htmlFor="committee">Committee</label>
                                        <Select
                                            styles={customStyles}
                                            name="committee"
                                            options={committeeList && chamber ? committeeList.filter(c => c.ChamberCode === chamber.value && (voteOrigin.label === "Committee" ? !c.ParentCommitteeID : c.ParentCommitteeID)) : []}
                                            onChange={value => this.handleChange('committee', value)}
                                            value={committeeVal}
                                            isDisabled={!chamber}
                                            placeholder={fetchingCommittees ? 'Getting Committees....' : 'Select...'}
                                            style={{ width: "350px" }}
                                            isClearable
                                        />
                                    </React.Fragment>}
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <div className="toolbar minutes-toolbar dlas-forms">
                    <button type="button" disabled={saving || (voteOrigin && voteOrigin.label !== "Floor" && (!committeeVal || !committeeVal.label))} onClick={() => this.saveVotes(true)} className="button">Publish</button>
                    <button type="button" disabled={saving || (voteOrigin && voteOrigin.label !== "Floor" && (!committeeVal || !committeeVal.label))} onClick={() => this.saveVotes(false)} className="button danger">Unpublish</button>
                </div>
                <ReactTable
                    resizable={false}
                    loading={!isLoaded}
                    data={organizedVotes}
                    noDataText="No votes found"
                    ref={r => this.reactTable = r}
                    columns={[
                        {
                            sortable: false,
                            filterable: false,
                            style: { textAlign: 'center' },
                            width: 45,
                            Header: x => {
                                return (
                                    <input
                                        id={'cm-checkbox-selectall'}
                                        type="checkbox"
                                        className="checkbox"
                                        checked={this.state.selectAll}
                                        onChange={this.toggleSelectAll}
                                    />
                                );
                            },
                            Cell: ({ original, index }) => {
                                return (
                                    <input
                                        id={'cm-checkbox-' + index}
                                        type="checkbox"
                                        className="checkbox"
                                        checked={original.checked}
                                        onChange={() => this.toggleRow(index)}
                                    />
                                );
                            }
                        },
                        {
                            Header: "",
                            accessor: "Date",
                            Cell: row => {
                                if (row.original.show) {
                                    return (
                                        <div className="vote-date-container">
                                            <div className="vote-date" onClick={() => this.props.showVotes(row.index)}>
                                                <span>{moment(row.value).format('L')}</span>
                                                <button className="arrow-up">votes</button>
                                            </div>
                                            <div className={`vote-information${voteOrigin.label === "Floor" ? "" : " committee"}`}>
                                                {voteOrigin.label === "Floor" ?
                                                    <React.Fragment>
                                                        <span className="vote-information-header"></span>
                                                        <span className="vote-information-header">Legislation</span>
                                                        <span className="vote-information-header">Vote Number</span>
                                                        <span className="vote-information-header" onClick={() => this.sortVotes(row)} style={{ cursor: "pointer" }}>Vote Time</span>
                                                        <span className="vote-information-header"></span>
                                                        {row.original.Votes.map((vote, voteIndex) =>
                                                            <React.Fragment key={voteIndex}>
                                                                <span><input type="checkbox" checked={vote.checked} onChange={() => this.toggleRow(row.index, voteIndex)} /></span>
                                                                {!["block", "cancelled"].includes(vote.LegislationNumber?.toLowerCase()) ? <Link to={`/bill-details/${vote.SessionCode}/${vote.LegislationNumber}`} target="_blank" rel="noreferrer">{vote.LegislationNumber}</Link> : <span>{vote.LegislationNumber}</span>}
                                                                <span>{vote.VoteNumber}</span>
                                                                <span>{moment(vote.VoteDate).format("LTS")}</span>
                                                                {VoteActions(vote)}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <span className="vote-information-header checkbox-container"></span>
                                                        <span className="vote-information-header">Legislation</span>
                                                        <span className="vote-information-header">Action</span>
                                                        <span className="vote-information-header">Committee</span>
                                                        <span className="vote-information-header">Vote Tally</span>
                                                        <span className="vote-information-header"></span>
                                                        {row.original.Votes.map((vote, voteIndex) =>
                                                            <React.Fragment key={voteIndex}>
                                                                <span title={"Vote Number: " + vote.VoteNumber} className="checkbox-container"><input type="checkbox" checked={vote.checked} onChange={() => this.toggleRow(row.index, voteIndex)} /></span>
                                                                {vote.LegislationNumber?.toLowerCase() !== "block" ? <Link to={`/bill-details/${vote.SessionCode}/${vote.LegislationNumber}`} target="_blank" rel="noreferrer">{vote.LegislationNumber}</Link> : <span>{vote.LegislationNumber}</span>}
                                                                <span title={"Vote Number: " + vote.VoteNumber}>{vote.EventCode}</span>
                                                                <span title={vote.CommitteeName}>{vote.CommitteeName}</span>
                                                                <span title={"Vote Number: " + vote.VoteNumber}>{vote.VoteTally}</span>
                                                                {VoteActions(vote)}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div style={{ cursor: 'pointer' }} onClick={() => this.props.showVotes(row.index)}>
                                            <span>{moment(row.value).format('L')}</span>
                                            <button style={{ float: 'right' }} className="arrow-down">votes</button>
                                        </div>
                                    );
                                }
                            }
                        }
                    ]}
                    defaultPageSize={15}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    className="committee-table -striped -highlight"
                />
            </div>)

    }
}

export default connect(
    (state) => {
        const { votes, nav, committee, login } = state;
        return {
            votes,
            nav,
            committee,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, voteActionCreators, navActionCreators, committeeActionCreators, authActionCreators), dispatch)
        }
    }
)(VoteGrid)