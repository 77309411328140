import React from 'react';
import moment from 'moment-timezone';

class DocketPatronList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div style={{ paddingBottom: "40px" }}>
                    <h3>{this.props.patronListDocketData.CommitteeName}</h3>
                    <p>{moment(this.props.patronListDocketData.CalendarDate).format("MMMM Do YYYY")}</p>
                    {this.props.patronListDocketData.IsPublic ? <p>Published</p> : <p className="dirty">Not Published</p>}
                    <hr />
                    {this.props.patronListDocketData && this.props.patronListDocketData.Patrons.map((patron, i) => {
                        return (
                            <div style={{ marginTop: "20px", marginBottom: "20px" }} key={i}>
                                <span>
                                    {patron.PatronDisplayName} -
                                    {patron.Legislation && patron.Legislation.map((item, j) => {
                                        return (
                                            <span> {j < patron.Legislation.length - 1 ? item.LegislationNumber + ", " : item.LegislationNumber}</span>
                                        )
                                    })}
                                </span>
                            </div>
                        )
                    })}
                </div>
                <div className="button-bar floating-button-bar" style={{ paddingBottom: "15px", paddingRight: "15px" }}>
                    <button onClick={() => this.props.togglePatronList()} className="button" type="button" style={{ width: "175px" }}>Return to Docket Form</button>
                    <div />
                </div>
            </div>
        )
    }
}

export default DocketPatronList;