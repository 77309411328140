import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { memberActionCreators } from '../../stores/lis-members-store';
import { committeeActionCreators } from '../../stores/lis-committee-store';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { patronActionCreators } from '../../stores/lis-patron-store';
import { navActionCreators } from '../../stores/lis-nav-store';

import { cancelRequest } from '../../services/request.service';
import jwtDecode from 'jwt-decode';

function Committees(props) {
    //Person doesn't have to be a member of the committee's subcommmittees so this check must be made
    let subcommittees = [];
    if (props.subcommittees && props.subcommittees.length > 0) {
        subcommittees = props.subcommittees.sort((a, b) => a.Name.localeCompare(b.Name)).map(subcomm => {
            return (
                <li key={subcomm.CommitteeID}>
                    <Link style={{ marginLeft: '1.5em' }} to={`/session-details/${props.session}/committee-information/${subcomm.CommitteeNumber}/committee-details`}>{subcomm.Name}</Link>
                </li>
            )
        })
    }
    return (
        <span>
            <Link to={`/session-details/${props.session}/committee-information/${props.CommitteeNumber}/committee-details`}>{props.Name}</Link>
            {subcommittees && subcommittees}
        </span>
    );
}

function CommitteeAssignments(props) {
    const listCommittees = props.committees.sort((a, b) => a.Name.localeCompare(b.Name)).map((committee, i) => <Committees key={i} session={props.session} {...committee} />);
    if (listCommittees.length === 0) {
        return (<p className="small-text">None</p>)
    }
    return <ul style={{ marginTop: "0px" }}>{listCommittees}</ul>;
}

const PatronRoles = props => {
    let roleLinks = []
    props.roles.forEach((role, roleIndex) => {
        if (role.Name === "Budget Amendment Requests") {
            const sessionCode = props.sessionData.SessionCode[props.sessionData.SessionCode.length - 1]
            //Need to get rid of leading zeros for the link to work on the budget site
            const memberNumber = props.memberData.MemberNumber;
            // The first char is the chamber. Need to get rid of that so parseInt doesn't return NaN. It will be added back later to make the member number without the leading zeros
            const memberChamber = memberNumber[0];
            const memberNumberWithoutChamber = memberNumber.substr(1);
            const formattedMemberNumber = memberChamber + String(parseInt(memberNumberWithoutChamber, 10));

            const link = `https://budget.lis.virginia.gov/mbramendment/${props.sessionData.SessionYear}/${sessionCode}/${formattedMemberNumber}/`
            roleLinks.push(<a key={roleIndex} target="_blank" href={link}>{role.Name}</a>)
        } else {
            //Add the link to the object
            const link = {
                selectedPatronType: [role.PatronTypeID],
                selectedPatron: props.memberData.MemberID,
                selectedSession: props.sessionData.SessionID
            }
            //Convert to base64 so when the link is clicked the adv search page can interpret it
            const b64Link = window.btoa(JSON.stringify(link))
            roleLinks.push(<Link key={roleIndex} to={"/bill-search/?q=" + b64Link}>{role.Name}</Link>)
        }
    });
    if (roleLinks.length === 0) {
        return (<p>None</p>);
    }
    return (<p>{roleLinks}</p>);
}

class MemberDetailsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionData: [],
            memberCommittees: [],
            memberNumber: '',
            memberData: '',
            patronRoles: [],
            memberSessionContact: [],
            memberDistrictContact: [],
            memberConferenceBills: []
        };
        this.getMemberContact = this.getMemberContact.bind(this);
        this.getMemberCommittees = this.getMemberCommittees.bind(this);
        this.getPatronTypes = this.getPatronTypes.bind(this);
        this.getMemberConferenceBills = this.getMemberConferenceBills.bind(this);
        this.setupPage = this.setupPage.bind(this);
    }

    getMemberCommittees(memberID, effectiveDate) {
        var params = 'effectiveDate=' + effectiveDate + '&MemberID=' + memberID

        this.props.actions.getCommitteesByMember(params).then(() => {
            let committees = this.props.committee.committeesByMember;
            for (let i = 0; i < committees.length; i++) {
                let comm = committees[i];
                //Find the subcommittees by checking if they have a parent ID
                if (comm.ParentCommitteeID) {
                    //Find the parent based the parent ID
                    let parent = committees.find(committee => comm.ParentCommitteeID === committee.CommitteeID);
                    //Just in case something odd is happening and the person is only a member of the subcommittee (possible in api 1.0)
                    if (parent) {
                        if (!parent.subcommittees) parent.subcommittees = [];
                        //Add the subcommittee as a child to the parent object
                        parent.subcommittees.push(comm);
                        //Remove the subcommittee from the list
                        committees.splice(i, 1);
                        //index is displaced because an element is deleted. This will put it back on track
                        i--;
                    }
                }
            }
            this.setState({
                memberCommittees: committees,
                memberCommitteesIsLoaded: true
            })
        }).catch(err => {
            if (err === 'Aborted') {
                return
            }
            this.setState({
                memberCommitteesIsLoaded: true
            })
        })
    }


    getMemberContact(memberNumber, sessionData) {
        const params = 'sessionID=' + sessionData.SessionID + '&memberNumber=' + memberNumber;
        this.props.actions.getMemberContact(params).then(() => {
            if (this.props.members.memberContactData[0]) {
                const memberData = this.props.members.memberContactData[0];
                const gaAddress = memberData.ContactInformation.filter(contact => contact.ContactType === 'GA');
                const mailingAddress = memberData.ContactInformation.filter(contact => contact.ContactType === 'Mailing');
                const sessionStartObj = sessionData.SessionEvents && sessionData.SessionEvents.length && sessionData.SessionEvents.find(date => date.DisplayName === "Session Start");
                const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate).format('MM/DD/YYYY') : '';
                this.setState({
                    memberSessionContact: gaAddress,
                    memberDistrictContact: mailingAddress,
                    memberData: memberData,
                    contactIsLoaded: true

                });
                this.getPatronTypes(memberData.MemberID, sessionData.SessionID);
                this.getMemberCommittees(memberData.MemberID, startDate);
                this.getMemberConferenceBills(memberData.MemberID, sessionData.SessionID)
            } else {
                throw 'Member data not found';
            }
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            this.setState({
                message: err,
                contactIsLoaded: true,
                memberConferenceBillsIsLoaded: true,
                patronRolesIsLoaded: true,
                memberCommitteesIsLoaded: true
            });
        });
    }

    getPatronTypes(memberId, sessionId) {
        this.props.actions.getPatronTypes(`?memberID=${memberId}&sessionID=${sessionId}`)
            .then(() => {
                this.setState({
                    patronRoles: this.props.patrons.patronTypes,
                    patronRolesIsLoaded: true
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    patronRolesIsLoaded: true
                });
            });
    }

    getMemberConferenceBills(memberId, sessionId) {
        const params = `?sessionID=${sessionId}&memberID=${memberId}`
        this.props.actions.getMemberConferenceBills(params)
            .then(() => {
                this.setState({
                    memberConferenceBills: this.props.members.memberConferenceBills,
                    memberConferenceBillsIsLoaded: true
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    memberConferenceBillsIsLoaded: true
                });
            })
    }

    // When component has mounted:
    // 1. Get session info, add to state
    // 2. Get member by ID
    // 3. Get committees
    componentDidMount() {
        window.scrollTo(0, 0);
        //Update the session selector component, which will run componentDidUpdate and call setupDoc on its own
        const sessionCode = this.props.match.params.sessioncode
        if (sessionCode) {
            if (this.props.nav.session !== sessionCode) {
                this.props.actions.changeSession(sessionCode)
            } else {
                this.setupPage(sessionCode);
            }
        }
    }

    setupPage(sessionCode) {
        const memberNumber = this.props.match.params.membernumber
        this.props.actions.getSessionByCode(sessionCode).then(() => {
            this.setState({
                sessionData: this.props.session.selectedSession,
                memberNumber: memberNumber
            });
            this.getMemberContact(memberNumber, this.props.session.selectedSession);
            //If a signed in member is viewing their details page then provide a link to the member status report page.
            const jwt = localStorage.getItem("LIS_ID_TOKEN");
            if (jwt) {
                try {
                    const parsedToken = jwtDecode(jwt);
                    const mNumber = parsedToken.MemberNumber;
                    if (mNumber === memberNumber) {
                        this.setState({
                            showMemberStatusPageLink: true
                        })
                    }
                } catch (e) {
                    if (Object.getPrototypeOf(e).toString() === 'InvalidTokenError') {
                        //Token is malformed..nothing to do
                    } else {
                        console.error(e);
                    }
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push('/session-details/' + this.props.nav.session + '/member-information/' + this.props.match.params.membernumber + "/member-details");
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            if (this.props.match.params.sessioncode) {
                this.props.actions.changeSession(this.props.match.params.sessioncode);
            }
        } else if (this.props.match.params.membernumber !== prevProps.match.params.membernumber) {
            this.setupPage(this.props.nav.session);
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        const { sessionData, memberData, memberDistrictContact, memberSessionContact, memberCommittees, memberConferenceBillsIsLoaded, memberConferenceBills } = this.state;
        const sessionContactInfo = sessionData && sessionData.IsActive && memberSessionContact.map((contact, contactIndex) =>
            <React.Fragment key={contactIndex}>
                <p>{contact.Address1}, Room {memberData.RoomNumber}<br />
                    {contact.Address2}<br />
                    {contact.City}, {contact.StateCode} {contact.ZipCode}<br />
                    {contact.ContactType === "GA" ? memberData.GABPhoneNumber : contact.PhoneNumber}
                    {memberData.GABEmailAddress && memberData.GABEmailAddress.length > 0 &&
                        <React.Fragment>
                            <h3 style={{ marginTop: "8px", paddingLeft: "0px" }}>Email Address</h3>
                            <a href={`mailto:${memberData.GABEmailAddress}`}>{memberData.GABEmailAddress}</a>
                        </React.Fragment>
                    }
                </p>
            </React.Fragment>
        );
        //Decision was made to only display the specified contact mailing address here -JGB        
        const districtContactInfo = sessionData && sessionData.IsActive && memberData && memberData.ContactInformation.map((contact, contactIndex) =>
            contact.ContactType === "Mailing" &&
            <React.Fragment key={contactIndex}>
                <p>
                    {contact.Address1}<br />
                    {contact.Address2 && <>{contact.Address2}<br /></>}
                    {contact.City}, {contact.StateCode} {contact.ZipCode}<br />
                    {contact.PhoneNumber}
                </p>
            </React.Fragment>
        );
        return (
            <div className="generic-details-page public-details inner-grid">
                <div>
                    <h2 style={{ marginBottom: '10px' }}>{sessionData.SessionYear} {sessionData.DisplayName}</h2>
                </div>
                {this.state.message &&
                    <p>{this.state.message}</p>
                }
                {(!this.state.message || this.state.message !== "Member data not found") &&
                    <React.Fragment>
                        {!this.state.contactIsLoaded
                            ? <div aria-label="Loading" className="spinner"></div>
                            : <div className="details-header">
                                <div>
                                    <h3>{memberData.MemberDisplayName}<span style={{ marginLeft: "0.5em" }}>({memberData.PartyCode}) - {memberData.Chamber} District {memberData.DistrictID}</span></h3>
                                    <div className="details-section">
                                        {this.state.showMemberStatusPageLink &&
                                            <a href="http://efile.dlas.virginia.gov/frmMemberBD.aspx">Patron status report</a>
                                        }
                                    </div>
                                </div>
                                <div className="list-links" style={{ padding: '5px 10px' }}>
                                    <Link to={`/vote-search/${sessionData.SessionCode}/${memberData.MemberNumber}`}>Votes</Link>
                                </div>
                            </div>
                        }
                        {!this.state.contactIsLoaded
                            ? <div aria-label="Loading" className="spinner"></div>
                            : sessionContactInfo.length > 0 && <div className="details-section">
                                <h4>In Session Address</h4>
                                <React.Fragment>{sessionContactInfo}</React.Fragment>
                            </div>
                        }
                        {!this.state.contactIsLoaded
                            ? <div aria-label="Loading" className="spinner"></div>
                            : districtContactInfo && districtContactInfo.find(c => c !== false) && <div className="details-section">
                                <h4>Mailing Address</h4>
                                <React.Fragment>{districtContactInfo}</React.Fragment>
                            </div>
                        }
                        {!this.state.memberCommitteesIsLoaded
                            ? <div aria-label="Loading" className="spinner"></div>
                            : <div className="details-section">
                                <h4>Committee Assignments</h4>
                                <CommitteeAssignments session={sessionData.SessionCode} committees={memberCommittees} />
                            </div>
                        }
                        {!this.state.patronRolesIsLoaded
                            ? <div aria-label="Loading" className="spinner"></div>
                            : <div className="details-section">
                                <h4>Sponsored Legislation</h4>
                                <PatronRoles
                                    roles={this.state.patronRoles}
                                    memberData={this.state.memberData}
                                    sessionData={sessionData}
                                />
                            </div>
                        }
                        {!memberConferenceBillsIsLoaded
                            ? <div aria-label="Loading" className="spinner"></div>
                            : <div className="details-section" style={{ minHeight: '150px' }}>
                                {memberConferenceBills.length !== 0 && <h4>Conference Assignments</h4>}
                                {memberConferenceBills.map((bill, billIndex) =>
                                    <div key={billIndex}>
                                        {billIndex !== 0 && <hr className="faded-line" />}
                                        <Link to={`/bill-details/${sessionData.SessionCode}/${bill.LegislationNumber}`}>{bill.LegislationNumber} {bill.Description}</Link>
                                    </div>
                                )}
                            </div>
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}

const PublicMemberDetails = connect(
    (state) => {
        const { members, session, committee, patrons, nav } = state;
        return {
            members,
            session,
            committee,
            patrons,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, memberActionCreators, sessionActionCreators, committeeActionCreators, patronActionCreators, navActionCreators), dispatch)
        }
    }
)(MemberDetailsComponent)

export default PublicMemberDetails;
