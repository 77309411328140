import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { developersActionCreators } from "../../../stores/lis-developers-store";
import { navActionCreators } from "../../../stores/lis-nav-store";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class KeyRegistration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            isSubmitting: false,
            emailError: ''
        }

        this.checkEmail = this.checkEmail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    checkEmail() {
        const email = this.state.email.toLowerCase();

        if (!emailRegex.test(email)) {
            this.setState({
                emailError: 'A valid email is required'
            }, () => {
                this.props.actions.makeToast([{ message: this.state.emailError, type: "failure" }])
            });
            return true;
        }
        this.setState({
            emailError: ''
        });
    }

    handleChange(state, value) {
        this.setState({
            [state]: value
        })
    }

    submit() {
        this.setState({
            isSubmitting: true
        }, () => {
            let error = false
            error = this.checkEmail();

            if (!error) {
                let params = "";

                params = params + "?emailAddress=" + encodeURIComponent(this.state.email);

                this.props.actions.submitKeyRegistration(params)
                    .then(() => {
                        //No need to reset isSubmitting because the user will be redirected
                        this.props.actions.makeToast([{ message: "Your registration was successful. You will receive a confirmation email shortly.", type: "success", long: true }])
                        //Give the user time to read the toast, then forward to the homepage
                        setTimeout(() => { window.location.href = "/home"; }, 5000);
                    })
                    .catch(() => {
                        this.props.actions.makeToast([{ message: this.props.developers.keyRegistrationError, type: "failure" }])
                    })
            } else {
                this.setState({
                    isSubmitting: false
                })
            }
        })
    }

    render() {
        const { email, isSubmitting } = this.state;

        return (
            <div>
                <div className="alert alert-warning"><p>API usage is authorized and validated for 2025 session data only. The General Assembly has not authorized the usage of data from sessions prior to 2025 to be extracted with this API toolset. If you require data prior to the 2025 session, please use <a href="https://legacylis.virginia.gov">legacylis.virginia.gov</a> via CSV download. Once the General Assembly has authorized the data for use, you will be notified.</p></div>
                <form className="dlas-forms key-registration-form">
                    <h3>LIS API Key Registration</h3>
                    <p>Enter your email address here and submit the form. You will receive an email containing a link that you can use to complete your API key registration.</p>
                    <div className="inner-grid short-one-three">
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            type="text"
                            value={email}
                            onChange={(e) => this.handleChange("email", e.target.value)}
                        />
                    </div>
                    <div className="inner-grid reverse-short-one-three">
                        <div />
                        <div className="align-right">
                            <button onClick={this.submit} disabled={isSubmitting || !email} type="button" className="button">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { nav, developers } = state;
        return {
            nav,
            developers
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, developersActionCreators, navActionCreators), dispatch)
        }
    }
)(KeyRegistration)