import React from "react";
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import '../../../stylesheets/shared/react-table.css';
import moment from 'moment-timezone';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import DatePicker from "react-datepicker";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { communicationsActionCreators } from '../../../stores/lis-communications-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { cancelRequest } from '../../../services/request.service';

class CommunicationsManagementGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            selectedSession: [],
            selectedChamber: '',
            sessionOptions: [],
            chamberOptions: [
                { label: "House", value: "H" },
                { label: "Senate", value: "S" }
            ],
            commDate: moment(),
            commList: [],
            showCreateSuccess: false,
        };

        this.handleChamberChange = this.handleChamberChange.bind(this);
        this.handleSessionChange = this.handleSessionChange.bind(this);
        this.loadCommsList = this.loadCommsList.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.createComm = this.createComm.bind(this);
        this.deleteCommunication = this.deleteCommunication.bind(this);
    }

    handleChamberChange(value) {
        this.setState({
            selectedChamber: value
        }, () => {
            this.props.actions.toggleChamber(value);
            this.loadCommsList()
        });
    }

    handleSessionChange(value) {
        this.setState({
            selectedSession: value
        }, () => { this.loadCommsList() });
    }

    loadCommsList() {
        if (this.state.selectedChamber && this.state.selectedSession) {
            this.setState({ isLoaded: false });
            const commParams = '?sessionID=' + this.state.selectedSession.SessionID + '&chamberCode=' + this.state.selectedChamber.value + '&isPublic=false&communicationType=Floor';
            this.props.actions.getCommList(commParams, true)
                .then(() => {
                    this.setState({
                        isLoaded: true,
                        commList: this.props.communications.commList
                    });
                });
        }
    }

    handleDateChange(value) {
        this.setState({
            commDate: moment(value)
        });
    }

    createComm() {
        this.setState({ isAdding: true });

        let createObj = {
            CommunicationDate: this.state.commDate,
            IsPublic: false,
            ChamberCode: this.state.selectedChamber.value,
            CommunicationType: 'Floor',
            SessionID: this.state.selectedSession.SessionID,
        };

        this.props.actions.createComm({ CommunicationResponse: [createObj] })
            .then(() => {
                this.setState({ showCreateSuccess: true });
                this.loadCommsList();
                setTimeout(() => {
                    this.setState({
                        showCreateSuccess: false,
                        createErrorMessage: '',
                        isAdding: false
                    })
                }, 3000);
                this.loadCommsList();
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    createErrorMessage: this.props.communications.commCreateError
                });
                setTimeout(() => {
                    this.setState({
                        showCreateSuccess: false,
                        createErrorMessage: '',
                        isAdding: false
                    })
                }, 3000);
                this.loadCommsList();
            })
    }

    deleteCommunication(rowIndex) {
        this.setState({
            communicationBeingDeleted: rowIndex
        });
        // Before being able to delete the communication we need to get the mod dates. So get the communication
        this.props.actions.getComm('?communicationId=' + this.state.commList[rowIndex].CommunicationID, true)
            .then(() => {
                let communicationData = this.props.communications.commGet;
                // Now that we have the communication data add a deletion date then save it
                communicationData.DeletionDate = moment();
                return this.props.actions.saveComm({ CommunicationResponse: [communicationData] })
            }).then(() => {
                this.setState({
                    showDeleteSuccess: true,
                    communicationBeingDeleted: -1
                });
                this.loadCommsList();
                setTimeout(() => {
                    this.setState({
                        showDeleteSuccess: false
                    })
                }, 3000);
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    communicationBeingDeleted: -1,
                    deleteFailureMessage: 'Communication failed to be deleted'
                });
                console.log(err)
                setTimeout(() => {
                    this.setState({
                        deleteFailureMessage: '',
                    });
                }, 3000);
            });
    }

    componentDidMount() {
        //Get the list of active sessions for the session dropdown
        this.props.actions.getSessionShallowList("IsActive=true")
            .then(() => {
                let sessionList = [...this.props.session.sessionShallowList];
                sessionList.forEach((session, index) => {
                    session.label = session.SessionYear + ' ' + session.DisplayName;
                    session.value = session.SessionID;
                    if (session.IsDefault) {
                        this.handleSessionChange(session);
                    }
                });
                //set the default chamber to house or senate depending on the user's role
                let selectedChamber = '';

                //set the default chamber to house or senate depending on the user's role
                let disableChamberSelector = false;

                if (!this.props.login.userClaims.claims.find(x => x.RoleName === "Admin")) {
                    //Find if the user is a house calendar editor, if so select house, otherwise select senate, and disable the chamber selector
                    selectedChamber = this.props.login.userClaims.claims.find(x => x.RoleName === "HouseLegislationCommunicationsEditor") ? this.state.chamberOptions.find(chamber => chamber.label === "House") : this.state.chamberOptions.find(chamber => chamber.label === "Senate");
                    disableChamberSelector = true;
                }

                this.setState({
                    sessionOptions: sessionList,
                    selectedChamber: selectedChamber,
                    disableChamberSelector: disableChamberSelector
                }, () => {
                    //Load the list of communicationsand put them in the grid
                    this.loadCommsList();
                });
            });
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        return (
            <div>
                <div className="header-half">
                    <h1>Communications Management</h1>
                    {this.props.login.userClaims.claims.find(c => c.RoleName === "Admin") &&
                        <div className="flex-row flex-vertical-center flex-end">
                            <a className="icon settings" href="/configurations-management/communications" />
                        </div>
                    }
                </div>

                <div className="toolbar minutes-toolbar dlas-forms">
                    <div>
                        <Fields.Dropdown
                            value={this.state.selectedChamber}
                            options={this.state.chamberOptions}
                            onChange={this.handleChamberChange}
                            isDisabled={this.state.disableChamberSelector}
                        />
                    </div>
                    <div>
                        <Fields.Dropdown
                            value={this.state.selectedSession}
                            options={this.state.sessionOptions}
                            onChange={this.handleSessionChange}
                        />
                    </div>
                </div>
                <ReactTable
                    resizable={false}
                    loading={!this.state.isLoaded}
                    data={this.state.commList}
                    ref={r => this.reactTable = r}
                    columns={[
                        {
                            Header: "Communications Date",
                            columns: [
                                {
                                    Header: (
                                        <div className="dlas-forms datepicker-container">
                                            {!this.state.showCreateSuccess &&
                                                <DatePicker
                                                    id="commDate"
                                                    className="dnd-field"
                                                    selected={this.state.commDate}
                                                    onChange={this.handleDateChange}
                                                    placeholderText="Communications Date"

                                                />
                                            }
                                        </div>
                                    ),
                                    headerClassName: "header-insert",
                                    accessor: "CommunicationDate",
                                    sortable: false,
                                    width: 200,
                                    Cell: row => <span>{moment(row.value).format('L')}</span>
                                }
                            ]
                        },
                        {
                            Header: "ID",
                            columns: [
                                {
                                    Header: (<div></div>),
                                    headerClassName: "header-insert",
                                    accessor: "CommunicationNumber",
                                    sortable: false,
                                    width: 140,
                                    Cell: row => <span>{row.value}</span>
                                }
                            ]
                        },
                        {
                            Header: "Session",
                            columns: [
                                {
                                    Header: (
                                        <div>
                                            {this.state.showCreateSuccess &&
                                                <span className="insert-message">Communications created successfully!</span>
                                            }
                                            {this.state.createErrorMessage &&
                                                <span className="insert-message fail">{this.state.createErrorMessage}</span>
                                            }
                                            {!this.state.createErrorMessage && !this.state.showCreateSuccess &&
                                                <span>{this.state.selectedSession.label}</span>
                                            }
                                            {this.state.showDeleteSuccess &&
                                                <span className="insert-message">Communication was deleted successfully!</span>
                                            }
                                            {this.state.deleteFailureMessage &&
                                                <span className="insert-message fail">{this.state.deleteFailureMessage}</span>
                                            }
                                        </div>
                                    ),
                                    headerClassName: "header-insert-dropdown",
                                    accessor: "SessionID",
                                    sortable: false,
                                    Cell: row => <span>{this.state.selectedSession.label}</span>
                                }
                            ]
                        },
                        {
                            Header: 'Actions',
                            headerClassName: "submit-action",
                            sortable: false,
                            filterable: false,
                            columns: [
                                {
                                    Header: () => this.state.isAdding ?
                                        <span><button className="button" onClick={this.createComm} disabled={true}>Adding...</button></span>
                                        :
                                        <span><button className="button" onClick={this.createComm} disabled={!this.state.commDate || !this.state.selectedChamber || !this.state.selectedSession}>Create</button></span>
                                    ,
                                    headerClassName: "header-action",
                                    accessor: "action",
                                    sortable: false,
                                    className: "action-column",
                                    width: 90,
                                    Cell: row => {
                                        return (
                                            <div className="committee-action-column-two">
                                                <Link to={`/communications-management/edit-communication/${row.original.CommunicationID}`} className="icon edit"></Link>
                                                {row.original.IsPublic ?
                                                    <Link target="_blank" to={`/session-details/${this.state.selectedSession.SessionCode}/communications/${row.original.ReferenceNumber}`} className="icon book"></Link>
                                                    :
                                                    <span onClick={() => this.deleteCommunication(row.index)} className="icon delete"></span>
                                                }
                                            </div>
                                        )
                                    }
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={15}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    className="committee-table -striped -highlight"
                />
            </div>
        )

    }
}

export default connect(
    (state) => {
        const { session, communications, login } = state;
        return {
            session,
            communications,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, communicationsActionCreators, authActionCreators), dispatch)
        }
    }
)(CommunicationsManagementGrid)