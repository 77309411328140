import React from 'react';
import ReactToPrint from "react-to-print";
import CommunicationsDetailsComponent from '../../../lis-shared/lis-communications/lis-communications-details';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import { communicationsActionCreators } from '../../../stores/lis-communications-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import BillsGrid from './lis-bills-grid';
import { cancelRequest } from '../../../services/request.service';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { personnelActionCreators } from '../../../stores/lis-personnel-store';
import { Prompt } from 'react-router';
const PUBLISH_COMM = true;
const SAVE_COMM = false;
let abortController = null;

class CommunicationsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            communicationCategories: [],
            organizedComms: [],
            hideCategories: [],
            commGet: '',
            isLoaded: false,
            errorMessage: '',
            isSaving: false,
            pendingSave: true,
            showPreview: false,
            selectedCategory: '',
            selectedBill: [],
            billList: [],
            referenceNumber: '',
            failedToJoinBills: [],
            isDirty: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateAgenda = this.updateAgenda.bind(this);
        this.changeCategoryDropdown = this.changeCategoryDropdown.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.changeBillDropdown = this.changeBillDropdown.bind(this);
        this.addBill = this.addBill.bind(this);

        this.togglePreview = this.togglePreview.bind(this);
        this.saveComm = this.saveComm.bind(this);
        this.genCommDoc = this.genCommDoc.bind(this);
        this.hasSelectedBills = this.hasSelectedBills.bind(this);
        this.hasIncludedBills = this.hasIncludedBills.bind(this);
        this.toggleShowSignatureNote = this.toggleShowSignatureNote.bind(this);
        this.includeAll = this.includeAll.bind(this);
        this.validateCommunicationNumber = this.validateCommunicationNumber.bind(this);
        this.toggleHideCategory = this.toggleHideCategory.bind(this);
    }

    toggleHideCategory(catIndex) {
        let hideCategories = this.state.hideCategories;
        hideCategories[catIndex] = !hideCategories[catIndex]
        this.setState({
            hideCategories: hideCategories
        });
    }

    handleInputChange(key, val) {
        let commGet = { ...this.state.commGet };
        commGet[key] = val;
        this.setState({
            commGet: commGet,
            isDirty: true
        }, () => {
            this.validateCommunicationNumber();
            //Update ref number with new comm date and comm number
            const data = this.state.commGet;
            let newReferenceNumber = data.ChamberCode + 'N';
            newReferenceNumber += data.CommunicationNumber.toString();
            let formattedDate = moment(data.CommunicationDate).format('MMDD');
            newReferenceNumber += formattedDate;
            this.setState({
                referenceNumber: newReferenceNumber
            });
        });
    }

    updateAgenda(organizedComms, saveRequired) {
        this.setState({
            organizedComms: organizedComms,
            isDirty: true,
            pendingSave: saveRequired === undefined ? this.state.pendingSave : saveRequired
        });
    }

    togglePreview() {
        this.setState(state => ({
            showPreview: !state.showPreview
        }));
    }

    saveComm(publishing) {
        if (!this.state.commGet.CommunicationNumber) {
            return;
        }
        this.setState({ isSaving: true });
        let commSave = { ...this.state.commGet };
        commSave.CommunicationCategories = this.state.organizedComms;
        commSave.ReferenceNumber = this.state.referenceNumber || commSave.ReferenceNumber;
        //Set whether it should be published or not. The published value depends on what button the user clicked
        commSave.IsPublic = publishing;
        this.props.actions.saveComm({ CommunicationResponse: [commSave] })
            .then(failureMessage => {
                if (publishing) {
                    const commId = this.props.communications.commSave.CommunicationID;
                    this.props.actions.saveCommFile("?communicationID=" + commId);
                    //Communications cannot be edited once published so send the user back to the grid
                    this.props.history.push('/communications-management');
                } else {
                    //If the form was not published then get the new mod dates and update the React state variables
                    const commSave = { ...this.props.communications.commSave };

                    commSave.FooterSentence = this.props.communications.commSave.ChamberCode === "S" ? "IN WHICH ACTION IT REQUESTS THE CONCURRENCE OF THE HOUSE OF DELEGATES." : "IN WHICH ACTION IT REQUESTS THE CONCURRENCE OF THE SENATE.";
                    commSave.ClerkTitle = this.props.communications.commSave.ChamberCode === "H" ? "Clerk of the House of Delegates" : "Clerk of the Senate";
                    commSave.ClerkName = this.props.personnel.personnelOtherList ? this.props.personnel.personnelOtherList[0].FullName : "";

                    this.setState({
                        organizedComms: commSave.CommunicationCategories,
                        commGet: commSave,
                        isSaving: false,
                        pendingSave: false,
                        isDirty: false
                    });
                }
                if (failureMessage && failureMessage.includes("Minutes")) {
                    this.props.actions.makeToast([{ message: `Communication ${publishing ? 'Published' : 'Saved'}`, type: "success" }, { message: "Problem with Minutes Creation", type: "failure" }])
                } else {
                    this.props.actions.makeToast([{ message: `Communication ${publishing ? 'Published' : 'Saved'}`, type: "success" }])
                }
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
                this.setState({
                    isSaving: false
                });
                this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
            });

    }

    validateCommunicationNumber() {
        this.setState({ duplicateCommNumber: this.state.commNumbers.find(n => n === this.state.commGet.CommunicationNumber) });
    }

    changeCategoryDropdown(value) {
        this.setState({
            selectedCategory: value
        });
    }

    addCategory() {
        const selectedCategory = this.state.selectedCategory;
        //Used to find the new index of the category so the hidden categories indices can be updated
        const categoryFinder = new Date().getTime();
        let organizedComms = [...this.state.organizedComms];
        let newCommCategory = {
            CommunicationLegislation: [],
            IsLegislationCategory: selectedCategory.IsLegislationCategory,
            CommunicationCategoryTypeID: selectedCategory.CommunicationCategoryTypeID,
            CategoryCode: selectedCategory.CategoryCode,
            CategoryDescription: selectedCategory.Description,
            PluralDescription: selectedCategory.PluralDescription,
            Sequence: selectedCategory.Sequence,
            isNewCategory: categoryFinder
        };
        //push new category
        organizedComms.push(newCommCategory);
        //Sort the communication by sequence
        organizedComms.sort((a, b) => a.Sequence - b.Sequence);
        //Update the categories that are hidden since the array indices have changed
        const newCategoryIndex = organizedComms.filter(c => !c.DeletionDate).findIndex(cat => cat.isNewCategory === categoryFinder);
        if (newCategoryIndex !== -1) {
            let hideCategories = this.state.hideCategories;
            hideCategories.splice(newCategoryIndex, 0, false);
            this.setState({
                hideCategories: hideCategories
            });
        }
        //Remove the selected category so the user doesn't accidently add it twice
        this.setState({
            selectedCategory: '',
            organizedComms: organizedComms,
            isDirty: true
        });
    }

    changeBillDropdown(index, value) {
        let selectedBill = [...this.state.selectedBill];
        selectedBill[index] = value;
        this.setState({
            selectedBill: selectedBill
        });
    }

    addBill(index) {
        let organizedComms = [...this.state.organizedComms];
        let newAgenda = {};
        //Check whether to move the bill from another category or add the bill
        let billExists = false;
        if (this.state.selectedBill[index]) {
            organizedComms.some((category, categoryIndex) => {
                const duplicateBillIndex = category.CommunicationLegislation.findIndex(bill => bill.LegislationID === this.state.selectedBill[index].LegislationID);
                if (duplicateBillIndex >= 0) {
                    billExists = true;
                    newAgenda = { ...organizedComms[categoryIndex].CommunicationLegislation[duplicateBillIndex] };
                    newAgenda.CommunicationCategoryTypeID = organizedComms[index].CommunicationCategoryTypeID;
                    newAgenda.CommunicationCategoryID = organizedComms[index].CommunicationCategoryID;
                    newAgenda.IsActive = true;
                    newAgenda.IsCandidate = false;
                    organizedComms[categoryIndex].CommunicationLegislation.splice(duplicateBillIndex, 1);
                    return true;
                }
            });
            if (!billExists) {
                newAgenda = {
                    CommunicationCategoryTypeID: organizedComms[index].CommunicationCategoryTypeID,
                    CommunicationCategoryID: organizedComms[index].CommunicationCategoryID,
                    IsActive: true,
                    LegislationID: this.state.selectedBill[index].LegislationID,
                    LegislationNumber: this.state.selectedBill[index].LegislationNumber,
                    Patrons: this.state.selectedBill[index].Patrons,
                    Description: this.state.selectedBill[index].Description,
                    IsCandidate: false
                }
            }
            organizedComms[index].CommunicationLegislation.push(newAgenda);

            //Remove object so the bill is no longer currently selected in the dropdown
            let selectedBill = [...this.state.selectedBill];
            selectedBill[index] = '';
            this.setState({
                selectedBill: selectedBill,
                organizedComms: organizedComms,
                isDirty: true
            });
        }
    }

    genCommDoc(printAllIncluded) {
        let billsToGen = [];
        this.state.organizedComms.forEach(category => {
            const associatedCategory = this.state.communicationCategories.find(c => c.CategoryCode === category.CategoryCode);
            category.CommunicationLegislation.forEach(item => {
                //if user clicked "Print all included", push all items that are active (i.e. included)
                //if user clicked "Print selected", push all items that are checked
                if (printAllIncluded && item.IsActive) {
                    billsToGen.push({
                        billNum: item.LegislationNumber,
                        LegislationTextID: item.LegislationTextID,
                        LegislationID: item.LegislationID,
                        CommunicationID: this.state.commGet.CommunicationID,
                        SessionID: this.state.commGet.SessionID,
                        AmendmentCount: associatedCategory ? associatedCategory.AmendmentCount : null,
                        FirstChamberCount: associatedCategory ? associatedCategory.FirstChamberCount : null,
                        SecondChamberCount: associatedCategory ? associatedCategory.SecondChamberCount : null
                    })
                }
                else if (!printAllIncluded && item.checked) {
                    billsToGen.push({
                        billNum: item.LegislationNumber,
                        LegislationTextID: item.LegislationTextID,
                        LegislationID: item.LegislationID,
                        CommunicationID: this.state.commGet.CommunicationID,
                        SessionID: this.state.commGet.SessionID,
                        AmendmentCount: associatedCategory ? associatedCategory.AmendmentCount : null,
                        FirstChamberCount: associatedCategory ? associatedCategory.FirstChamberCount : null,
                        SecondChamberCount: associatedCategory ? associatedCategory.SecondChamberCount : null
                    });
                }
            });
        });
        this.setState({
            gettingPdf: true,
            failedToJoinBills: [],
            pdfSrc: ''
        });
        this.props.actions.joinCommFile({ LegislationFiles: billsToGen })
            .then(() => {
                const res = this.props.communications.joinCommFile;
                if (res.Pdf && res.Pdf.FileContents) {
                    fetch(`data:application/pdf;base64,${res.Pdf.FileContents}`)
                        .then(res => res.blob())
                        .then(blob => {
                            const urlObj = URL.createObjectURL(blob);
                            this.setState({
                                pdfSrc: urlObj
                            });
                        });
                }
                let failedBills = [];
                res.JoinLegislation.forEach(leg => {
                    if (!leg.Generated && !res.JoinLegislation.find(l => l.LegislationID === leg.LegislationID && l.Generated)) {
                        const bill = billsToGen.find(bill => bill.LegislationTextID === leg.LegislationTextID || bill.LegislationID === leg.LegislationID)
                        if (bill && !failedBills.includes(bill)) {
                            failedBills.push(bill)
                        }
                    }
                });

                if (failedBills.length === billsToGen.length) {
                    // Every single bill in the list does not have a clerk copy
                    this.props.actions.makeToast([{ message: "No selected bills have a clerk copy", type: "failure", long: true }]);
                    this.setState({
                        gettingPdf: false
                    });
                } else {
                    this.setState({
                        failedToJoinBills: failedBills,
                        showPdfWindow: true,
                        gettingPdf: false
                    });
                }
            }).catch(err => {
                if (err === 'AbortError') {
                    return;
                }
                this.setState({
                    gettingPdf: false
                });
                console.log(err.toString());
                this.props.actions.makeToast([{ message: "Failed To Get Data", type: "failure" }]);
            })
    }

    hasSelectedBills() {
        let hasSelectedBills = false;
        this.state.organizedComms.forEach(category => {
            category.CommunicationLegislation.forEach(item => {
                if (item.checked) { hasSelectedBills = true; return }
            })
        })

        return hasSelectedBills;
    }

    hasIncludedBills() {
        let hasIncludedBills = false;
        this.state.organizedComms.forEach(category => {
            category.CommunicationLegislation.forEach(item => {
                if (item.IsActive) { hasIncludedBills = true; return }
            })
        })

        return hasIncludedBills;

    }

    includeAll() {
        let organizedComms = [...this.state.organizedComms]
        organizedComms.forEach(category => {
            category.selectAll = false;
            category.CommunicationLegislation.forEach(item => {
                item.IsActive = true;
                item.checked = false;
            })
        })

        this.updateAgenda(organizedComms);
    }

    toggleShowSignatureNote() {
        let commGet = { ...this.state.commGet };
        commGet.ShowSignatureNote = !commGet.ShowSignatureNote;
        this.setState({
            commGet: commGet
        })
    }

    componentDidMount() {
        //Get the list of bills for the form
        const communicationsId = this.props.match.params.communicationid
        this.props.actions.getComm('?communicationID=' + communicationsId, true)
            .then(() => {
                const commGet = { ...this.props.communications.commGet };
                if (!commGet.CommunicationCategories) {
                    throw 'No categories in communication';
                }
                let noSavedItems = true;
                //bills are added to the communication by default so set IsActive to true for all of them
                commGet.CommunicationCategories.forEach(category => {
                    category.CommunicationLegislation.forEach(leg => {
                        if (leg.IsActive === undefined) {
                            leg.IsActive = true;
                        }
                        if (leg.CommunicationLegislationID) {
                            noSavedItems = false;
                        }
                    })
                });
                // Get the list of communication categories
                this.props.actions.getCommCategories('?chamberCode=' + commGet.ChamberCode)
                    .then(() => {
                        let catList = [...this.props.communications.commCategories];
                        catList.forEach(cat => {
                            cat.label = cat.Description;
                            cat.value = cat.CommunicationCategoryTypeID;
                        });
                        this.setState({
                            communicationCategories: catList
                        });
                    });
                //Get the list of existing communications so we know communication numbers (to alert user in the event they change the number to a duplicate)
                const commParams = '?sessionID=' + commGet.SessionID + '&chamberCode=' + commGet.ChamberCode + '&isPublic=false&communicationType=Floor';
                this.props.actions.getCommList(commParams, true)
                    .then(() => {
                        const commNumbers = this.props.communications.commList.filter(comm => comm.CommunicationID !== commGet.CommunicationID && moment(comm.CommunicationDate).isSame(commGet.CommunicationDate, 'day')).map(comm => comm.CommunicationNumber);
                        this.setState({
                            commNumbers: commNumbers,
                            duplicateCommNumber: commNumbers.find(n => n === commGet.CommunicationNumber)
                        });
                    });

                //Get bills in the session for the bill dropdown
                this.props.actions.getSessionBills('?sessionID=' + commGet.SessionID)
                    .then(() => {
                        let billList = [...this.props.bills.sessionBills];
                        billList.forEach(bill => {
                            bill.label = bill.LegislationNumber;
                            bill.value = bill.LegislationID;
                        });
                        this.setState({
                            billList: billList
                        })
                    });

                this.props.actions.getRoleTypes()
                    .then(() => {
                        let roleType = this.props.personnel.roleTypes.find(x => x.Name.toLowerCase().includes("clerk") && x.Name.toLowerCase().includes(this.props.communications.commGet.ChamberCode === "H" ? "house" : "senate"))
                        let clerkRoleTypeID = roleType ? roleType.StaffRoleTypeID : 0;
                        this.props.actions.getPersonnelList("staffroletypeid=" + clerkRoleTypeID + "&isPublic=true")
                            .then(() => {
                                commGet.FooterSentence = this.props.communications.commGet.ChamberCode === "S" ? "IN WHICH ACTION IT REQUESTS THE CONCURRENCE OF THE HOUSE OF DELEGATES." : "IN WHICH ACTION IT REQUESTS THE CONCURRENCE OF THE SENATE.";
                                commGet.ClerkTitle = this.props.communications.commGet.ChamberCode === "H" ? "Clerk of the House of Delegates" : "Clerk of the Senate";
                                commGet.ClerkName = this.props.personnel.personnelOtherList ? this.props.personnel.personnelOtherList[0].FullName : "";
                            });
                    })

                commGet.ShowSignatureNote = [null, true].includes(commGet.ShowSignatureNote); // attr is returned as null on first creation and should default to true
                this.setState({
                    organizedComms: [...commGet.CommunicationCategories],
                    commGet: commGet,
                    isLoaded: true,
                    pendingSave: noSavedItems
                });

                //Get the session data for use in the preview
                this.props.actions.getSessionById(commGet.SessionID)
                    .then(() => {
                        this.setState({
                            session: this.props.session.selectedSession
                        });
                    })
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    isLoaded: true,
                    errorMessage: err
                });
            });
    }

    componentWillUnmount() {
        if (abortController)
            abortController.abort();
        cancelRequest();
    }

    render() {
        if (this.state.showPreview) {
            return (
                <div>
                    <div className="flex-row">
                        <h2>Communications Preview</h2>
                        <ReactToPrint
                            trigger={() => <a type="button" className="button print"> Print</a>}
                            content={() => this.componentRef}
                            pageStyle={"break-inside: avoid"}
                        />
                    </div>
                    <div className="checkbox-container">
                        <div className="inner-grid two">
                            <div className="checkbox">
                                <label><input type="checkbox" onChange={() => this.toggleShowSignatureNote()} checked={this.state.commGet.ShowSignatureNote} /> Show Clerk's Note</label>
                            </div>
                            <div />
                        </div>
                    </div>
                    <div ref={el => (this.componentRef = el)}>
                        <CommunicationsDetailsComponent
                            session={this.state.session}
                            communication={this.state.commGet}
                            refNum={this.state.referenceNumber}
                            authoring
                        />
                    </div>
                    <div className="button-bar">
                        <div></div>
                        <div className="align-right">
                            <button onClick={this.togglePreview} className="button secondary">Edit</button>
                            <button onClick={() => this.saveComm(PUBLISH_COMM)} disabled={this.state.isSaving} className="button">{this.state.isSaving ? "Publishing..." : "Publish"}</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            const allLegislation = this.state.organizedComms.flatMap(cat => cat.CommunicationLegislation ? cat.CommunicationLegislation.filter(cl => cl.IsActive).map(cl => cl.LegislationNumber) : []);
            const hasDuplicates = Boolean(allLegislation.filter((leg, idx) => allLegislation.indexOf(leg) !== idx).length);
            return (
                <React.Fragment>
                    <div>
                        <Prompt
                            when={this.state.isDirty}
                            message={`You have unsaved changes. Are you sure you would like to leave?`}
                        />
                    </div>
                    <div style={{ display: this.state.showPdfWindow ? 'block' : 'none', overflow: 'hidden' }} onClick={() => this.setState({ showPdfWindow: false })} className="overlay center-content">
                        <div className="full full-height" style={{ maxWidth: '100vw', width: '95%' }}>
                            <div onClick={e => e.stopPropagation()} className="dlas-forms user-forms overlay-content animated-fade-in-up full-height">
                                <div>
                                    <button className="button print" onClick={() => this.pdfRef ? this.pdfRef.contentWindow.print() : null}> Print</button>
                                </div>
                                {this.state.failedToJoinBills.length > 0 &&
                                    <div className="small-text">
                                        <span>These bills do not have clerk copies and are not included in the PDF: </span>
                                        <span>{this.state.failedToJoinBills.map((bill, i) => <React.Fragment key={i}>{i !== 0 && ", "}{bill.billNum}</React.Fragment>)}</span>
                                    </div>
                                }
                                <div className="center full-height">
                                    <iframe className="center" style={{ width: '100%', height: '90%' }} ref={el => this.pdfRef = el} src={this.state.pdfSrc}></iframe>
                                    <button style={{ position: 'absolute', bottom: '10px', right: '10px' }} type="button" onClick={() => this.setState({ showPdfWindow: false })} className="button secondary">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.commGet ? <h1>{(this.state.commGet.ChamberCode === "H" ? "House " : "Senate ") + "Communication"}</h1> : <h1>&nbsp;</h1>}
                    <div className="user-forms">
                        {!this.state.isLoaded ?
                            <div className="center-spinner spinner">Fetching results...</div>
                            :
                            <div className="dlas-forms">
                                <span className="error-message">{this.state.errorMessage}</span>
                                <fieldset className="fieldset-collapse fieldset-open" style={{ paddingBottom: '50px' }}>
                                    <legend>Communication Information</legend>
                                    <div className="multi-row four no-margin">
                                        <div>
                                            <label>Communication Date</label>
                                            <DatePicker
                                                selected={moment(this.state.commGet.CommunicationDate)}
                                                onChange={val => this.handleInputChange('CommunicationDate', val)}
                                            />
                                        </div>
                                        <div>
                                            <label>Communication ID</label>
                                            <input
                                                type="number"
                                                placeholder="Communication ID"
                                                value={this.state.commGet.CommunicationNumber}
                                                onChange={e => this.handleInputChange('CommunicationNumber', parseInt(e.target.value))}
                                            />
                                            {this.state.duplicateCommNumber && <div className="input-feedback-warning">Warning: Communication ID {this.state.commGet.CommunicationNumber} is already in use on another communication on this same communication date.</div>}
                                            {!this.state.commGet.CommunicationNumber && <div className="input-feedback">Communication ID is required.</div>}
                                        </div>
                                        <div />
                                        <div>
                                            <button type="button" className="button m-left" style={{ float: 'right' }} disabled={!this.state.organizedComms.map(comm => comm.CommunicationLegislation).find(leg => leg.find(it => !it.IsActive))} onClick={this.includeAll}>Include All</button>
                                        </div>
                                    </div>
                                    <BillsGrid
                                        organizedComms={this.state.organizedComms}
                                        communicationCategories={this.state.communicationCategories}
                                        updateAgenda={this.updateAgenda}
                                        changeCategoryDropdown={this.changeCategoryDropdown}
                                        changeCategoryPositionDropdown={this.changeCategoryPositionDropdown}
                                        selectedCategory={this.state.selectedCategory}
                                        addCategory={this.addCategory}
                                        selectedBill={this.state.selectedBill}
                                        changeBillDropdown={this.changeBillDropdown}
                                        addBill={this.addBill}
                                        billList={this.state.billList}
                                        sessionCode={this.state.commGet.SessionCode}
                                        hideCategories={this.state.hideCategories}
                                        toggleHideCategory={this.toggleHideCategory}
                                    />
                                </fieldset>
                                <div className="floating-button-bar">
                                    <div className="flex-row flex-vertical-center" style={{ width: "100%" }}>
                                        <div className="inline-list">
                                            <button disabled={this.state.pendingSave || this.state.gettingPdf || !this.hasSelectedBills()} title={this.state.pendingSave ? "You must save the pending changes in order to print" : this.hasSelectedBills() ? "Print Selected" : "You must select one or more legislation"} type="button" className="button" onClick={() => this.genCommDoc(false)}>{this.gettingPdf ? "Getting Document..." : "Print Selected"}</button>
                                            <button disabled={this.state.pendingSave || this.state.gettingPdf || !this.hasIncludedBills()} title={this.state.pendingSave ? "You must save the pending changes in order to print" : this.hasIncludedBills() ? "Print All Included" : "You must include one or more legislation"} type="button" className="button" onClick={() => this.genCommDoc(true)}>{this.gettingPdf ? "Getting Document..." : "Print All Included"}</button>
                                        </div>
                                        <div className="inline-list">
                                            {hasDuplicates && <span style={{ fontSize: '15px' }}>Please remove all duplicate legislation included in multiple categories before saving</span>}
                                            <button disabled={hasDuplicates || this.state.isSaving || !this.state.commGet.CommunicationNumber} onClick={() => this.saveComm(SAVE_COMM)} className="button" type="submit">{this.state.commGet.IsPublic ? "Unpublish" : this.state.isSaving ? "Saving..." : "Save"}</button>
                                            <button disabled={hasDuplicates || this.state.pendingSave || !this.state.commGet.CommunicationNumber || this.state.commGet.IsPublic} onClick={this.togglePreview} className="button" type="button">Preview</button>
                                            {!this.state.commGet.CommunicationNumber && <div className="input-feedback">Communication ID is required.</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default connect(
    (state) => {
        const { bills, session, communications, minutes, nav, personnel } = state;
        return {
            bills,
            session,
            communications,
            minutes,
            nav,
            personnel
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, billActionCreators, sessionActionCreators, communicationsActionCreators, minutesActionCreators, navActionCreators, personnelActionCreators), dispatch)
        }
    }
)(CommunicationsForm)