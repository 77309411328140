import React from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment-timezone';

class ImpactStatementForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fiscal: true,
            selectedAgency: "",
            uploadFile: ""
        }

        this.getImpactStatementDescription = this.getImpactStatementDescription.bind(this);
        this.handleAgencyChange = this.handleAgencyChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleRadioChange() {
        this.setState({
            fiscal: !this.state.fiscal
        })
    }

    handleAgencyChange(value) {
        this.setState({
            selectedAgency: value
        })
    }

    handleFileChange(e) {
        this.setState({
            uploadFile: e.target.files[0]
        })
    }

    getImpactStatementDescription() {
        if (!this.state.selectedAgency) { return "" }
        let description;
        if (!this.state.fiscal) {
            description = "Racial and ethnic impact statement";
        } else if (this.state.selectedAgency.Abbreviation !== "JLARC") {
            description = "Impact statement";
        } else {
            description = "Fiscal impact review";
        }

        description += " from " + this.state.selectedAgency.Abbreviation + " (" + this.props.match.params.billnumber + ")";
        return description;
    }

    submit() {
        this.props.submit(this.getImpactStatementDescription(), this.state.selectedAgency, this.state.uploadFile);
    }

    render() {
        let description = this.getImpactStatementDescription();
        if (description) {
            description = description.substr(0, description.indexOf("("))
        }
        return (
            <div className="dlas-forms">
                <p>{"Impact Statement for " + moment(this.props.statementDate).format("MM/DD/YYYY") + " - " + this.props.statementDescription}</p>
                <div className="grid-wrapper one-two-small-small">
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <div style={{ display: "inline-flex", alignItems: 'center', marginRight: '0.5em' }}>
                            <label htmlFor="selected-type-button" className={`label radio-label`} style={{ whiteSpace: "no-wrap", width: 'auto' }}>Fiscal</label>
                            <input id="selected-type-button" checked={this.state.fiscal === true} onChange={() => this.handleRadioChange()} type="radio" />
                        </div>
                        <div style={{ display: "inline-flex", alignItems: 'center' }}>
                            <label htmlFor="all-sessions-button" className={`label radio-label`} style={{ whiteSpace: "no-wrap", width: 'auto' }}>Racial and Ethnic</label>
                            <input id="all-sessions-button" checked={this.state.fiscal === false} onChange={() => this.handleRadioChange()} type="radio" />
                        </div>
                    </div>
                    <Select
                        options={this.props.agencyOptions}
                        onChange={this.handleAgencyChange}
                        value={this.state.selectedAgency}
                        isSearchable
                    />
                    <button type="button" className="button small-button file-button">
                        <input type="file" accept=".pdf" onChange={this.handleFileChange} />
                        {this.state.uploadFile ? this.state.uploadFile.name : "Attach Impact Statement"}
                    </button>
                </div>
                <br />
                <div>
                    <p style={{ position: 'absolute' }}>{description ? "Description: " + description : ""}</p>
                    <div className="align-right">
                        <button type="button" className="button secondary" style={{ marginRight: "15px" }} onClick={() => this.props.toggleStatementForm()}>Cancel</button>
                        <button type="button" className="button default" disabled={this.state.uploadFile === "" || this.state.selectedAgency === "" || this.props.isSubmitting} onClick={() => this.submit()}>Upload</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ImpactStatementForm);
