import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { memberActionCreators } from '../../../stores/lis-members-store';
import { billActionCreators } from '../../../stores/lis-legislation-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { patronActionCreators } from '../../../stores/lis-patron-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import AdvancedSearchBox from '../../../lis-shared/lis-search/lis-advanced-search-box';
import moment from 'moment-timezone';
import queryString from 'query-string';
import '../../../stylesheets/lis-public-view/public-view.css';
import BillCategories from '../../../lis-shared/lis-search/lis-search-categories';
import SearchSelections from '../../../lis-shared/lis-search/lis-search-selections';
import LegislationWatchlists from '../../../lis-shared/lis-search/lis-legislation-watchlists';
import WatchlistNotifications from '../../../lis-shared/lis-search/lis-watchlist-notifications';
import CollectionControls from '../../../lis-shared/lis-search/lis-collection-controls'
import { cancelRequest } from '../../../services/request.service';
import { collectionActionCreators } from '../../../stores/lis-collection-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import ReportMaker from './lis-report-maker';
import BillInfoComponent from '../../../lis-shared/lis-search/lis-bill-info';
import SearchHelp from './lis-search-help';
import QuickAdd from './quick-add-bills';
import { reportActionCreators } from '../../../stores/lis-report-store';

const BILL_COLLECTION_AUTHOR = "LegislationCollections";
const PageSize = 50;

const sortLegislations = (a, b) => {
    if (a.LegislationNumber[0] !== b.LegislationNumber[0]) {
        return a.LegislationNumber[0].localeCompare(b.LegislationNumber[0]);
    } else if (a.LegislationNumber[1] !== b.LegislationNumber[1]) {
        return a.LegislationNumber[1].localeCompare(b.LegislationNumber[1]);
    } else {
        const aLegislationKey = a.LegislationNumber.substr(2);
        const bLegislationKey = b.LegislationNumber.substr(2);
        return parseInt(aLegislationKey - bLegislationKey);
    }
}

const validLegPrefixes = ["HB", "SB", "HJ", "SJ", "HR", "SR"];

class BillSearchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCategories: false,
            billList: [],
            billStatusCategoryList: [],
            billStatusCategoryListIsLoading: true,
            billEventOptions: [],
            selectedBillEvent: '',
            selectedBill: '',
            selectedKeywords: '',
            searchedKeywords: '',
            selectedBillAction: '',
            billEventStartDate: null,
            billEventEndDate: null,
            selectedBillSession: '',
            selectedBillType: '',
            previousSearch: '',
            searchType: { value: 'all', label: 'All' },
            selectedChamber: '',
            patronList: [],
            patronRoles: [],
            selectedBillNumbers: '',
            selectedBillRangeBeginning: '',
            selectedBillRangeEnd: '',
            selectedChapterNumber: '',
            filteredPatronList: [],
            selectedPatron: '',
            selectedPatronType: [],
            selectedBillStatusCategory: '',
            sessionOptions: [],
            billStatusStartDate: null,
            billStatusEndDate: null,
            committeeList: [],
            groupedCommitteeList: [],
            filteredCommitteeList: [],
            selectedCommittee: '',
            selectedSubcommittee: '',
            subcommitteeList: [],
            subjectList: [],
            selectedSubject: '',
            introDateList: [],
            collections: [],
            selectedCollection: '',
            selectedCollectionIndex: -1,
            billVersionList: [],
            selectedBillVersion: 'All',
            billSummaryList: [],
            selectedSummaryVersion: 'All',
            selectedLocation: 'Caption',
            criteriaTypes: [],
            checkedBills: [],
            lastCheckedBill: -1,
            showAllNotes: false,
            selectAllBills: false,
            collectionIsSaving: false,
            keywordUseGlobalSessionSearch: false,
            displayCrossSession: false,
            currentStatus: true,
            excludeFailed: false,
            autoReport: false,
            showSidebar: true,
            height: 1,
            showModal: false,
            introductionDate: null,
            showHelpModal: false,
            showUpdateSearchButton: false,
            compositeView: false
        };
        this.searchOnEnterPress = this.searchOnEnterPress.bind(this);
        this.toggleGroup = this.toggleGroup.bind(this);
        this.toggleShowCategories = this.toggleShowCategories.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.getBillsResults = this.getBillsResults.bind(this);
        this.chamberChange = this.chamberChange.bind(this);
        this.sessionChange = this.sessionChange.bind(this);
        this.committeeChange = this.committeeChange.bind(this);
        this.handlePatronChange = this.handlePatronChange.bind(this);
        this.handlePatronTypeChange = this.handlePatronTypeChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectionClear = this.handleSelectionClear.bind(this);
        this.handleMultiSelectionClear = this.handleMultiSelectionClear.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.getCollections = this.getCollections.bind(this);
        this.selectCollection = this.selectCollection.bind(this);
        this.handleCollectionChange = this.handleCollectionChange.bind(this);
        this.createCollection = this.createCollection.bind(this);
        this.deleteCollection = this.deleteCollection.bind(this);
        this.addToWatchlists = this.addToWatchlists.bind(this);
        this.removeWatchlistBills = this.removeWatchlistBills.bind(this);
        this.toggleReportMaker = this.toggleReportMaker.bind(this);
        this.toggleAllBills = this.toggleAllBills.bind(this);
        this.handleBillCheckbox = this.handleBillCheckbox.bind(this);
        this.quickAddBills = this.quickAddBills.bind(this);
        this.toggleAllNotes = this.toggleAllNotes.bind(this);
        this.handleCurrentStatusChange = this.handleCurrentStatusChange.bind(this);
        this.handleExcludeFailedChange = this.handleExcludeFailedChange.bind(this);
        this.unload = this.unload.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.getSessions = this.getSessions.bind(this);
        this.toggleShowData = this.toggleShowData.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleHelpModal = this.toggleHelpModal.bind(this);
        this.getReportTitle = this.getReportTitle.bind(this);
        this.handleToggleCompositeView = this.handleToggleCompositeView.bind(this);

        // The div that sorrounds the page is in this component but the function that needs to run to be able to make a search is in the child component.
        // This ref is used to call the child component's function.
        this.searchBox = React.createRef();
        this._billResultRefs = [];
    }

    componentDidMount() {
        // IF A SEARCH QUERY EXISTS
        let queryObj = '';
        const parsed = queryString.parse(this.props.location.search, { decode: false });
        if (parsed.q) {
            const base64 = parsed.q;
            const decodedSearch = window.atob(base64);
            queryObj = JSON.parse(decodedSearch);
            this.setState(state => ({
                ...state,
                ...queryObj
            }), () => {
                if (!parsed.autoReport) {
                    this.getSessions(queryObj);
                } else {
                    this.getSessions(null, parsed.autoReport == 1);
                }
            });
        } else {
            this.getSessions(null, parsed.autoReport == 1);
        }
        this.getBillStatusCategoryOptions();
        this.getBillVersionOptions();
        this.getBillSummaryOptions();
        this.getBillEventOptions();
        this.getPatronRoles();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.executeSearch();
        }
        if (this.props.nav.session !== prevProps.nav.session) {
            if (!isNaN(parseInt(this.props.nav.session))) {
                this.sessionChange(this.props.nav.session);
            }
        }

        //get collections if user logs in/logged in user changes
        if (this.props.login.userProfile.email && this.props.login.userProfile.email !== prevProps.login.userProfile.email) {
            if (this.state.selectedSession.SessionID && this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR)) {
                this.getCollections("?SessionID=" + this.state.selectedSession.SessionID);
            } else if (this.state.collections && this.state.collections.length) {
                this.setState({ collections: [] })
            }
        }

        if (this.state.billList !== prevState.billList || this.state.showCategories !== prevState.showCategories) {
            if (window.innerWidth > 900) { //breaks mobile otherwise, and the sidebar toggle isn't shown on mobile anyways
                let height = document.getElementById('results-div');
                if (height && (height.scrollHeight / 540) !== this.state.height) {
                    this.setState({
                        height: (height.scrollHeight / 540) >= 1 ? (height.scrollHeight / 540) : 1
                    })
                } else {
                    this.setState({
                        height: 1
                    })
                }
            }
        }
    }

    executeSearch() {
        let queryObj = '';
        const parsed = queryString.parse(this.props.location.search, { decode: false });
        if (parsed.q) {
            const base64 = parsed.q;
            const decodedSearch = window.atob(base64);
            queryObj = JSON.parse(decodedSearch);
            //Remove any existing entries in the form and replace it with the content of the url
            this.clearForm();
            this.setState(state => ({
                ...state,
                ...queryObj
            }), () => {
                //If a member is already selected and there are no patron types selected then select them all by default for senators and all but sponsor and incorporated for delegates 
                if (this.state.selectedPatron && this.state.selectedPatronType.length === 0) {
                    const patronRoles = [];
                    this.state.patronRoles.filter(x => x.PatronTypeID !== 3 && x.PatronTypeID !== 5).forEach(role => patronRoles.push(role.PatronTypeID));
                    this.setState({
                        selectedPatronType: patronRoles
                    });
                }
                //If a committee is already selected and there is no legislation status then select 'In Committee' as the status
                if (this.state.selectedCommittee && !this.state.selectedBillStatusCategory) {
                    const selectedBillStatusCategory = this.state.billStatusCategoryList.find(status => status.Name === 'In Committee');
                    this.setState({
                        selectedBillStatusCategory: selectedBillStatusCategory ? selectedBillStatusCategory.LegislationCategoryID : ''
                    });
                }
                this.getSessions(queryObj);
            });
        } else if (!parsed.collection) {
            //this means the user hit the back button from a query and there are no longer any params in the url, 
            //and the user should be back at the base search page with categories
            this.clearForm(true);
        }
    }

    getSessions(queryObj, autoReport) {
        let sessions = ''
        if (!this.state.sessionOptions.length) {
            sessions = this.props.actions.getSessionList()
                .then(() => {
                    this.setState({
                        sessionOptions: this.props.session.sessionList
                    })
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                });
        } else {
            sessions = () => Promise.resolve();
        }

        Promise.all([sessions]).then(() => {
            let selectedSession = '';
            let defaultSession = '';
            this.state.sessionOptions.forEach(session => {
                if (queryObj && session.SessionID === queryObj.selectedSession) {
                    selectedSession = session;
                    this.props.actions.changeSession(session.SessionCode);
                } else if (session.SessionCode === this.props.nav.session && !selectedSession) {
                    selectedSession = session;
                }
                if (session.IsDefault) {
                    defaultSession = session;
                }
            });
            //Only set the session to default if the queryObj doesn't have a sessionID and the the session selector doesn't have a sessionID
            if (!selectedSession) {
                selectedSession = defaultSession;
            }

            if (selectedSession) {
                const sessionStartObj = selectedSession.SessionEvents && selectedSession.SessionEvents.length && selectedSession.SessionEvents.find(date => date.DisplayName === "Session Start");
                const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate).format("MM/DD/YYYY") : '';
                const dateParam = 'effectiveDate=' + encodeURIComponent(startDate);
                const sessionParam = 'sessionID=' + selectedSession.SessionID;
                this.getPatrons(sessionParam);
                this.getSubjects(sessionParam);
                this.getCommittees(sessionParam);
                this.getIntroDates(sessionParam)

                this.setState({ selectedSession }, () => {
                    if (this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR)) {
                        this.getCollections("?SessionID=" + this.state.selectedSession.SessionID);
                        if (autoReport) {
                            window.addEventListener("beforeunload", this.unload);
                            this.toggleReportMaker();
                            this.setState({
                                autoReport: true
                            })
                        }
                    }
                    if (queryObj && !queryObj.sessionOnly) {
                        this.setState({ billList: [], fullLegislationList: [], skippedRecords: 0 }, () => {
                            this.handleSearch()
                        })
                    } else {
                        this.setState({ showCategories: true })
                    }

                    document.querySelector('body').addEventListener('keydown', this.searchOnEnterPress);
                })
            } else {
                this.setState({ failedToGetSessions: true })
            }
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    componentWillUnmount() {
        document.querySelector('body').removeEventListener('keydown', this.searchOnEnterPress);
        cancelRequest();
        //Handle leaving the page when we arrived here from the Create Report button in the calendar        
        if (this.state.autoReport && this.state.selectedCollection != '' && this.state.showReportMaker) {
            window.removeEventListener("beforeunload", this.unload);
        }
    }

    searchOnEnterPress(event) {
        if (event.key === 'Enter' && !this.state.isFetching) {
            //Pressing enter while on a react-select will select an option. A search should not happen because of that.
            if (!event.target.id.includes('react-select') && !event.target.className.includes('note-area') && !event.target.className.includes('quick-search') && event.target.id !== "userId" && event.target.id !== "userPassword" && event.target.id !== "report-personal-message") {
                if (!this.state.selectedChamber && !this.state.selectedBillEvent && !this.state.selectedBillType && !this.state.selectedCommittee && !this.state.selectedSubcommittee && !this.state.selectedPatron && !this.state.selectedSubject && !this.state.selectedKeywords && !this.state.selectedBillNumbers && !this.state.selectedBillRangeBeginning && !this.state.selectedBillRangeEnd && !this.state.selectedSubject && !this.state.selectedChapterNumber && !this.state.selectedBillStatusCategory) {
                    //there are not any search parameters provided, don't execute a search
                    return;
                }
                this.searchBox.current.handleSubmit();
            }
        }
    }

    toggleShowCategories(val) {
        this.setState({
            showCategories: val
        }, () => {
            if (this.state.showCategories) {
                this.props.history.push("/bill-search");
            }
        });
    }

    toggleGroup(groupName) {
        this.setState(state => ({
            [groupName]: !state[groupName]
        }));
    }

    toggleShowData(billID, bool) {
        if (bool === undefined) {
            this.setState(prevState => ({
                [billID]: !prevState[billID]
            }));
        } else {
            //not just toggling, we're explicitly setting (e.g. if searching by keyword, and a bill has been expanded, if the keyword search location changes to bill text,
            //don't just toggle all (which would reset that bill to false), set them all to true)
            this.setState({
                [billID]: bool
            })
        }
    }

    clearForm(clearUrl, clearBillData) {
        this.setState({
            selectedBillEvent: '',
            selectedBill: '',
            selectedKeywords: '',
            searchedKeywords: '',
            selectedBillAction: '',
            billEventStartDate: null,
            billEventEndDate: null,
            selectedBillSession: '',
            selectedBillType: '',
            selectedBillNumbers: '',
            selectedBillRangeBeginning: '',
            selectedBillRangeEnd: '',
            selectedChapterNumber: '',
            subcommitteeList: [],
            selectedPatron: '',
            selectedPatronType: '',
            selectedChamber: '',
            selectedBillStatusCategory: '',
            billStatusStartDate: null,
            billStatusEndDate: null,
            selectedCommittee: '',
            selectedSubcommittee: '',
            selectedSubject: '',
            isPending: null,
            mostFrequent: null,
            allLegislation: null,
            excludeFailed: false,
            selectedBillVersion: 'All',
            selectedSummaryVersion: 'All',
            selectedLocation: 'Caption',
            displaySelectedLocation: "Caption",
            introductionDate: null
        }, () => {
            if (clearUrl) {
                this.props.history.push('/bill-search');
                this.setState({ billList: [], fullLegislationList: [], skippedRecords: 0, showCategories: true })
            }
            if (clearBillData) {
                this.setState({
                    billList: []
                })
            }
        });

    }

    handleSubmit(queryObj) {
        let encodedSearch = window.btoa(JSON.stringify(queryObj));
        //if it is the same search (i.e. same parameters) as the current one, updating the url won't work since it will be the same, just execute it manually
        const parsed = queryString.parse(this.props.location.search, { decode: false });
        if (parsed.q && parsed.q === encodedSearch) {
            this.executeSearch();
        } else {
            this.props.history.push('/bill-search?q=' + encodedSearch + (this.state.selectedCollection ? '&collection=' + this.state.selectedCollection.WatchListID : ''));
        }
    }

    handleSearch(skipIdsCall) {
        if (this.state.failedToGetSessions) { return; }
        //Format the selected options to be sent to the API
        //Add bill range to list of bills
        let selectedBillNumbers = this.state.selectedBillNumbers.trim();
        if (this.state.selectedBillRangeBeginning && this.state.selectedBillRangeEnd) {
            selectedBillNumbers = selectedBillNumbers ? selectedBillNumbers + ',' : selectedBillNumbers;
            selectedBillNumbers += this.state.selectedBillRangeBeginning + '-' + this.state.selectedBillRangeEnd;
        }
        let keywordVersion = null;
        switch (this.state.selectedLocation) {
            case ('Bill Text'):
                if (this.state.selectedBillVersion !== 'All') {
                    keywordVersion = { KeywordLegislationVersionID: this.state.selectedBillVersion }
                }
                break;
            case ('Summary'):
                if (this.state.selectedSummaryVersion !== 'All') {
                    keywordVersion = { KeywordSummaryVersionID: this.state.selectedSummaryVersion }
                }
                break;
        }

        // Build the param array with param names as the keys
        const params = {
            ChamberCode: this.state.selectedChamber ? this.state.selectedChamber.value : null,
            CommitteeID: this.state.selectedSubcommittee || this.state.selectedCommittee || null,
            PatronTypes: this.state.selectedPatronType || null,
            LegislationNumbers: selectedBillNumbers ? [{ LegislationNumber: selectedBillNumbers.replaceAll(" ", "") }] : null,
            ChapterNumber: this.state.selectedChapterNumber,
            SessionID: this.state.keywordUseGlobalSessionSearch ? null : this.state.selectedSession.SessionID,
            StartDate: this.state.billStatusStartDate ? moment(this.state.billStatusStartDate).format('MM/DD/YYYY') : null,
            EndDate: this.state.billStatusEndDate ? moment(this.state.billStatusEndDate).format() : null,
            KeywordExpression: this.state.selectedKeywords || null,
            KeywordLocation: this.state.selectedLocation || null,
            ...keywordVersion,
            MemberID: this.state.selectedPatron,
            SubjectIndexID: this.state.selectedSubject,
            LegislationCategoryID: this.state.selectedBillStatusCategory,
            LegislationEventTypeID: this.state.selectedBillEvent,
            EventStartDate: this.state.billEventStartDate ? moment(this.state.billEventStartDate).format("MM/DD/YYYY") : null,
            EventEndDate: this.state.billEventEndDate ? moment(this.state.billEventEndDate).format("MM/DD/YYYY") : null,
            IsPending: this.state.isPending || null,
            MostFrequent: this.state.mostFrequent || null,
            AllLegislation: this.state.allLegislation || null,
            KeywordUseGlobalSessionSearch: this.state.keywordUseGlobalSessionSearch,
            CurrentStatus: this.state.currentStatus,
            ExcludeFailed: this.state.excludeFailed,
            IntroductionDate: this.state.introductionDate
        }
        //Open the fieldset if one of the form inputs is filled out
        if (params.LegislationNumbers) {
            this.setState({
                billFieldset: true
            });
        }
        if (params.KeywordExpression) {
            this.setState({
                keywordFieldset: true
            });
        }
        if (params.LegislationCategoryID || params.ChamberCode) {
            this.setState({
                statusFieldset: true
            });
        }
        if (params.EventStartDate || params.EventEndDate) {
            this.setState({
                activityFieldset: true
            });
        }
        if (params.MemberID) {
            this.setState({
                membersFieldset: true
            });
        }
        if (params.CommitteeID) {
            this.setState({
                committeesFieldset: true
            });
        }
        if (params.SubjectIndexID) {
            this.setState({
                subjectFieldset: true
            });
        }

        this.setState({
            searchMade: true,
            displaySelectedLocation: this.state.selectedLocation,
            showUpdateSearchButton: false
        });

        //use existing search params if the user has scrolled to the next page (i.e. ignore any possible changes to the params on the side)
        this.getBillsResults(skipIdsCall && this.state.params ? this.state.params : params, skipIdsCall);
    }

    getBillsResults(params, skipIdsCall) {

        //if the user searches hb1-hb4 and expands all of these bills to see further info, and then search hb1-hb5, leave open their previously expanded bills for them;
        //however, if the existing search (if there is one, i.e. this new one is not the first search they're making) is a bill text keyword search, which automatically expands all of them in order to show bill text matches,
        //then close these in the new search results as there's a good chance they don't care for the expanded info regarding patrons/summary/etc
        const closeBillInfo = this.state.params?.KeywordLocation === "Bill Text" && this.state.params?.KeywordExpression && (params?.KeywordLocation !== "Bill Text" || !params?.KeywordExpression);

        this.setState({
            isFetching: true,
            showCategories: false,
            searchedKeywords: params.KeywordExpression //without this, if the user edits the keyword in the adv search box but does not submit, the existing bill version hit count links will nonetheless use the new value and not work
        })

        if (!this.state.skippedRecords) {
            window.scrollTo(0, 0);
        }

        let getResultsPromise;
        let hasResults = true;
        let numSearchedIds;
        if (params.MostFrequent) {
            getResultsPromise = this.props.actions.getMostFrequentBillList("?sessionID=" + params.SessionID + "&excludeFailed=" + params.ExcludeFailed);
        } else {
            //break this up into three calls: 1) get the legislation numbers (i.e. 'skinny list') of all results, 2) get the full list of objects with pagination, 3) get the hit counts if it's a billtext keyword search
            //we need the skinny list to know all the legislation numbers for creating reports, but don't want to get all the data for each yet (i.e. paginate)
            if (skipIdsCall) {
                const paginatedLegislationIds = [...this.state.fullLegislationList].slice(this.state.skippedRecords, this.state.skippedRecords + PageSize);
                getResultsPromise = this.props.actions.getBillListByIds({ "LegislationIds": paginatedLegislationIds });
                numSearchedIds = this.state.numSearchedIds + paginatedLegislationIds.length;
            } else {
                getResultsPromise = this.props.actions.getLegislationIdsList(params).then(() => {
                    let legislationIdsResponse = { ... this.props.bills.legislationIdsList };
                    //Filter to only one result per bill per session since DB sometimes returns duplicates which messes up pagination
                    legislationIdsResponse.LegislationIds = legislationIdsResponse.LegislationIds.filter((item, pos) => legislationIdsResponse.LegislationIds.findIndex(i => i.LegislationNumber === item.LegislationNumber && i.SessionID === item.SessionID) === pos);
                    this.setState({ fullLegislationList: legislationIdsResponse.LegislationIds })
                    const paginatedLegislationIds = [...legislationIdsResponse.LegislationIds].slice(0, PageSize);
                    numSearchedIds = paginatedLegislationIds.length;
                    if (paginatedLegislationIds.length) {
                        return this.props.actions.getBillListByIds({ "LegislationIds": paginatedLegislationIds });
                    } else {
                        hasResults = false;
                        return Promise.resolve();
                    }
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        isFetching: false
                    });
                })
            }
        }

        getResultsPromise.then(() => {
            let billList;
            if (!params.MostFrequent && this.state.skippedRecords) {
                billList = [...this.state.billList.concat(hasResults ? this.props.bills.billListByIds : [])];
            } else if (!params.MostFrequent) {
                billList = hasResults ? [...this.props.bills.billListByIds] : [];
            } else {
                billList = [...this.props.bills.mostFrequentBillList];
            }
            //Filter to only one result per bill per session since DB sometimes returns duplicates which messes up pagination
            billList = billList.filter((item, pos) => billList.findIndex(i => i.LegislationNumber === item.LegislationNumber && i.SessionID === item.SessionID) === pos);
            this.setState({
                params: params,
                billList: billList,
                numSearchedIds,
                totalResults: billList && billList.length ? params.MostFrequent ? billList.length : this.state.fullLegislationList.length : 0, //if we got IDs back but no full legislation object results, display '0 Results' since none are actually being shown. Example of this is when you search a bill number with cross session turned on
                hasNextPage: numSearchedIds && this.state.fullLegislationList.length > numSearchedIds ? true : false,
                isFetching: false,
                checkedBills: !params.MostFrequent && this.state.skippedRecords ? this.state.checkedBills : [],
                lastCheckedBill: !params.MostFrequent && this.state.skippedRecords ? this.state.lastCheckedBill : -1,
                selectAllBills: false,
                displayCrossSession: this.state.keywordUseGlobalSessionSearch
            }, () => {
                if (closeBillInfo)
                    [...this.state.billList].forEach(b => this.toggleShowData(b.LegislationID, false))
                const options = {
                    root: null,
                    rootMargin: "0px",
                    threshold: 1.0
                };

                this.observer = new IntersectionObserver(
                    this.handleObserver.bind(this),
                    options
                );
                this.observer.observe(this.loadingRef);

                //if bill text search, get hit counts
                if (params.KeywordLocation === 'Bill Text' && params.KeywordExpression && hasResults) {
                    const LOADING = 'LOADING';
                    const legislationTextIDs = [...this.props.bills.billListByIds].map(bill => bill.SearchText && bill.SearchText.map(text => { text.countMatches = LOADING; return { "LegislationTextID": text.LegislationTextID } })).flat();
                    let hitCountRequestBody = {
                        "Documents": legislationTextIDs,
                        "Location": "Bill Text",
                        "KeywordExpression": params.KeywordExpression
                    };

                    this.props.actions.getLegislationTextsHitCounts(hitCountRequestBody).then(() => {
                        const hitCounts = [...this.props.bills.legislationTextsHitCounts];
                        billList = [...this.state.billList];
                        hitCounts.forEach(hitCount => {
                            const associatedBillSearchTextIndexes = billList.reduce((r, b, i) => r.concat(b.SearchText && b.SearchText.find(st => st.LegislationTextID === hitCount.LegislationTextID) ? i : []), []);
                            associatedBillSearchTextIndexes.forEach(index => {
                                billList[index].SearchText.find(st => st.LegislationTextID === hitCount.LegislationTextID).countMatches = hitCount.HitCount;
                            })
                        })
                        billList.filter(bill => bill.SearchText && bill.SearchText.find(st => st.countMatches === LOADING)).forEach(bill => {
                            bill.SearchText.filter(st => st.countMatches === LOADING).forEach(st => { st.countMatches = null })
                        })

                        this.setState({ billList })
                    }).catch(err => {
                        if (err === 'Aborted') {
                            return;
                        }
                        this.setState({
                            isFetching: false
                        });
                    })
                }
            })
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            this.setState({
                isFetching: false
            });
        })
    }

    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        if (!this.state.showCategories && this.state.billList.length && this.state.hasNextPage && !this.state.isFetching && this.state.prevY > y) {
            this.setState({ skippedRecords: this.state.numSearchedIds }, () => {
                if (!this.state.selectedCollection || this.state.searchMade) {
                    this.handleSearch(true);
                } else {
                    const bills = [...this.state.selectedCollection.WatchListLegislation].filter(l => !l.DeletionDate).map(l => l.LegislationID);
                    if (bills.length > 0) {
                        let legislationIds = [];
                        for (let i = 0; i < bills.length; i++) {
                            legislationIds.push({ LegislationId: bills[i] });
                        }

                        const params = {
                            LegislationIDs: legislationIds,
                            SessionID: this.state.selectedSession.SessionID
                        }

                        this.setState({ searchMade: false }, () => {
                            this.getBillsResults(params, true);
                        })
                    }
                }
            });
        }
        this.setState({ prevY: y });
    }

    getBillStatusCategoryOptions() {
        // Get Bill Status references for bill status typeahead
        this.props.actions.getBillStatusCategoryReferences().then(() => {
            let billStatusReferences = [...this.props.bills.billStatusCategoryReferences];
            billStatusReferences.forEach(ref => {
                ref.label = ref.Name
                ref.value = ref.LegislationCategoryID
            });
            if (this.state.selectedCommittee && !this.state.selectedBillStatusCategory) {
                const selectedBillStatusCategory = billStatusReferences.find(status => status.Name === 'In Committee');
                this.setState({
                    selectedBillStatusCategory: selectedBillStatusCategory ? selectedBillStatusCategory.LegislationCategoryID : ''
                });
            }
            this.setState({
                billStatusCategoryList: billStatusReferences,
                billStatusCategoryListIsLoading: false
            });
        });
    }

    getBillVersionOptions() {
        this.props.actions.getBillVersionRef().then(() => {
            let topOfList = [{ Name: 'All Versions', LegislationVersionID: 'All' }];
            let billVersionReferences = topOfList.concat(this.props.bills.billVersionRef);
            billVersionReferences.forEach(ref => {
                ref.label = ref.Name
                ref.value = ref.LegislationVersionID
            });
            this.setState({
                billVersionList: billVersionReferences,
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
        });
    }

    getBillSummaryOptions() {
        this.props.actions.getBillSummaryRef().then(() => {
            let topOfList = [{ Name: 'All Summary Versions', SummaryVersionID: 'All' }];
            let billSummaryReferences = topOfList.concat(this.props.bills.billSummaryRef);
            billSummaryReferences.forEach(ref => {
                ref.label = ref.Name
                ref.value = ref.SummaryVersionID
            });
            this.setState({
                billSummaryList: billSummaryReferences,
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
        });
    }

    getBillEventOptions() {
        this.props.actions.getBillEventReferences("?isSearchable=true&isActive=true").then(() => {
            let billEventReferences = [...this.props.bills.billEventRef];
            billEventReferences.forEach(ref => {
                ref.label = ref.LegislationDescription
                ref.value = `${ref.EventCode}-${ref.IsPassed}`
            });
            this.setState({
                billEventOptions: billEventReferences
            });
        });
    }

    getPatrons(sessionParam) {
        // Get Patrons list for typeahead
        this.props.actions.getMemberList(sessionParam).then(() => {
            let memberList = [...this.props.members.memberList];
            memberList.forEach(patron => {
                patron.label = patron.ListDisplayName + ' ' + '(' + patron.ChamberCode + ')';
                patron.value = patron.MemberID;
            });
            const member = memberList.find(member => member.MemberID === this.state.selectedPatron);
            let selectedPatronType = [];
            if (member && this.state.selectedPatronType.length === 0) {
                this.state.patronRoles.filter(x => x.PatronTypeID !== 3 && x.PatronTypeID !== 5).forEach(role => selectedPatronType.push(role.PatronTypeID));
                this.setState({
                    selectedPatronType: selectedPatronType
                });
            }
            this.setState({
                patronList: memberList,
                filteredPatronList: memberList,
            });
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    getSubjects(sessionParam) {
        this.props.actions.getSubjectList('?' + sessionParam)
            .then(() => {
                this.setState({
                    subjectList: this.props.bills.subjectList
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    getPatronRoles() {
        this.props.actions.getPatronRoles()
            .then(() => {
                const patronRoles = [...this.props.patrons.patronRoles];
                patronRoles.forEach(role => {
                    role.label = role.Name;
                    role.value = role.PatronTypeID;
                });
                this.setState({
                    patronRoles: patronRoles,
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    getCommittees(sessionParam) {
        this.props.actions.getCommitteeList(sessionParam).then(() => {
            let committeeList = [...this.props.committee.committeeList];
            committeeList.forEach(committee => {
                committee.label = committee.Name;
                committee.value = committee.CommitteeID;
            });
            let groupedCommitteeList = [{ "label": "House Committees", "ChamberCode": "H", "options": committeeList.filter(c => c.ChamberCode === "H") }, { "label": "Senate Committees", "ChamberCode": "S", "options": committeeList.filter(c => c.ChamberCode === "S") }]
            this.setState({
                committeeList: committeeList.map(c => ({ ...c, label: c.label + ' ' + '(' + c.ChamberCode + ')' })),
                groupedCommitteeList: groupedCommitteeList,
                filteredCommitteeList: groupedCommitteeList
            });
            //If a committee is already selected then that committee's subcommittees need to be retrieved
            if (this.state.selectedCommittee) {
                this.getSubcommittees(this.state.selectedCommittee);
            }
        }).catch(err => {
            if (err === 'Aborted') {
                return;
            }
            console.log(err)
        });
    }

    getSubcommittees(id) {
        this.props.actions.getCommitteeList("parentCommitteeID=" + id)
            .then(() => {
                let committeeList = [...this.props.committee.committeeList];
                committeeList.forEach(committee => {
                    committee.label = '(' + committee.ChamberCode + ') ' + committee.Name;
                    committee.value = committee.CommitteeID;
                });
                this.setState({
                    subcommitteeList: committeeList
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    getIntroDates(sessionParam) {
        this.props.actions.getIntroDateList('?' + sessionParam)
            .then(() => {
                let datesList = [];
                this.props.bills.introDateList.forEach(date => datesList.push(moment(date.IntroductionDate)));
                this.setState({
                    introDateList: datesList
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    sessionChange(code) {
        const selectedSession = this.state.sessionOptions.find(session => session.SessionCode === code);
        if (!selectedSession) {
            return;
        }
        const sessionParam = 'sessionID=' + selectedSession.SessionID;
        this.getPatrons(sessionParam);
        this.getSubjects(sessionParam);
        this.getCommittees(sessionParam);
        this.getIntroDates(sessionParam);
        this.getCollections("?SessionID=" + selectedSession.SessionID);

        const parsed = queryString.parse(this.props.location.search, { decode: false });
        if (parsed.q) {
            const base64 = parsed.q;
            const decodedSearch = window.atob(base64);
            let queryObj = JSON.parse(decodedSearch);
            if (queryObj.selectedSession) {
                queryObj.selectedSession = selectedSession.SessionID;
                this.handleSubmit(queryObj);
                return;
            }
        } else {
            this.setState({ currentStatus: selectedSession.IsActive })
        }

        if (this.state.selectedBillStatusCategory) {
            this.setState({
                selectedPatron: '',
                selectedPatronType: '',
                selectedCommittee: '',
                selectedSubcommittee: '',
                selectedSubject: '',
                selectedSession: selectedSession,
                billList: [],
                fullLegislationList: [],
                skippedRecords: 0
            }, () => {
                this.handleSearch();
            });
        } else {
            this.setState({
                selectedPatron: '',
                selectedPatronType: '',
                selectedCommittee: '',
                selectedSubcommittee: '',
                selectedSubject: '',
                selectedSession: selectedSession,
                billList: [],
                fullLegislationList: [],
                skippedRecords: 0
            }, () => {
                const parsed = queryString.parse(this.props.location.search, { decode: false });
                if (parsed.q) {
                    this.handleSearch();
                }
            });
        }
    }

    chamberChange(value) {
        this.setState({
            selectedChamber: value
        });
        if (value) {
            const patronList = [...this.state.patronList];
            const committeeList = [...this.state.groupedCommitteeList];
            //Remove the patrons from the patronList that do not fit the current selected chamber
            //Remove the committees from the committee list that do not fit the current selected chamber
            let filteredPatronList = patronList.filter(patron => patron.ChamberCode === value.value);
            let filteredCommitteeList = committeeList.filter(committee => committee.ChamberCode === value.value);
            this.setState({
                filteredPatronList: filteredPatronList,
                filteredCommitteeList: filteredCommitteeList,
            });
        } else {
            // 'all' has been selected so show all patrons/committees
            this.setState({
                filteredPatronList: this.state.patronList,
                filteredCommitteeList: this.state.groupedCommitteeList,
            });
        }
        // Reset all the values that are chamber specific
        this.setState({
            selectedPatron: '',
            selectedPatronType: '',
            selectedCommittee: '',
            selectedSubcommittee: ''
        });
    }

    committeeChange(opt) {
        this.setState({
            selectedCommittee: opt ? opt.value : null,
            selectedSubcommittee: null
        });
        if (opt) {
            if (!this.state.selectedBillStatusCategory) {
                const selectedBillStatusCategory = this.state.billStatusCategoryList.find(status => status.Name === 'In Committee');
                this.setState({
                    selectedBillStatusCategory: selectedBillStatusCategory ? selectedBillStatusCategory.LegislationCategoryID : ''
                });
            }
            this.getSubcommittees(opt.CommitteeID)
        };
    }

    handlePatronChange(opt) {
        let patronTypes = [];
        if (opt) {
            this.state.patronRoles.filter(x => x.PatronTypeID !== 3 && x.PatronTypeID !== 5).forEach(role => patronTypes.push(role.PatronTypeID));
        }
        this.setState({
            selectedPatron: opt ? opt.value : null,
            selectedPatronType: patronTypes
        });
    }

    handlePatronTypeChange(options) {
        let values = [];
        if (options) {
            options.forEach(opt => values.push(opt.value));
        }
        this.setState({
            selectedPatronType: values
        });
    }

    handleSelectChange = (opt, name) => {
        this.setState({
            [name]: opt ? opt.value : null
        });
        if (name === 'selectedBillStatusCategory') {
            if (opt === null || (opt && [2, 3, 4, 6, 7, 11, 22, 16, 17, 21, 27].includes(opt.LegislationCategoryID))) {
                this.setState({
                    currentStatus: true,
                    billStatusStartDate: null,
                    billStatusEndDate: null
                });
            } else if ([1, 5, 23, 24, 18, 26, 29, 8, 9, 10, 19, 14, 20, 25, 13, 15, 28].includes(opt.LegislationCategoryID)) {
                this.setState({
                    currentStatus: false
                });
            }
        }
    }

    handleDateChange = (val, name) => {
        this.setState({
            [name]: val
        });
    }

    handleTextChange = (evt, name) => {
        let value = evt.target.value;
        if (['selectedChapterNumber', 'selectedBillRangeBeginning', 'selectedBillRangeEnd'].includes(name)) {
            value = value.replace(/[^a-zA-Z0-9]/, "");
        }
        this.setState({ [name]: value });
    }

    handleSelectionClear(key) {
        //Clearing some form inputs changes the value of other form inputs. Handle them here.
        this.setState({ showUpdateSearchButton: true }, () => {
            switch (key) {
                case "selectedBillStatusCategory":
                    this.handleSelectChange(null, "selectedBillStatusCategory");
                    break;
                case "selectedPatron":
                    this.handlePatronChange(null);
                    break;
                case "selectedCommittee":
                    this.committeeChange(null);
                    break;
                case "selectedChamber":
                    this.chamberChange(null);
                    break;
                default:
                    this.setState({
                        [key]: ''
                    });
            }

            if (!localStorage.getItem('searchModalDismissed')) {
                this.toggleModal();
                localStorage.setItem('searchModalDismissed', true);
            }
        })
    }

    handleMultiSelectionClear(key, index) {
        let value = [...this.state[key]];
        value.splice(index, 1);
        this.setState({
            [key]: value,
            showUpdateSearchButton: true
        })
    }

    handleRadioChange() {
        if (window.env && window.env.HISTORICAL_DATA_REDIRECT && !this.state.keywordUseGlobalSessionSearch && !this.props.login.userClaims.roles.find(role => role === "CrossSearch")) {
            this.setState({
                historicalSessionRedirect: (redirect) => {
                    if (redirect) {
                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = `https://legacylis.virginia.gov`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    this.setState({ historicalSessionRedirect: false })
                }
            })
            return;
        }
        this.setState({
            keywordUseGlobalSessionSearch: !this.state.keywordUseGlobalSessionSearch
        }, () => {
            if (this.state.keywordUseGlobalSessionSearch) {
                this.setState({
                    excludeFailed: false
                })
            }
        })
    }

    handleCurrentStatusChange() {
        this.setState({
            currentStatus: !this.state.currentStatus
        }, () => {
            if (this.state.currentStatus) {
                this.setState({
                    billStatusStartDate: null,
                    billStatusEndDate: null
                })
            }
        })
    }

    handleExcludeFailedChange() {
        this.setState({
            excludeFailed: !this.state.excludeFailed,
            showUpdateSearchButton: true
        })
    }

    getCollections(params) {
        this.setState({
            collectionIsLoading: true
        });
        this.props.actions.getCollections(params)
            .then(() => {
                const parsed = queryString.parse(this.props.location.search);
                const collectionIndex = this.props.collection.collections.findIndex(coll => coll.WatchListID === parseInt(parsed.collection))
                this.setState({
                    collections: this.props.collection.collections,
                    collectionIsLoading: false,
                }, () => {
                    if (collectionIndex !== -1) {
                        this.selectCollection(collectionIndex)
                    }
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
                this.setState({
                    collectionIsLoading: false
                });
            });
        this.props.actions.getCriteriaTypes()
            .then(() => {
                this.setState({
                    criteriaTypes: this.props.collection.criteriaTypes
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
            });
    }

    selectCollection(collectionIndex, returningFromSearch) {
        if (collectionIndex === -1) {
            this.setState({
                selectedCollectionIndex: -1,
                quickAddErr: '',
                selectedCollection: '',
                billList: [],
                fullLegislationList: [],
                skippedRecords: 0,
                searchMade: true
            })
            this.props.history.push('/bill-search');
            this.clearForm();
        } else {
            // Remove object references so the state does not mutate
            const selectedCollection = JSON.parse(JSON.stringify(this.state.collections[collectionIndex] || ''))
            if (selectedCollection.IsPaid && !Boolean(this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) && !Boolean(this.props.login.userClaims.resources.find(resource => resource === "PaidLegislationCollectionsAuthor" || resource === "All"))) {
                alert("The watchlist you are trying to access is a paid watchlist. As a free user, you are no longer able to access this watchlist.");
                return;
            }
            const parsed = queryString.parse(this.props.location.search, { decode: false });
            let sessionOnly = false;
            if (parsed.q) {
                const base64 = parsed.q;
                const decodedSearch = window.atob(base64);
                const queryObj = JSON.parse(decodedSearch);
                sessionOnly = queryObj.sessionOnly || false;
            }
            if (returningFromSearch || !parsed.q || sessionOnly)
                this.props.history.push(`/bill-search?collection=${selectedCollection.WatchListID}`)
            this.setState({
                selectedCollectionIndex: collectionIndex,
                selectedCollection: selectedCollection,
                showCategories: false,
                billList: !returningFromSearch && parsed.q ? this.state.billList : [],
                fullLegislationList: !returningFromSearch && parsed.q ? this.state.fullLegislationList : [],
                skippedRecords: !returningFromSearch && parsed.q ? this.state.skippedRecords : 0,
                searchMade: !returningFromSearch && parsed.q ? this.state.searchMade : false,
                showAllNotes: !returningFromSearch && parsed.q ? this.state.showAllNotes : false
            });
            if (returningFromSearch || !parsed.q || sessionOnly) {
                const bills = selectedCollection.WatchListLegislation.filter(l => !l.DeletionDate).sort(sortLegislations).map(l => l.LegislationID);
                if (bills.length > 0) {
                    let legislationIds = [];
                    for (let i = 0; i < bills.length; i++) {
                        legislationIds.push({ LegislationId: bills[i] });
                    }

                    const params = {
                        LegislationIDs: legislationIds,
                        SessionID: this.state.selectedSession.SessionID
                    }
                    this.getBillsResults(params);
                }
                this.clearForm();
            }
        }
    }

    handleCollectionChange(collection, callback) {
        if (this.state.collectionIsSaving) {
            return
        }
        this.setState({
            collectionIsSaving: true
        });
        this.props.actions.saveCollections({ WatchLists: [collection] })
            .then(() => {
                let collectionSave = [...this.props.collection.collectionSave][0];
                // The api does not return WatchListLegislation or NotificationCriterias at all if it is empty. To stop it from being undefined it is set to a blank array.
                collectionSave.WatchListLegislation = collectionSave.WatchListLegislation || [];
                collectionSave.NotificationCriterias = collectionSave.NotificationCriterias || [];
                let collections = [...this.state.collections];
                const savedCollectionIndex = collections.findIndex(coll => coll.WatchListID === collectionSave.WatchListID);
                collections[savedCollectionIndex] = collectionSave;
                this.setState({
                    collectionIsSaving: false,
                    collections: collections,
                    selectedCollection: collectionSave
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                if (callback) {
                    callback(err);
                }
                this.setState({
                    collectionIsSaving: false
                });
                let message;
                if (err && JSON.parse(err)) {
                    const errorMsg = JSON.parse(err).Name;
                    message = errorMsg && errorMsg.includes('Public users are') ? errorMsg : 'Save Failed'
                } else {
                    message = "Save Failed";
                }
                this.props.actions.makeToast([{ message: message, type: "failure", long: message.includes('Public users are') }]);
            });
    }

    createCollection(collection) {
        this.setState({
            collectionIsSaving: true
        });
        this.props.actions.saveCollections({ WatchLists: [collection] }, true)
            .then(() => {
                let collections = [...this.state.collections];
                let selectedCollection = [...this.props.collection.collectionSave][0]
                // The api does not return WatchListLegislation or NotificationCriterias at all if it is empty. To stop it from being undefined it is set to a blank array.
                selectedCollection.WatchListLegislation = selectedCollection.WatchListLegislation || [];
                selectedCollection.NotificationCriterias = selectedCollection.NotificationCriterias || [];
                collections.unshift(selectedCollection);
                this.setState({
                    collections: collections,
                    collectionIsSaving: false,
                    showCategories: false
                }, () => {
                    this.selectCollection(0)
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    collectionIsSaving: false
                });
                let message;
                if (err && JSON.parse(err)) {
                    const errorMsg = JSON.parse(err).Name;
                    message = errorMsg && errorMsg.includes('Public users are') ? errorMsg : 'Save Failed'
                } else {
                    message = "Save Failed";
                }
                this.props.actions.makeToast([{ message: message, type: "failure", long: message.includes('Public users are') }]);
            });
    }

    deleteCollection() {
        this.setState({
            collectionIsSaving: true
        });
        let collection = { ...this.state.selectedCollection };
        let selectedCollectionIndex = this.state.selectedCollectionIndex;
        collection.DeletionDate = new Date();
        this.props.actions.saveCollections({ WatchLists: [collection] })
            .then(() => {
                let collections = [...this.state.collections];
                const idx = collections.findIndex(coll => coll.WatchListID === collection.WatchListID);
                if (idx > -1)
                    collections.splice(idx, 1);
                else
                    collections.splice(selectedCollectionIndex, 1);
                this.setState({
                    selectedCollection: '',
                    selectedCollectionIndex: -1,
                    collections: collections,
                    collectionIsSaving: false,
                    searchMade: true,
                    billList: [],
                    fullLegislationList: [],
                    skippedRecords: 0
                });

            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    collectionIsSaving: false,
                });
                this.props.actions.makeToast([{ message: "Delete Failed", type: "failure" }]);
            });
    }

    addToWatchlists(watchlists, merge, callback) {
        let billsToAdd = [];
        if (merge) {
            let selectedCollection = { ...this.state.selectedCollection };
            if (selectedCollection) {
                selectedCollection.WatchListLegislation.filter(l => !l.DeletionDate).forEach(leg => { billsToAdd.push(leg) })
            } else {
                return;
            }
        } else {
            this.state.checkedBills.forEach((c, i) => {
                if (c) {
                    billsToAdd.push(this.state.billList[i])
                }
            });
        }
        let duplicatedBills = [];
        watchlists.forEach(watchlist => {
            billsToAdd.forEach(bill => {
                if (watchlist.__isNew__) {
                    watchlist.Name = watchlist.label;
                    watchlist.Description = `Created on ${moment().format('MM/DD/YYYY')}`;
                    watchlist.WatchListLegislation = [];
                    delete watchlist.label;
                    delete watchlist.__isNew__;
                    delete watchlist.value;
                }
                // Make sure the bill isn't already a part of the watchlist
                if (!watchlist.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID) || watchlist.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID).DeletionDate) {
                    watchlist.WatchListLegislation.push({
                        LegislationID: bill.LegislationID,
                        LegislationNumber: bill.LegislationNumber,
                        Sessions: [{ SessionID: bill.SessionID }],
                        WatchListID: watchlist.WatchListID,
                        Note: merge ? bill.Note : null
                    });
                } else if (merge && bill.Note && !watchlist.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID).Note) { //merge note if there is not already one in the target watchlist for that bill
                    const leg = watchlist.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID);
                    leg.Note = bill.Note;
                } else {
                    duplicatedBills.push({ "Watchlist": watchlist.Name, "LegislationNumber": bill.LegislationNumber });
                }
            });
        })

        this.setState({
            collectionIsSaving: true
        }, () => {
            let collections = [...this.state.collections];
            this.props.actions.saveCollections({ WatchLists: watchlists })
                .then(() => {
                    let watchlistSave = [...this.props.collection.collectionSave];
                    let savedCollectionIndex;
                    watchlistSave.forEach(res => {
                        if (!collections.find(coll => coll.WatchListID === res.WatchListID)) {
                            collections.unshift(res);
                            savedCollectionIndex = 0;
                        } else {
                            savedCollectionIndex = collections.findIndex(coll => coll.WatchListID === res.WatchListID);
                            collections[savedCollectionIndex] = res;
                        }
                        res.WatchListLegislation = res.WatchListLegislation || [];
                        res.NotificationCriterias = res.NotificationCriterias || [];
                    })

                    this.setState({
                        collectionIsSaving: false,
                        collections: collections,
                        selectedCollection: watchlists.length === 1 && !merge ? collections[savedCollectionIndex] : this.state.selectedCollection,
                        selectedCollectionIndex: watchlists.length === 1 && !merge ? savedCollectionIndex : this.state.selectedCollectionIndex
                    });
                    if (duplicatedBills.length && !merge) {
                        this.props.actions.makeToast([{ message: (new Set(duplicatedBills.map(b => b.LegislationNumber)).size === 1 ? (duplicatedBills[0].LegislationNumber + " is ") : "Multiple added bills were ") + "already in " + (watchlists.length === 1 ? "this watchlist. " : "one or more of these watchlists. ") + (watchlists.length === 1 ? ((billsToAdd.length - duplicatedBills.length) + " bills were added to your watchlist " + watchlists[0].Name) : "All other bills were added."), type: "warning", long: true }]);
                    } else if (!merge) {
                        this.props.actions.makeToast([{ message: (billsToAdd.length === 1 ? billsToAdd[0].LegislationNumber + " was " : billsToAdd.length + " bills were ") + "added to your watchlist" + (watchlists.length > 1 ? "s" : (" " + watchlists[0].Name)), type: "success" }]);
                    } else {
                        this.props.actions.makeToast([{ message: "Watchlists have successfully been merged", type: "success" }]);
                    }
                    if (callback) {
                        callback(collections)
                    }
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                    this.setState({
                        collectionIsSaving: false
                    });
                    let message;
                    if (err && JSON.parse(err)) {
                        const errorMsg = JSON.parse(err).Name;
                        message = errorMsg && errorMsg.includes('Public users are') ? errorMsg : 'Save Failed'
                    } else {
                        message = "Save Failed";
                    }
                    this.props.actions.makeToast([{ message: message, type: "failure", long: message.includes('Public users are') }]);
                })
        });
    }

    removeWatchlistBills() {
        let selectedCollection = { ...this.state.selectedCollection };
        let billList = [...this.state.billList];
        let fullLegislationList = [...this.state.fullLegislationList];
        let removedBillCount = 0;
        this.state.checkedBills.forEach((_cb, cbIndex) => {
            if (_cb) { //sometimes the value will be undefined, don't use those
                const legislationIndex = selectedCollection.WatchListLegislation.findIndex(leg => !leg.DeletionDate && leg.LegislationID === this.state.billList[cbIndex].LegislationID);
                if (legislationIndex !== -1) {
                    selectedCollection.WatchListLegislation[legislationIndex].DeletionDate = new Date().toISOString();
                    billList.splice(cbIndex - removedBillCount, 1);
                    fullLegislationList.splice(cbIndex - removedBillCount, 1);
                    removedBillCount++; //need to know how many we've removed since the index for billList won't be accurate anymore the next time through
                }
            }
        });
        this.setState({
            checkedBills: [],
            billList: billList,
            fullLegislationList: fullLegislationList,
            totalResults: billList.length
        });
        this.handleCollectionChange(selectedCollection);
    }

    toggleReportMaker(autoReport) {
        this.setState(state => ({
            showReportMaker: !state.showReportMaker,
        }), () => {
            //Delete the watchlist if we arrived at this report from the interactive calendar Create Report button
            //Should only be triggered by closing a report maker that was opened automatically
            if (autoReport) {
                this.deleteCollection();
            }
        });
    }

    toggleAllBills() {
        const opposite = !this.state.selectAllBills;
        let checkedBills = [...this.state.checkedBills];
        checkedBills = new Array(this.state.billList.length).fill(opposite);
        this.setState({
            selectAllBills: opposite,
            checkedBills: checkedBills,
            lastCheckedBill: -1
        });
    }

    handleBillCheckbox(billIndex, e) {
        let checkedBills = [...this.state.checkedBills];
        let lastCheckedBill = this.state.lastCheckedBill;
        if (e.nativeEvent.shiftKey && this.state.lastCheckedBill !== -1) {
            checkedBills = checkedBills.fill(false);
            for (let i = 0; i < Math.abs(billIndex - this.state.lastCheckedBill); i++) {
                const index = this.state.lastCheckedBill + i * (billIndex - this.state.lastCheckedBill) / Math.abs(billIndex - this.state.lastCheckedBill);
                checkedBills[index] = true;
            }
        } else {
            lastCheckedBill = !checkedBills[billIndex] ? billIndex : this.state.lastCheckedBill;
        }
        checkedBills[billIndex] = !checkedBills[billIndex]
        this.setState({
            checkedBills: checkedBills,
            lastCheckedBill: lastCheckedBill
        });
    }

    quickAddBills(billsToAdd, callback) {
        let billsToSearchFor = "";
        let duplicatedBills = [];
        let ranges = [];
        let exitFn = false;
        this.setState({ quickAddErr: null });
        if (this.state.selectedCollection.WatchListLegislation && this.state.selectedCollection.WatchListLegislation.filter(l => !l.DeletionDate).length > 0) {
            const existingWatchListLegislation = [...new Set(this.state.selectedCollection.WatchListLegislation.filter(l => !l.DeletionDate).map(l => l.LegislationNumber.toUpperCase()))];
            billsToSearchFor += existingWatchListLegislation.join(", ");
            let billsToAddArray = billsToAdd.split(",");
            billsToAddArray.every(b => {
                if (b.includes('-')) { //range (e.g. hb5-hb10)
                    const rangeStartAndEnd = b.split('-'); // e.g. ['hb5', 'hb10']
                    const rangeStart = rangeStartAndEnd[0]; // e.g. 'hb5'
                    const rangeStartNumber = Number.parseInt(rangeStart.replace(/^\D+/g, '')); // e.g. 5
                    const rangeEnd = rangeStartAndEnd[1];
                    const rangeEndNumber = Number.parseInt(rangeEnd.replace(/^\D+/g, '')); // e.g. 10
                    const prefix = rangeStart.replace(/\d+/, "").trim().toUpperCase();

                    if (!prefix || !validLegPrefixes.includes(prefix) || !rangeStartNumber || !rangeEndNumber || Number.isNaN(rangeStartNumber) || Number.isNaN(rangeEndNumber) || rangeStartNumber > rangeEndNumber) {
                        this.setState({ quickAddErr: 'Invalid range. No bills added.' }); //return this message to the user
                        callback(true);
                        exitFn = true; //can't quit the outer function from within every(), forEach(), etc., so store as bool to use later
                        return false; //exit the every()
                    }
                    for (let i = rangeStartNumber; i <= rangeEndNumber; i++) {
                        const billNumber = prefix + i.toString().trim().toUpperCase();
                        if (!existingWatchListLegislation.includes(billNumber)) {
                            billsToSearchFor += ", " + billNumber;
                        } else {
                            duplicatedBills.push(billNumber);
                        }
                    }
                    ranges.push({ prefix: prefix, rangeStart: rangeStartNumber, rangeEnd: rangeEndNumber })
                } else { //individual
                    const billKey = Number.parseInt(b.replace(/^\D+/g, '')); // e.g. 5
                    if (!billKey) {
                        this.setState({ quickAddErr: 'Invalid bill number: ' + b.trim() + ' (No bills added)' }); //return this message to the user
                        callback(true);
                        exitFn = true; //can't quit the outer function from within every(), forEach(), etc., so store as bool to use later
                        return false; //exit the every()
                    }

                    const prefix = b.replace(/\d+/, "").trim().toUpperCase();
                    if (!validLegPrefixes.includes(prefix)) {
                        this.setState({ quickAddErr: 'Invalid bill prefix: ' + prefix + ' (No bills added)' });
                        callback(true);
                        exitFn = true;
                        return false;
                    }

                    if (!existingWatchListLegislation.includes(b.trim().toUpperCase())) {
                        billsToSearchFor += ", " + b.trim();
                    } else {
                        duplicatedBills.push(b.trim().toUpperCase())
                    }
                }
                return true;
            })
        } else { //no legislation currently in the existing watchlist
            if (billsToAdd.includes('-')) { //includes at least one range (e.g. hb5-hb10)
                let billsToAddArray = billsToAdd.split(",");
                billsToAddArray.every(b => {
                    if (b.includes('-')) { //range (e.g. hb5-hb10)    
                        const rangeStartAndEnd = b.split('-'); // e.g. ['hb5', 'hb10']
                        const rangeStart = rangeStartAndEnd[0]; // e.g. 'hb5'
                        const rangeStartNumber = Number.parseInt(rangeStart.replace(/^\D+/g, '')); // e.g. 5
                        const rangeEnd = rangeStartAndEnd[1];
                        const rangeEndNumber = Number.parseInt(rangeEnd.replace(/^\D+/g, '')); // e.g. 10
                        const prefix = rangeStart.replace(/\d+/, "").trim().toUpperCase();
                        if (!prefix || !validLegPrefixes.includes(prefix) || !rangeStartNumber || !rangeEndNumber || Number.isNaN(rangeStartNumber) || Number.isNaN(rangeEndNumber) || rangeStartNumber > rangeEndNumber) {
                            this.setState({ quickAddErr: 'Invalid range. No bills added.' }); //return this message to the user
                            callback(true);
                            exitFn = true; //can't quit the outer function from within every(), forEach(), etc., so store as bool to use later
                            return false; //exit the every()
                        }
                        for (let i = rangeStartNumber; i <= rangeEndNumber; i++) {
                            const billNumber = prefix + i.toString().trim().toUpperCase();
                            if (billsToSearchFor.length) { billsToSearchFor += ", " }
                            billsToSearchFor += billNumber;
                        }
                        ranges.push({ prefix: prefix, rangeStart: rangeStartNumber, rangeEnd: rangeEndNumber })
                    } else {
                        billsToSearchFor += (billsToSearchFor.length ? ", " : "") + b.trim();
                    }
                    return true;
                })
            } else {
                let billsToAddArray = billsToAdd.split(",");
                billsToAddArray.every(b => {

                    const billKey = Number.parseInt(b.replace(/^\D+/g, '')); // e.g. 5
                    if (!billKey) {
                        this.setState({ quickAddErr: 'Invalid bill number: ' + b.trim() + ' (No bills added)' }); //return this message to the user
                        callback(true);
                        exitFn = true; //can't quit the outer function from within every(), forEach(), etc., so store as bool to use later
                        return false; //exit the every()
                    }

                    const prefix = b.replace(/\d+/, "").trim().toUpperCase();
                    if (!validLegPrefixes.includes(prefix)) {
                        this.setState({ quickAddErr: 'Invalid bill prefix: ' + prefix + ' (No bills added)' });
                        callback(true);
                        exitFn = true;
                        return false;
                    }

                    if (billsToSearchFor.length) { billsToSearchFor += ", " }
                    billsToSearchFor += b;
                    return true;
                })
            }
        }
        if (exitFn) { return; }
        const params = {
            LegislationNumbers: [{
                LegislationNumber: billsToSearchFor
            }],
            SessionID: this.state.selectedSession.SessionID
        };
        this.props.actions.getBillList(params).then(() => {
            let billList = this.props.bills.billList;
            //Filter to only one result per bill per session since DB sometimes returns duplicates
            billList = billList.filter((item, pos) => billList.findIndex(i => i.LegislationNumber === item.LegislationNumber && i.SessionID === item.SessionID) === pos);
            let watchlist = JSON.parse(JSON.stringify(this.state.selectedCollection));
            //Check to see if some of the bills the user wanted to add don't exist
            let missedBills = [];
            // Match text that has two letters followed by numbers. There can be white space between them.
            const foundBills = billsToSearchFor.match(/[a-zA-Z]{2}\s*(?=[0-9])[0-9]*/ig) || [];

            foundBills.forEach(fb => {
                const noWhiteSpaceBill = fb.replace(/\s/g, "").toUpperCase();
                if (!billList.find(bill => bill.LegislationNumber.toUpperCase() === noWhiteSpaceBill)) { //desired bill is not in the bill search response
                    //if the non-found bill was only requested within a range (e.g. hb120 within hb1-hb1000), then don't include it as a missed bill, because obviously not every number in a range will be expected
                    const billKey = noWhiteSpaceBill.replace(/^\D+/g, '');
                    const billPrefix = noWhiteSpaceBill.replace(/\d+/, "");
                    if (!ranges.find(range => range.prefix === billPrefix && range.rangeStart <= billKey && range.rangeEnd >= billKey)) {
                        missedBills.push(noWhiteSpaceBill);
                    }
                }
            })

            if (duplicatedBills.length) {
                this.props.actions.makeToast([{ message: (duplicatedBills.length === 1 ? (duplicatedBills[0] + " is ") : "Multiple added bills were ") + "already in this watchlist.", type: "warning" }]);
            }
            let saveWatchlist = false;
            billList.forEach(bill => {
                // Make sure the bill isn't already a part of the watchlist
                if (!watchlist.WatchListLegislation.find(leg => !leg.DeletionDate && leg.LegislationID === bill.LegislationID)) {
                    saveWatchlist = true;
                    watchlist.WatchListLegislation.push({
                        LegislationID: bill.LegislationID,
                        LegislationNumber: bill.LegislationNumber,
                        Sessions: [{ SessionID: bill.SessionID }],
                        WatchListID: watchlist.WatchListID
                    });
                }
            });
            if (saveWatchlist) {
                this.handleCollectionChange(watchlist, (err => {
                    if (!err) {
                        this.setState({
                            billList: billList,
                            fullLegislationList: billList,
                            totalResults: billList.length,
                            checkedBills: [],
                            lastCheckedBill: -1
                        })
                    }
                }));
            } else if (ranges.length) {
                throw 'No bills found'
            }
            if (missedBills.length > 0) {
                throw `${missedBills.length === 1 ? "This bill" : missedBills.length > 7 ? missedBills.length.toString() + " bills" : "These bills"} could not be added${missedBills.length <= 7 ? ': ' + missedBills.join(", ") : ""}`
            }
            callback(false);
        }).catch(err => {
            if (err === 'Aborted') {
                return err;
            }
            this.setState({
                isFetching: false
            });
            this.setState({ quickAddErr: err });
            callback(true);
        })
    }

    toggleAllNotes() {
        const opposite = !this.state.showAllNotes;
        this.setState({
            showAllNotes: opposite
        });
        for (let i = 0; i < this._billResultRefs.length; i++) {
            if (this._billResultRefs[i] && this._billResultRefs[i].current) {
                this._billResultRefs[i].current.toggleBillNotesFromParent(opposite)
            }
        }
    }

    unload() {
        //Delete the watchlist when the page is unloaded if the user leaves when the report maker is still open
        this.deleteCollection();
    }

    toggleSidebar() {
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    toggleHelpModal() {
        this.setState({
            showHelpModal: !this.state.showHelpModal
        })
    }

    getReportTitleObject() {
        return { Name: this.getReportTitle() };
    }

    getReportTitle() {
        if (this.state.selectedCollection) { return ''; }

        let title = '';
        if (this.state.allLegislation) {
            title = "All legislation";
        } else if (this.state.mostFrequent) {
            title = "Most frequently accessed bills";
        } else if (this.state.isPending) {
            title = "Pending bills";
        } else if (this.state.selectedBillStatusCategory && this.state.billStatusCategoryList.find(opt => opt.value === this.state.selectedBillStatusCategory)) {
            title = this.state.billStatusCategoryList.find(opt => opt.value === this.state.selectedBillStatusCategory).label;
            if (this.state.currentStatus) {
                title += " (Current Status)";
            }
        }
        return title;
    }

    handleToggleCompositeView() {
        let billList = []
        this.state.billList.forEach(x => {
            billList.push({
                LegislationNumber: x.LegislationNumber,
                SessionID: x.SessionID
            })
        });
        this.setState({ compositeView: !this.state.compositeView }, () => {
            const jsonData = JSON.stringify({
                showCatchline: true,
                showCurrentStatus: false,
                showTitle: false,
                showChiefPatron: true,
                showAllPatrons: false,
                showSummary: false,
                showNotes: true,
                userCreated: this.props.login.userProfile.email || '',
                selectedNotes: "current",
                selectedCollection: this.state.selectedCollection && this.state.selectedCollection.Name && this.state.selectedCollection.WatchListID ? this.state.selectedCollection.WatchListID : '',
                reportTitle: "",
                showHistory: {
                    all: false,
                    recent: true,
                    mostRecentAmount: "1",
                    showVotes: false,
                    Committee: true,
                    Subcommittee: false
                },
                showHouseAmendments: false,
                showSenateAmendments: false,
                showConferenceAmendments: false,
                showGovernorsRecommendations: false,
                showStatus: {},
                showCommitteeEvent: {},
                showLdNumber: false,
                showClerkNames: false,
                showDlsStaffNames: false,
                personalMessage: "",
                billList: billList
            });
            this.props.actions.saveBillReportInformation(window.btoa(jsonData)).then(() => {
                const billReportInformation = this.props.report.billReportInformation;
                if (billReportInformation && billReportInformation.ReportNumber) {
                    window.open('/bill-search-report/composite/' + billReportInformation.ReportNumber, '_blank');
                } else {
                    this.props.actions.makeToast([{ message: "Composite View Generation Failed", type: "failure" }]);
                }
                this.setState({ compositeView: false })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err)
                this.props.actions.makeToast([{ message: "Composite View Generation Failed", type: "failure" }]);
                this.setState({
                    compositeView: false
                });
            })
        })
    }

    render() {
        const { isFetching, sessionID, billList, showSidebar, height } = this.state;
        const { errorMessage } = this.props.bills;

        const userCanUseCollections = Boolean(this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR));

        const nextPageLoadingHeight = '100px';

        let billResultRefs = [];
        billList.forEach((_b) => billResultRefs.push(React.createRef()));
        this._billResultRefs = billResultRefs;

        let toggleArrows = [];
        for (let i = 1; i <= height; i++) {
            let top = (i * 540).toString() + "px";
            toggleArrows.push(<button key={i} type="button" aria-expanded={showSidebar} onClick={this.toggleSidebar} className={showSidebar ? "sidebar-button arrow-up white-chevron" : "sidebar-button arrow-down white-chevron"} style={{ top: top }}></button>);
        }

        return (
            <div className="bill-theme bill-search-container">
                {this.state.historicalSessionRedirect &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content fit-content center-content">
                            <p>For historical data, please use <b>Legacy LIS</b>, available at <b><a href="https://legacylis.virginia.gov" target="_blank" rel="noreferrer">https://legacylis.virginia.gov</a></b>.</p>
                            <p>Click 'Go' to be taken to Legacy LIS, or 'Close' to remain here.</p>
                            <div className="inline-list">
                                <button className="button primary float-right" onClick={() => this.state.historicalSessionRedirect(true)}>Go</button>
                                <button className="button secondary float-right" onClick={() => this.state.historicalSessionRedirect(false)}>Close</button>
                            </div>
                            <br />
                        </div>
                    </div>
                }
                {this.state.showModal &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content">
                            <p>You will need to click the search button or press the enter key in order to update your search results after removing a search parameter.</p>
                            <button className="button primary float-right" onClick={() => this.toggleModal()}>Close</button>
                            <br />
                        </div>
                    </div>
                }
                {this.state.showHelpModal &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content seach-help-modal">
                            <a className="float-right" style={{ cursor: "pointer" }} onClick={() => this.toggleHelpModal()}>X</a>
                            <SearchHelp />
                            <button className="button primary float-right" onClick={() => this.toggleHelpModal()}>Close</button>
                            <br />
                        </div>
                    </div>
                }
                {this.state.showReportMaker &&
                    <ReportMaker
                        toggleReportMaker={this.toggleReportMaker}
                        billList={this.state.mostFrequent ? this.state.billList : this.state.fullLegislationList}
                        collections={this.state.collections}
                        selectedCollection={this.state.selectedCollection || this.getReportTitleObject()}
                        autoReport={this.state.autoReport}
                        reportTitle={this.getReportTitle()}
                    />
                }
                <br />
                <div className={showSidebar ? "inner-grid one-toggle-and-three" : "inner-grid toggle-and-three"}>
                    {showSidebar &&
                        <div>
                            <LegislationWatchlists
                                collections={this.state.collections}
                                isLoading={this.state.collectionIsLoading}
                                error={this.props.collection.collectionError}
                                selectedCollectionIndex={this.state.selectedCollectionIndex}
                                selectCollection={this.selectCollection}
                                createCollection={this.createCollection}
                                collectionIsSaving={this.state.collectionIsSaving}
                                location={this.props.history.location.pathname}
                                isAuthor={this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR)}
                            />
                            <br />
                            <AdvancedSearchBox
                                ref={this.searchBox}
                                selectedBillNumbers={this.state.selectedBillNumbers}
                                selectedBillRangeBeginning={this.state.selectedBillRangeBeginning}
                                selectedBillRangeEnd={this.state.selectedBillRangeEnd}
                                selectedChapterNumber={this.state.selectedChapterNumber}
                                selectedKeywords={this.state.selectedKeywords}
                                selectedBillEvent={this.state.selectedBillEvent}
                                billEventOptions={this.state.billEventOptions}
                                sessionOptions={this.state.sessionOptions}
                                selectedSession={this.state.selectedSession}
                                billStatusCategoryList={this.state.billStatusCategoryList}
                                selectedBillStatusCategory={this.state.selectedBillStatusCategory}
                                billStatusStartDate={this.state.billStatusStartDate}
                                billStatusEndDate={this.state.billStatusEndDate}
                                selectedCommittee={this.state.selectedCommittee}
                                committeeList={this.state.filteredCommitteeList}
                                subcommitteeList={this.state.subcommitteeList}
                                selectedSubcommittee={this.state.selectedSubcommittee}
                                patronList={this.state.filteredPatronList}
                                patronRoles={this.state.patronRoles}
                                selectedPatron={this.state.selectedPatron}
                                selectedPatronType={this.state.selectedPatronType}
                                selectedChamber={this.state.selectedChamber}
                                selectedBillType={this.state.selectedBillType}
                                billEventEndDate={this.state.billEventEndDate}
                                billEventStartDate={this.state.billEventStartDate}
                                resetUrl={this.resetUrl}
                                handleSubmit={this.handleSubmit}
                                handleTextChange={this.handleTextChange}
                                handleSelectChange={this.handleSelectChange}
                                handleDateChange={this.handleDateChange}
                                committeeChange={this.committeeChange}
                                chamberChange={this.chamberChange}
                                sessionChange={this.sessionChange}
                                clearForm={this.clearForm}
                                isFetching={this.state.isFetching}
                                handlePatronChange={this.handlePatronChange}
                                handlePatronTypeChange={this.handlePatronTypeChange}
                                subjectList={this.state.subjectList}
                                selectedSubject={this.state.selectedSubject}
                                toggleGroup={this.toggleGroup}
                                billFieldset={this.state.billFieldset}
                                keywordFieldset={this.state.keywordFieldset}
                                statusFieldset={this.state.statusFieldset}
                                activityFieldset={this.state.activityFieldset}
                                membersFieldset={this.state.membersFieldset}
                                committeesFieldset={this.state.committeesFieldset}
                                subjectFieldset={this.state.subjectFieldset}
                                showCategories={this.state.showCategories}
                                toggleShowCategories={this.toggleShowCategories}
                                location={this.props.history.location.pathname}
                                searchParam={this.props.history.location.search}
                                billVersionList={this.state.billVersionList}
                                selectedBillVersion={this.state.selectedBillVersion}
                                billSummaryList={this.state.billSummaryList}
                                selectedSummaryVersion={this.state.selectedSummaryVersion}
                                selectedLocation={this.state.selectedLocation}
                                keywordUseGlobalSessionSearch={this.state.keywordUseGlobalSessionSearch}
                                handleRadioChange={this.handleRadioChange}
                                handleCurrentStatusChange={this.handleCurrentStatusChange}
                                currentStatus={this.state.currentStatus}
                                handleExcludeFailedChange={this.handleExcludeFailedChange}
                                excludeFailed={this.state.excludeFailed}
                                toggleHelpModal={this.toggleHelpModal}
                                failedToGetSessions={this.state.failedToGetSessions}
                                showReportMaker={this.state.showReportMaker}
                                showUpdateSearchButton={this.state.showUpdateSearchButton}
                            />
                        </div>
                    }
                    <div className={showSidebar ? "sidebar-toggle-open" : "sidebar-toggle-closed"} onClick={this.toggleSidebar}>
                        {toggleArrows.map((a, i) => {
                            return (a)
                        })}
                    </div>
                    {this.state.failedToGetSessions ? <div className="center input-feedback" style={{ fontSize: '1em' }}>Failed to retrieve sessions. Please refresh & try again.</div> : !this.state.selectedSession ? <div className="center-spinner spinner">Loading...</div>
                        :
                        this.state.showCategories ?
                            <BillCategories
                                session={this.state.sessionOptions.find(session => session.SessionCode === this.props.nav.session)}
                                patronList={this.state.patronList}
                                committeeList={this.state.groupedCommitteeList}
                                subjectList={this.state.subjectList}
                                introDateList={this.state.introDateList}
                                statusCategoriesList={this.state.billStatusCategoryList}
                                isLoading={this.state.billStatusCategoryListIsLoading}
                                history={this.props.history}
                                location={this.props.history.location.pathname}
                                selectedSession={this.state.selectedSession}
                                excludeFailed={this.state.excludeFailed}
                            />
                            :
                            <div id="results-div">
                                {userCanUseCollections &&
                                    <React.Fragment>
                                        <CollectionControls
                                            searchMade={this.state.searchMade}
                                            collections={this.state.collections}
                                            selectedCollection={this.state.selectedCollection}
                                            selectedCollectionIndex={this.state.selectedCollectionIndex}
                                            handleCollectionChange={this.handleCollectionChange}
                                            handleToggleCompositeView={this.handleToggleCompositeView}
                                            selectCollection={this.selectCollection}
                                            collectionIsSaving={this.state.collectionIsSaving}
                                            deleteCollection={this.deleteCollection}
                                            checkedBills={this.state.checkedBills}
                                            addToWatchlists={this.addToWatchlists}
                                            billListLength={this.state.billList.length}
                                        />
                                        {!this.state.searchMade &&
                                            <WatchlistNotifications
                                                selectedCollection={this.state.selectedCollection}
                                                handleCollectionChange={this.handleCollectionChange}
                                                memberList={this.state.patronList}
                                                committeeList={this.state.groupedCommitteeList}
                                                criteriaTypes={this.state.criteriaTypes}
                                                collectionIsSaving={this.state.collectionIsSaving}
                                            />
                                        }
                                    </React.Fragment>
                                }
                                {this.state.searchMade &&
                                    <SearchSelections
                                        currentStatus={this.state.currentStatus}
                                        selectedSession={this.state.selectedSession}
                                        selectedBillNumbers={this.state.selectedBillNumbers}
                                        selectedBillRangeBeginning={this.state.selectedBillRangeBeginning}
                                        selectedBillRangeEnd={this.state.selectedBillRangeEnd}
                                        selectedChapterNumber={this.state.selectedChapterNumber}
                                        selectedKeywords={this.state.searchedKeywords || this.state.selectedKeywords}
                                        selectedBillEvent={this.state.billEventOptions.find(opt => opt.value === this.state.selectedBillEvent)}
                                        selectedBillStatusCategory={this.state.billStatusCategoryList.find(opt => opt.value === this.state.selectedBillStatusCategory)}
                                        billStatusStartDate={this.state.billStatusStartDate}
                                        billStatusEndDate={this.state.billStatusEndDate}
                                        selectedCommittee={this.state.committeeList.find(opt => opt.value === this.state.selectedCommittee)}
                                        selectedSubcommittee={this.state.subcommitteeList.find(opt => opt.value === this.state.selectedSubcommittee)}
                                        selectedPatron={this.state.patronList.find(opt => opt.value === this.state.selectedPatron)}
                                        selectedPatronType={this.state.patronRoles.filter(role => this.state.selectedPatronType.includes(role.PatronTypeID))}
                                        selectedChamber={this.state.selectedChamber}
                                        billEventEndDate={this.state.billEventEndDate}
                                        billEventStartDate={this.state.billEventStartDate}
                                        selectedSubject={this.state.subjectList.find(opt => opt.value === this.state.selectedSubject)}
                                        excludeFailed={this.state.excludeFailed}
                                        handleSelectionClear={this.handleSelectionClear}
                                        handleMultiSelectionClear={this.handleMultiSelectionClear}
                                        updateSearch={this.state.showUpdateSearchButton ? this.searchBox.current.handleSubmit : null}
                                    />}
                                {!isFetching &&
                                    <>
                                        <div className="flex-row search-results-title-section">
                                            {this.state.searchMade ?
                                                <>
                                                    {this.state.allLegislation && <h2>All legislation</h2>}
                                                    {this.state.mostFrequent && <h2>Most frequently accessed bills</h2>}
                                                    {this.state.isPending && <h2>Pending bills</h2>}
                                                    {!this.state.isPending && !this.state.mostFrequent && !this.state.allLegislation && <h2 style={{ marginTop: "0px", marginBottom: "10px" }}>{billList.length === 0 && "No "}Search Results</h2>}
                                                </>
                                                : <div></div>}
                                            {!this.state.selectedCollection || this.state.searchMade ?
                                                <div className="inline-list">
                                                    {billList.length > 0 &&
                                                        <React.Fragment>
                                                            <button onClick={() => this.toggleReportMaker()} id="create-report-button" type="button" className={`${this.state.selectedCollection ? 'set-right ' : ''}button small-button`} style={{ marginRight: '0px', marginTop: "8px" }}>Create Report</button>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                :
                                                <div />
                                            }
                                        </div>
                                        {this.state.selectedCollection &&
                                            <div className="flex-row flex-vertical-center notes-and-controls">
                                                {this.state.searchMade ?
                                                    <>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                disabled={this.state.checkedBills.every(b => b === false) || this.state.collectionIsSaving}
                                                                className="button small-button"
                                                                onClick={() => this.addToWatchlists(JSON.parse(JSON.stringify([this.state.selectedCollection])))}>Add</button>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="inline-list">
                                                            <button aria-label="remove watchlist bills" onClick={this.removeWatchlistBills} type="button" disabled={this.state.checkedBills.every(b => b === false) || this.state.collectionIsSaving} className="button danger small-button">Remove</button>
                                                            <button aria-label="toggle all notes" disabled={this.state.collectionIsSaving} className="button-link toggle-all-notes" onClick={this.toggleAllNotes} type="button">{this.state.showAllNotes ? "Hide" : "Show"} All Notes +/-</button>
                                                        </div>
                                                        <div className="inline-list" style={{ marginRight: "0px", display: this.state.quickAddErr ? "grid" : null }}>
                                                            <QuickAdd
                                                                quickAddBills={this.quickAddBills}
                                                                quickAddErr={this.state.quickAddErr}
                                                                autoFocus={!this.state.skippedRecords}
                                                                collectionIsSaving={this.state.collectionIsSaving}
                                                            />
                                                            {billList.length > 0 &&
                                                                <div style={{ display: 'inline-flex', justifyContent: 'end', marginRight: '0px', marginTop: this.state.quickAddErr ? '5px' : null }}>
                                                                    <button onClick={() => this.toggleReportMaker()} id="create-report-button" type="button" className={`${this.state.selectedCollection ? 'set-right ' : ''}button small-button`} style={{ marginRight: '0px' }}>Create Report</button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </>
                                }
                                <div className="public-list search-results">
                                    {isFetching && !this.state.skippedRecords ?
                                        <div className="spinner"></div>
                                        :
                                        <>
                                            {errorMessage ?
                                                <p className="message-error">{errorMessage}</p>
                                                :
                                                null
                                            }
                                        </>
                                    }
                                    {(!isFetching || this.state.skippedRecords) && billList.length && !errorMessage ?
                                        <ul className="slidedown-list">
                                            <div className='flex-row'>
                                                {userCanUseCollections ?
                                                    <label htmlFor="sr-select-all"
                                                        className="txt-greyed">
                                                        <input id="sr-select-all" checked={this.state.selectAllBills} onChange={this.toggleAllBills} type="checkbox" style={{ verticalAlign: 'sub', marginRight: '10px' }} />
                                                        Select all</label>
                                                    :
                                                    <div></div>}
                                                {!errorMessage && <span id="search-result-count" className="txt-greyed">{this.state.totalResults} {this.state.searchMade ? "Result" : "Item"}{this.state.totalResults !== 1 && "s"}</span>}
                                            </div>
                                            {!this.state.displayCrossSession && <hr className="faded-line" />}
                                            {billList.map((bill, i) =>
                                                <React.Fragment>
                                                    {this.state.displayCrossSession && i === 0 &&
                                                        <div className="search-session-header">
                                                            <label>{this.state.sessionOptions.find(x => x.SessionID === bill.SessionID).SessionYear + " " + this.state.sessionOptions.find(x => x.SessionID === bill.SessionID).DisplayName}</label>
                                                        </div>
                                                    }

                                                    {this.state.displayCrossSession && billList[i + 1] && billList[i].SessionID != billList[i + 1].SessionID || this.state.displayCrossSession && billList[i + 1] === undefined
                                                        ?
                                                        <React.Fragment>
                                                            <BillInfoComponent
                                                                key={i}
                                                                ref={this._billResultRefs[i]}
                                                                userCanUseCollections={userCanUseCollections}
                                                                bill={bill}
                                                                billIndex={i}
                                                                sessionID={sessionID}
                                                                toggleShowData={this.toggleShowData}
                                                                expanded={this.state[bill.LegislationID]}
                                                                sessionCode={this.state.sessionOptions.find(session => session.SessionID === bill.SessionID) && this.state.sessionOptions.find(session => session.SessionID === bill.SessionID).SessionCode}
                                                                selectedCollection={this.state.selectedCollection}
                                                                handleCollectionChange={this.handleCollectionChange}
                                                                collectionIsSaving={this.state.collectionIsSaving}
                                                                handleBillCheckbox={e => this.handleBillCheckbox(i, e)}
                                                                checked={this.state.checkedBills[i]}
                                                                selectedKeywords={this.state.searchedKeywords || this.state.selectedKeywords}
                                                                selectedLocation={this.state.displaySelectedLocation}
                                                                searchMade={this.state.searchMade}
                                                                session={this.state.sessionOptions.find(x => x.SessionID === bill.SessionID)}
                                                                displayCrossSession={this.state.displayCrossSession}
                                                                newSession={true}
                                                                displayChapterNumber={this.state.selectedChapterNumber || (this.state.selectedBillStatusCategory && this.state.billStatusCategoryList.find(it => it.Name.toLowerCase() === "acts of assembly chapters") && this.state.selectedBillStatusCategory === this.state.billStatusCategoryList.find(it => it.Name.toLowerCase() === "acts of assembly chapters").LegislationCategoryID)}
                                                                compositeView={this.state.compositeView}
                                                            />
                                                            {billList[i + 1] &&
                                                                <div className="search-session-header">
                                                                    <label>{this.state.sessionOptions.find(x => x.SessionID === billList[i + 1].SessionID).SessionYear + " " + this.state.sessionOptions.find(x => x.SessionID === billList[i + 1].SessionID).DisplayName}</label>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <BillInfoComponent
                                                            key={i}
                                                            ref={this._billResultRefs[i]}
                                                            userCanUseCollections={userCanUseCollections}
                                                            bill={bill}
                                                            billIndex={i}
                                                            sessionID={sessionID}
                                                            sessionCode={this.state.sessionOptions.find(session => session.SessionID === bill.SessionID) && this.state.sessionOptions.find(session => session.SessionID === bill.SessionID).SessionCode}
                                                            selectedCollection={this.state.selectedCollection}
                                                            handleCollectionChange={this.handleCollectionChange}
                                                            collectionIsSaving={this.state.collectionIsSaving}
                                                            handleBillCheckbox={e => this.handleBillCheckbox(i, e)}
                                                            checked={this.state.checkedBills[i]}
                                                            selectedKeywords={this.state.searchedKeywords || this.state.selectedKeywords}
                                                            toggleShowData={this.toggleShowData}
                                                            expanded={this.state[bill.LegislationID]}
                                                            selectedLocation={this.state.displaySelectedLocation}
                                                            searchMade={this.state.searchMade}
                                                            session={this.state.sessionOptions.find(x => x.SessionID === bill.SessionID)}
                                                            displayCrossSession={this.state.displayCrossSession}
                                                            displayChapterNumber={this.state.selectedChapterNumber || (this.state.selectedBillStatusCategory && this.state.billStatusCategoryList.find(it => it.Name.toLowerCase() === "acts of assembly chapters") && this.state.selectedBillStatusCategory === this.state.billStatusCategoryList.find(it => it.Name.toLowerCase() === "acts of assembly chapters").LegislationCategoryID)}
                                                            compositeView={this.state.compositeView}
                                                        />
                                                    }
                                                </React.Fragment>
                                            )}
                                        </ul>
                                        : null
                                    }
                                </div>
                            </div>
                    }
                </div>
                <div
                    ref={loadingRef => (this.loadingRef = loadingRef)}
                    style={{ position: 'relative', bottom: !isFetching && this.state.hasNextPage ? ((window.innerHeight || document.documentElement.clientHeight) * .35) + 'px' : null }}
                >
                    {isFetching && this.state.skippedRecords ?
                        <div className="spinner" style={{ height: nextPageLoadingHeight, margin: 'auto' }}></div>
                        :
                        this.state.hasNextPage ?
                            <div style={{ marginTop: nextPageLoadingHeight }} />
                            : null
                    }
                </div>
            </div>
        )
    }
}

const PublicBillSearch = connect(
    (state) => {
        const { bills, session, members, committee, patrons, nav, collection, login, report } = state;
        return {
            bills,
            session,
            members,
            committee,
            patrons,
            nav,
            collection,
            login,
            report
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, committeeActionCreators, billActionCreators, sessionActionCreators, memberActionCreators, patronActionCreators, navActionCreators, collectionActionCreators, reportActionCreators), dispatch)
        }
    }
)(BillSearchComponent)

export default PublicBillSearch;