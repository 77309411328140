import React from "react"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { navActionCreators } from '../../../stores/lis-nav-store';

class IconLinks extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { selectedSession } = this.props;
        let budgetUrl = "https://budget.lis.virginia.gov/";
        if (selectedSession) {
            //budgetUrl += `default/${selectedSession.SessionCode.substr(0, 4)}/${selectedSession.SessionCode.substr(4)}`
        }

        return (
            <div className="landing home-icon-links">
                <div className="grid-wrapper four-icons">
                    <div className="landing legislation">
                        <Link className="info-link legislation" to="/bill-search"></Link>
                        <Link to="/bill-search">Bills & Resolutions</Link>
                    </div>
                    <div className="landing state-budget">
                        <a className="info-link state-budget" href={`${budgetUrl}`}></a>
                        <a href={`${budgetUrl}`}>State Budget</a>
                    </div>
                    <div className="landing virginia-law">
                        <a className="info-link virginia-law" href={`https://law.lis.virginia.gov/`}></a>
                        <a href={`https://law.lis.virginia.gov/`}>Virginia Law</a>
                    </div>
                    <div className="landing legislative-resources">
                        <a className="info-link legislative-resources" href={`https://rga.lis.virginia.gov/`}></a>
                        <a href={`https://rga.lis.virginia.gov/`}>Reports to the General Assembly</a>
                    </div>
                </div>
            </div>
        )
    }
}

const Links = connect(
    (state) => {
        const { nav } = state;
        return {
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators), dispatch)
        }
    }
)(IconLinks)

export default withRouter(Links)